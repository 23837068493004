import React from 'react';
import { Chart } from '@bit/primefaces.primereact.chart';
import { getTechnique } from '../../data';
import { getCompTechnique } from '../../compData';
import { getTechniqueTypes } from '../../customTech';
import { getHRTechType } from '../../techTypes';


class SpiderChart extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            loaded: false, charts: [], counter: 0
        }
    }

    componentDidMount = async () => {
        //Find which values are supposed to be maximized for the specified technique. 
        await this.findDataLabels();
        //load average and user values
        this.loadData();

    }


    findDataLabels = async () => {
        //Read maximized values from db. 
        let customTechniques = await getTechniqueTypes();
        let techDef = {};
        let technique = await getTechnique();
        let compTech = await getCompTechnique();

        //Find the specific technique
        for (let i = 0; i < customTechniques.length; i++) {
            if (customTechniques[i].id == technique.techniqueType) {
                techDef = customTechniques[i];
            }
        }

        let maxValues = JSON.parse(techDef.properties.max[0].value)

        //push labels if a given value is to be maximized. 
        let labels = [];
        if (maxValues.footSpeed === true) { labels.push("Foot Speed"); }
        if (maxValues.footHeight === true) { labels.push("Foot Height"); }
        if (maxValues.handHeight === true) { labels.push("Hand Height"); }
        if (maxValues.handSpeed === true) { labels.push("Hand Speed"); }
        if (maxValues.kneeExtensionAngle === true) { labels.push("Knee Extension"); }
        if (maxValues.kneeExtensionSpeed === true) { labels.push("Knee Explosion"); }
        if (maxValues.elbowExtAngle === true) { labels.push("Elbow Extension"); }
        if (maxValues.elbowExtSpeed === true) { labels.push("Elbow Explosion"); }
        if (maxValues.shoulderRotSpeed === true) { labels.push("Shoulder Rotation"); }
        if (maxValues.hipRotSpeed === true) { labels.push("Hip Rotation"); }

        this.setState({ technique, techDef, labels, compTech, maxValues })
    }

    loadData = async () => {
        let self = this;
        //find averages of the desired technique
        let maxValues = this.state.maxValues;
        console.log(this.state.techDef)
        let numExamples = this.state.techDef.properties.number[0].value;

        //store data points from both the current technique and the average. 
        let techData = [];
        let averageData = [];

        if (maxValues.footSpeed === true) {
            averageData.push(this.state.techDef.properties.totalFootSpeed[0].value / numExamples);
            techData.push(this.state.technique.maxFootSpeed);
        }
        if (maxValues.footHeight === true) {
            averageData.push(this.state.techDef.properties.totalFootHeight[0].value / numExamples);
            techData.push(this.state.technique.maxFootHeight);
        }

        if (maxValues.handSpeed === true) {
            averageData.push(this.state.techDef.properties.totalHandSpeed[0].value / numExamples);
            techData.push(this.state.technique.maxHandSpeed);
        }

        if (maxValues.handHeight === true) {
            averageData.push(this.state.techDef.properties.totalHandHeight[0].value / numExamples);
            techData.push(this.state.technique.maxHandHeight);
        }

        if (maxValues.kneeExtensionAngle === true) {
            averageData.push(this.state.techDef.properties.totalKneeExtAngle[0].value / numExamples);
            techData.push(this.state.technique.maxKneeExtAngle);
        }

        if (maxValues.kneeExtensionSpeed === true) {
            averageData.push(this.state.techDef.properties.totalKneeExtSpeed[0].value / numExamples);
            techData.push(this.state.technique.maxKneeExtSpeed);
        }

        if (maxValues.elbowExtAngle === true) {
            averageData.push(this.state.techDef.properties.totalElbowExtAngle[0].value / numExamples);
            techData.push(this.state.technique.maxElbowExtAngle);
        }

        if (maxValues.elbowExtSpeed === true) {
            averageData.push(this.state.techDef.properties.totalElbowExtSpeed[0].value / numExamples);
            techData.push(this.state.technique.maxElbowExtSpeed);
        }

        if (maxValues.shoulderRotSpeed === true) {
            averageData.push(this.state.techDef.properties.totalShoulderRotSpeed[0].value / numExamples);
            techData.push(this.state.technique.maxShoulderRotSpeed);
        }

        if (maxValues.hipRotSpeed === true) {
            averageData.push(this.state.techDef.properties.totalHipRotSpeed[0].value / numExamples);
            techData.push(this.state.technique.maxHipRotSpeed);
        }


        //normalize - The smallest of aveage data and techdata for each category is assigned a value of 100. 
        for (let i = 0; i < averageData.length; i++) {
            if (techData[i] < averageData[i]) {
                techData[i] = 100;
                averageData[i] = (averageData[i] / techData[i])
            }
            else {
                averageData[i] = 100;
                techData[i] = (techData[i] / averageData[i])
            }

        }
  
        let myHRType = getHRTechType(this.state.techDef.id)

        const data = {
            labels: this.state.labels,
            datasets: [
                {
                    label: 'My Technique',
                    backgroundColor: 'rgba(179,181,198,0.2)',
                    borderColor: 'rgba(179,181,198,1)',
                    pointBackgroundColor: 'rgba(179,181,198,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(179,181,198,1)',
                    data: techData
                },
                {
                    label: `Average ${myHRType}`,
                    backgroundColor: 'rgba(255,99,132,0.2)',
                    borderColor: 'rgba(255,99,132,1)',
                    pointBackgroundColor: 'rgba(255,99,132,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(255,99,132,1)',
                    data: averageData
                }
            ]
        };

       let options = {
            legend: {
                position: 'top',
                labels: {
                    fontColor: 'white'
                }
            },
            title: {
                display: true,
                text: 'Normalized Stats',
                fontColor: 'white'
            },
            scale: {

                ticks: {
                    beginAtZero: true,
                    fontColor: 'black', // labels such as 10, 20, etc
                    showLabelBackdrop: false // hide square behind text

                },
                pointLabels: {
                    fontColor: 'white' // labels around the edge like 'Running'
                },
                gridLines: {
                    color: 'rgba(255, 255, 255, 0.2)'
                },
                angleLines: {
                    color: 'white' // lines radiating from the center
                }
            }
        }
        let charts = self.state.charts;
        let i = self.state.counter + 1;
        if (charts.length !== 0) { charts.pop(); }
        charts.push(<div style={{ width: 375, height: 375 }}> <Chart type='radar' key={i} data={data} options={options} height={350} width={350} /> </div>)
        this.setState({loaded: true })
    }

    render() {
    
        return (<div>
            {this.state.loaded == false && <div> Spider Chart loading... </div>}

            {this.state.loaded == true && this.state.charts[0] } }

        </div>


        );
    }

}


export default SpiderChart;

