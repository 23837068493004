// src/components/Profile.js

import React from "react";
import { useAuth0 } from "../react-auth0-wrapper";
import { updateUser } from "../userInfo";
import { setToken } from "../token";
import { loadFavoritesForUser } from "../favoriteInfo";

const Profile =  () => {
    const { loading, user, getTokenSilently} = useAuth0();

    if (loading || !user) {
        setToken('public')
        return (
            <div />
        );
    }

    if (user) {
        
        getTokenSilently().then(function (value) {
            setToken(value);
            updateUser(user);
            loadFavoritesForUser();     
        });
    }
    return (
       <div />
    );
};

export default Profile;

