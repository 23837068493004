import axios from 'axios'
import { getToken } from './token';
import { functionURL } from './config/default';

let favorites = [];

async function loadFavoritesForUser() {
    let token = getToken();
    let info = await axios.get(functionURL + `?type=getUserFavorites`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }, {});
    info = JSON.parse(info.request.response);
   // console.log('favorite Info:', info);
    let loadedFavorites = [];
    for (let i = 0; i < info.length; i++) {
        loadedFavorites.push(info._items[i].inV)
    }
    setFavorites(loadedFavorites);
   // console.log('LOADED FAVORITES:', loadedFavorites)
    return loadedFavorites;

}

//set the list of all techniques favorited by each user. 
function setFavorites(newFavorites) {
    favorites = newFavorites;
}

function getFavorites() {
    return favorites;
}

function isFavorite(id) {
    return (favorites.includes(id))
}


async function addFavorite(id) {
    let token = getToken();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.post(functionURL + `?type=addFavorite&favorite=${id}`, {}, {})
    .then(function (response) {
        console.log(response);
    })
    .catch(function (error) {
        console.log(error);
    }); 
    return id;
}

//Function which removes a favorite from the current user. 
async function removeFavorite(id) {
    let token = getToken();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.post(functionURL + `?type=remFavorite&favorite=${id}`, { }, {})
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        }); 
   

}


    export {isFavorite, setFavorites, getFavorites, addFavorite, removeFavorite, loadFavoritesForUser}