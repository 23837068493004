import React from 'react';
import { changePrivacy } from '../data';



class PrivacySelector extends React.Component {

    constructor(props) {
        super(props)
        this.state = {privacy: props.privacy, techniqueId: props.techniqueId}
    }


    updatePrivacy = (event) => {

        //e.preventDefault();
        changePrivacy(this.state.techniqueId, event.target.value);
        this.setState({ privacy: event.target.value });
    }

    render() {
        return (
            <select value={this.state.privacy} onChange={this.updatePrivacy}>
                <option value={'0-Private'}> Private </option>
                <option value={'1-Public-Unlisted'}> Public-Unlisted </option>
                <option value={'2-Public-Leaderboard'}> Public-Leaderboard </option>
             </select>

        );
    }
}

export default PrivacySelector;










