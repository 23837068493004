import React from 'react';



class InfoCardKinect extends React.Component {


    constructor(props) {
        super(props)
        this.state = {  }
    }


    render() {
   
     
        
            return (
                <div className="ui raised inverted centered link blue card" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }} >
                    <div className="content">
                        <p style={{ 'color': 'white' }}>Take results with a big grain of salt as Microsoft's Neural Network that extracts joint positions currently performs poorly on extended and fast moving joints.
                            They are working on fixing the <a style={{ 'text-decoration': 'underline', color: 'rgba(64, 160, 255, 1)' }} href='https://github.com/microsoft/Azure-Kinect-Sensor-SDK/issues/738'> issue </a> and we are providing training data for their network. </p> 
                        </div>
                    </div>

                

            );
    }

}


 


export default InfoCardKinect;



