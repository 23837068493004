import React from 'react';


function HeaderSection({title, color='white' }) {
    
    return (
     
        < div className={`sixteen wide column ${color}`} style={{'background-color': color}} >
          
       
            <h1 align="center" style={{'color': 'white'}}>{title}</h1>
       
    </div>
            
    )

}
export default HeaderSection;