import React from 'react';
import { getTechniqueFromDb, isTechnique } from '../../data';

class LoadButton extends React.Component {

    constructor(props) {
        super(props)
        this.state = { techniqueId: props.techniqueId, status: 1, refresh: props.refresh}
        //1 - none, 2 - loading 3 - loaded
    }

    componentDidMount() {
  
    }

    handleClick = async (e) =>  {
        e.persist()
        this.setState({status: 2})
        await getTechniqueFromDb(this.state.techniqueId)
        e.preventDefault();
       this.state.refresh();
       this.setState({status: 1})
    }

   
    render() {

    return (
        <>
     
            {this.state.status ===1  && <i className="ui big green cloud download icon" style={{ cursor: 'pointer' }} onClick={this.handleClick} />}
            {this.state.status === 2 && <span>Loading...</span>}
          
         </>
         
    );
}

}

export default LoadButton;











