export const functionURL = "https://macoach.azurewebsites.net/api/HttpTrigger"
//export const functionURL = 'http://localhost:7071/api/HttpTrigger'
export const defaultTechnique = 
{
    "allTimestamps": [
        0,
        50811,
        84145,
        117478,
        150811,
        184145,
        217478,
        250811,
        284145,
        317478,
        350811,
        384145,
        417478,
        450811,
        484145,
        517478,
        550811,
        584145,
        617478,
        650811,
        684145,
        717478,
        750811,
        784145,
        817478,
        850811,
        884145,
        917478,
        950811,
        984145,
        1017478,
        1050811,
        1084145,
        1117478,
        1150811,
        1184145,
        1217478,
        1250811,
        1284145,
        1317478,
        1350811,
        1384145,
        1417478,
        1450811,
        1484145,
        1517478,
        1550811,
        1584145,
        1617478,
        1650811,
        1684145,
        1717478,
        1750811,
        1784145,
        1817478,
        1850811,
        1884145,
        1917478,
        1950811,
        1984145,
        2017478,
        2050811,
        2084145,
        2117478,
        2150811,
        2184145,
        2217478,
        2250811,
        2284145,
        2317478,
        2350811,
        2384145,
        2417478,
        2450811,
        2484145,
        2517478,
        2550811,
        2584145,
        2617478,
        2650811,
        2684145,
        2717478,
        2750811,
        2784145,
        2817478,
        2850811,
        2884145,
        2917478,
        2950811,
        2984145,
        3017478,
        3050811,
        3084145,
        3117478,
        3150811,
        3184145,
        3217478,
        3250811,
        3284145,
        3317478,
        3350811,
        3384145,
        3417478,
        3450811,
        3484145,
        3517478,
        3550811,
        3584145,
        3617478,
        3650811,
        3684145,
        3717478,
        3750811,
        3784145,
        3817478,
        3850811,
        3884145,
        3917478,
        3950811,
        3984145,
        4017478,
        4050811,
        4084145,
        4117478,
        0
    ],
    "head_x": [
        0,
        0,
        207,
        205,
        194,
        202,
        214,
        220,
        215,
        215,
        208,
        208,
        198,
        197,
        202,
        186,
        190,
        186,
        183,
        171,
        158,
        155,
        153,
        144,
        133,
        130,
        114,
        123,
        109,
        94,
        80,
        63,
        45,
        33,
        25,
        23,
        20,
        23,
        25,
        0,
        3,
        11,
        12,
        16,
        15,
        22,
        25,
        29,
        25,
        17,
        8,
        -9,
        -20,
        -24,
        -26,
        -26,
        -26,
        -22,
        -20,
        -20,
        -24,
        -32,
        -42,
        -45,
        -50,
        -48,
        -45,
        -41,
        -38,
        -41,
        -41,
        -42,
        -39,
        -35,
        -35,
        -36,
        -34,
        -30,
        -32,
        -26,
        -20,
        -9,
        1,
        25,
        50,
        71,
        82,
        104,
        117,
        137,
        154,
        172,
        188,
        197,
        194,
        191,
        195,
        198,
        198,
        199,
        198,
        192,
        189,
        191,
        196,
        192,
        191,
        192,
        196,
        201,
        196,
        194,
        192,
        192,
        193,
        195,
        198,
        198,
        197,
        194,
        193,
        194,
        194,
        -858993460,
        -858993460
    ],
    "head_y": [
        0,
        0,
        -1090,
        -1089,
        -1089,
        -1082,
        -1078,
        -1079,
        -1081,
        -1081,
        -1086,
        -1091,
        -1091,
        -1090,
        -1088,
        -1089,
        -1094,
        -1095,
        -1096,
        -1098,
        -1099,
        -1096,
        -1090,
        -1090,
        -1092,
        -1093,
        -1098,
        -1108,
        -1106,
        -1099,
        -1091,
        -1098,
        -1103,
        -1108,
        -1110,
        -1114,
        -1124,
        -1119,
        -1112,
        -1105,
        -1103,
        -1106,
        -1111,
        -1120,
        -1120,
        -1121,
        -1121,
        -1120,
        -1117,
        -1110,
        -1103,
        -1100,
        -1099,
        -1099,
        -1098,
        -1096,
        -1100,
        -1107,
        -1115,
        -1119,
        -1117,
        -1117,
        -1120,
        -1122,
        -1126,
        -1130,
        -1132,
        -1138,
        -1134,
        -1135,
        -1139,
        -1144,
        -1145,
        -1146,
        -1139,
        -1134,
        -1121,
        -1115,
        -1114,
        -1109,
        -1112,
        -1115,
        -1116,
        -1113,
        -1110,
        -1102,
        -1094,
        -1091,
        -1082,
        -1075,
        -1068,
        -1066,
        -1070,
        -1075,
        -1078,
        -1080,
        -1083,
        -1085,
        -1087,
        -1086,
        -1087,
        -1087,
        -1088,
        -1089,
        -1089,
        -1090,
        -1090,
        -1090,
        -1092,
        -1094,
        -1093,
        -1093,
        -1091,
        -1092,
        -1092,
        -1093,
        -1093,
        -1095,
        -1096,
        -1096,
        -1096,
        -1097,
        -1097,
        -858993460,
        -858993460
    ],
    "head_z": [
        0,
        0,
        2351,
        2355,
        2348,
        2343,
        2337,
        2342,
        2345,
        2343,
        2341,
        2337,
        2344,
        2343,
        2342,
        2341,
        2337,
        2337,
        2341,
        2344,
        2346,
        2341,
        2332,
        2329,
        2328,
        2325,
        2328,
        2322,
        2306,
        2300,
        2303,
        2291,
        2281,
        2271,
        2257,
        2246,
        2274,
        2278,
        2279,
        2251,
        2252,
        2261,
        2268,
        2267,
        2265,
        2266,
        2272,
        2284,
        2292,
        2293,
        2295,
        2297,
        2297,
        2292,
        2291,
        2288,
        2288,
        2278,
        2268,
        2261,
        2264,
        2277,
        2290,
        2295,
        2295,
        2288,
        2287,
        2280,
        2282,
        2281,
        2281,
        2278,
        2270,
        2257,
        2245,
        2229,
        2215,
        2214,
        2229,
        2244,
        2262,
        2249,
        2257,
        2265,
        2272,
        2288,
        2312,
        2325,
        2329,
        2332,
        2340,
        2338,
        2336,
        2338,
        2338,
        2342,
        2346,
        2346,
        2347,
        2348,
        2350,
        2348,
        2344,
        2340,
        2337,
        2338,
        2337,
        2338,
        2342,
        2340,
        2341,
        2343,
        2342,
        2345,
        2344,
        2339,
        2340,
        2339,
        2340,
        2342,
        2343,
        2343,
        2345,
        -858993460,
        -858993460
    ],
    "leftAnkle_x": [
        0,
        0,
        -3,
        4,
        151,
        114,
        66,
        33,
        40,
        12,
        26,
        6,
        37,
        28,
        4,
        171,
        120,
        109,
        78,
        209,
        291,
        333,
        350,
        361,
        367,
        241,
        282,
        331,
        381,
        364,
        416,
        412,
        390,
        367,
        341,
        296,
        171,
        44,
        -23,
        32,
        -21,
        -72,
        -108,
        -141,
        -173,
        -202,
        -211,
        -211,
        -202,
        -199,
        -204,
        -198,
        -192,
        -188,
        -179,
        -170,
        -152,
        -138,
        -133,
        -132,
        -135,
        -130,
        -114,
        -112,
        -103,
        -98,
        -100,
        -99,
        -108,
        -107,
        -100,
        -86,
        -74,
        -72,
        -73,
        -25,
        -54,
        -69,
        114,
        208,
        154,
        61,
        9,
        -24,
        -45,
        -52,
        -35,
        -49,
        -40,
        -41,
        -49,
        -62,
        -68,
        -68,
        -65,
        -63,
        -57,
        -68,
        -65,
        -68,
        -69,
        -62,
        -61,
        -63,
        -66,
        -65,
        -68,
        -66,
        -67,
        -67,
        -61,
        -64,
        -62,
        -67,
        -70,
        -73,
        -71,
        -72,
        -66,
        -68,
        -69,
        -64,
        -63,
        -858993460,
        -858993460
    ],
    "leftAnkle_y": [
        0,
        0,
        250,
        253,
        266,
        269,
        263,
        260,
        262,
        261,
        263,
        255,
        265,
        266,
        266,
        292,
        281,
        276,
        274,
        288,
        283,
        278,
        278,
        276,
        272,
        292,
        283,
        271,
        253,
        260,
        236,
        227,
        216,
        200,
        171,
        139,
        232,
        254,
        262,
        142,
        201,
        219,
        229,
        225,
        226,
        219,
        216,
        214,
        215,
        218,
        218,
        218,
        219,
        218,
        220,
        223,
        223,
        220,
        219,
        221,
        228,
        232,
        234,
        236,
        235,
        232,
        233,
        231,
        236,
        237,
        236,
        233,
        234,
        234,
        240,
        226,
        239,
        252,
        185,
        140,
        215,
        238,
        247,
        248,
        247,
        251,
        258,
        259,
        265,
        266,
        270,
        266,
        261,
        258,
        255,
        256,
        258,
        256,
        259,
        262,
        265,
        272,
        274,
        272,
        274,
        273,
        272,
        273,
        274,
        270,
        275,
        275,
        281,
        281,
        280,
        277,
        278,
        276,
        278,
        279,
        280,
        279,
        279,
        -858993460,
        -858993460
    ],
    "leftAnkle_z": [
        0,
        0,
        2413,
        2413,
        2419,
        2522,
        2572,
        2577,
        2580,
        2571,
        2570,
        2573,
        2572,
        2574,
        2571,
        2528,
        2554,
        2574,
        2580,
        2519,
        2481,
        2465,
        2461,
        2460,
        2458,
        2495,
        2477,
        2340,
        2397,
        2443,
        2537,
        2446,
        2424,
        2427,
        2504,
        2572,
        2392,
        2416,
        2431,
        2529,
        2472,
        2427,
        2405,
        2398,
        2407,
        2410,
        2408,
        2403,
        2399,
        2399,
        2399,
        2404,
        2406,
        2410,
        2420,
        2424,
        2437,
        2448,
        2455,
        2463,
        2469,
        2478,
        2482,
        2473,
        2471,
        2469,
        2467,
        2466,
        2466,
        2467,
        2472,
        2476,
        2478,
        2472,
        2468,
        2469,
        2456,
        2457,
        2334,
        2329,
        2319,
        2331,
        2370,
        2429,
        2463,
        2471,
        2457,
        2459,
        2491,
        2516,
        2532,
        2553,
        2555,
        2560,
        2589,
        2608,
        2608,
        2609,
        2602,
        2595,
        2587,
        2590,
        2586,
        2589,
        2583,
        2592,
        2592,
        2590,
        2589,
        2599,
        2596,
        2599,
        2592,
        2589,
        2590,
        2594,
        2592,
        2594,
        2592,
        2588,
        2587,
        2588,
        2588,
        -858993460,
        -858993460
    ],
    "leftClavicle_x": [
        0,
        0,
        224,
        222,
        213,
        225,
        239,
        246,
        241,
        243,
        237,
        237,
        228,
        227,
        231,
        214,
        219,
        215,
        213,
        200,
        189,
        185,
        183,
        177,
        168,
        167,
        152,
        162,
        149,
        136,
        128,
        111,
        92,
        77,
        68,
        62,
        58,
        57,
        56,
        35,
        37,
        43,
        41,
        41,
        36,
        39,
        41,
        45,
        42,
        34,
        25,
        9,
        -2,
        -5,
        -6,
        -5,
        -2,
        2,
        4,
        5,
        2,
        -4,
        -10,
        -13,
        -16,
        -12,
        -7,
        -2,
        0,
        -2,
        1,
        3,
        8,
        11,
        9,
        6,
        3,
        6,
        5,
        12,
        19,
        27,
        36,
        59,
        84,
        107,
        119,
        140,
        153,
        172,
        189,
        207,
        223,
        233,
        231,
        229,
        233,
        236,
        236,
        236,
        235,
        229,
        226,
        227,
        231,
        228,
        228,
        229,
        233,
        237,
        231,
        229,
        226,
        226,
        227,
        229,
        232,
        231,
        231,
        229,
        227,
        228,
        228,
        -858993460,
        -858993460
    ],
    "leftClavicle_y": [
        0,
        0,
        -978,
        -976,
        -976,
        -967,
        -963,
        -964,
        -966,
        -966,
        -970,
        -975,
        -975,
        -975,
        -973,
        -974,
        -978,
        -979,
        -980,
        -982,
        -983,
        -980,
        -974,
        -974,
        -977,
        -979,
        -984,
        -994,
        -993,
        -986,
        -980,
        -987,
        -992,
        -995,
        -998,
        -1001,
        -1010,
        -1004,
        -996,
        -991,
        -989,
        -991,
        -995,
        -1003,
        -1002,
        -1002,
        -1002,
        -1000,
        -997,
        -989,
        -983,
        -980,
        -978,
        -979,
        -977,
        -976,
        -979,
        -987,
        -995,
        -999,
        -998,
        -998,
        -1002,
        -1004,
        -1009,
        -1013,
        -1016,
        -1022,
        -1018,
        -1019,
        -1024,
        -1030,
        -1032,
        -1033,
        -1025,
        -1020,
        -1006,
        -999,
        -1001,
        -996,
        -1000,
        -1001,
        -1001,
        -999,
        -995,
        -988,
        -981,
        -979,
        -969,
        -962,
        -955,
        -953,
        -956,
        -960,
        -963,
        -964,
        -968,
        -969,
        -971,
        -970,
        -971,
        -971,
        -972,
        -973,
        -972,
        -973,
        -973,
        -973,
        -975,
        -976,
        -975,
        -975,
        -973,
        -973,
        -974,
        -975,
        -975,
        -977,
        -977,
        -977,
        -978,
        -978,
        -979,
        -858993460,
        -858993460
    ],
    "leftClavicle_z": [
        0,
        0,
        2322,
        2326,
        2325,
        2330,
        2329,
        2335,
        2337,
        2335,
        2333,
        2329,
        2334,
        2332,
        2332,
        2326,
        2324,
        2325,
        2329,
        2330,
        2331,
        2328,
        2321,
        2318,
        2317,
        2315,
        2317,
        2306,
        2297,
        2294,
        2308,
        2292,
        2284,
        2274,
        2264,
        2257,
        2280,
        2288,
        2290,
        2271,
        2268,
        2275,
        2280,
        2278,
        2275,
        2275,
        2279,
        2288,
        2295,
        2296,
        2297,
        2299,
        2298,
        2293,
        2292,
        2288,
        2288,
        2280,
        2271,
        2264,
        2269,
        2283,
        2297,
        2301,
        2301,
        2292,
        2290,
        2282,
        2283,
        2282,
        2283,
        2281,
        2272,
        2256,
        2240,
        2222,
        2204,
        2203,
        2210,
        2224,
        2243,
        2235,
        2243,
        2254,
        2261,
        2273,
        2291,
        2301,
        2307,
        2311,
        2318,
        2318,
        2318,
        2322,
        2324,
        2329,
        2332,
        2333,
        2332,
        2333,
        2334,
        2332,
        2328,
        2324,
        2320,
        2323,
        2323,
        2324,
        2328,
        2326,
        2328,
        2328,
        2328,
        2330,
        2329,
        2324,
        2325,
        2325,
        2325,
        2328,
        2330,
        2329,
        2330,
        -858993460,
        -858993460
    ],
    "leftEar_x": [
        0,
        0,
        139,
        136,
        115,
        131,
        149,
        159,
        154,
        159,
        148,
        149,
        137,
        133,
        137,
        107,
        114,
        108,
        109,
        94,
        79,
        78,
        81,
        80,
        72,
        73,
        54,
        77,
        62,
        48,
        40,
        34,
        24,
        10,
        2,
        0,
        4,
        12,
        19,
        -5,
        9,
        24,
        26,
        29,
        32,
        34,
        30,
        29,
        22,
        11,
        4,
        -17,
        -32,
        -38,
        -42,
        -44,
        -44,
        -41,
        -41,
        -41,
        -46,
        -51,
        -58,
        -59,
        -66,
        -68,
        -67,
        -68,
        -63,
        -65,
        -65,
        -67,
        -68,
        -74,
        -77,
        -83,
        -91,
        -86,
        -86,
        -74,
        -60,
        -52,
        -49,
        -31,
        -14,
        3,
        13,
        36,
        52,
        68,
        82,
        102,
        126,
        136,
        134,
        131,
        132,
        135,
        133,
        133,
        132,
        124,
        121,
        122,
        126,
        124,
        126,
        128,
        132,
        134,
        129,
        125,
        124,
        123,
        125,
        126,
        128,
        128,
        128,
        127,
        126,
        125,
        124,
        -858993460,
        -858993460
    ],
    "leftEar_y": [
        0,
        0,
        -1145,
        -1143,
        -1135,
        -1122,
        -1116,
        -1118,
        -1120,
        -1122,
        -1126,
        -1132,
        -1132,
        -1131,
        -1129,
        -1126,
        -1132,
        -1131,
        -1133,
        -1135,
        -1134,
        -1131,
        -1126,
        -1126,
        -1127,
        -1129,
        -1132,
        -1146,
        -1141,
        -1135,
        -1115,
        -1130,
        -1140,
        -1145,
        -1145,
        -1146,
        -1165,
        -1164,
        -1160,
        -1142,
        -1154,
        -1166,
        -1175,
        -1186,
        -1188,
        -1189,
        -1192,
        -1189,
        -1185,
        -1176,
        -1169,
        -1165,
        -1161,
        -1161,
        -1159,
        -1159,
        -1162,
        -1170,
        -1179,
        -1185,
        -1183,
        -1182,
        -1184,
        -1188,
        -1191,
        -1195,
        -1199,
        -1205,
        -1202,
        -1202,
        -1204,
        -1208,
        -1207,
        -1205,
        -1198,
        -1192,
        -1177,
        -1170,
        -1173,
        -1165,
        -1171,
        -1169,
        -1168,
        -1161,
        -1153,
        -1145,
        -1140,
        -1140,
        -1129,
        -1121,
        -1112,
        -1110,
        -1117,
        -1122,
        -1124,
        -1125,
        -1129,
        -1132,
        -1134,
        -1133,
        -1134,
        -1133,
        -1133,
        -1134,
        -1134,
        -1135,
        -1135,
        -1135,
        -1139,
        -1142,
        -1140,
        -1140,
        -1138,
        -1138,
        -1137,
        -1137,
        -1138,
        -1139,
        -1140,
        -1140,
        -1140,
        -1141,
        -1141,
        -858993460,
        -858993460
    ],
    "leftEar_z": [
        0,
        0,
        2274,
        2279,
        2276,
        2260,
        2248,
        2250,
        2252,
        2248,
        2249,
        2243,
        2251,
        2252,
        2251,
        2260,
        2254,
        2255,
        2255,
        2260,
        2265,
        2258,
        2244,
        2235,
        2232,
        2226,
        2231,
        2218,
        2203,
        2196,
        2193,
        2179,
        2169,
        2160,
        2145,
        2134,
        2163,
        2168,
        2169,
        2137,
        2144,
        2159,
        2168,
        2168,
        2169,
        2170,
        2176,
        2186,
        2193,
        2194,
        2194,
        2195,
        2194,
        2189,
        2188,
        2186,
        2185,
        2176,
        2167,
        2161,
        2165,
        2176,
        2188,
        2194,
        2194,
        2187,
        2188,
        2182,
        2184,
        2183,
        2181,
        2178,
        2170,
        2159,
        2148,
        2134,
        2125,
        2123,
        2140,
        2150,
        2166,
        2153,
        2162,
        2171,
        2182,
        2200,
        2226,
        2240,
        2241,
        2246,
        2256,
        2252,
        2245,
        2247,
        2245,
        2248,
        2253,
        2255,
        2256,
        2258,
        2259,
        2259,
        2254,
        2250,
        2247,
        2247,
        2244,
        2244,
        2249,
        2249,
        2250,
        2252,
        2251,
        2254,
        2253,
        2247,
        2250,
        2248,
        2248,
        2249,
        2250,
        2252,
        2254,
        -858993460,
        -858993460
    ],
    "leftElbow_x": [
        0,
        0,
        -42,
        -44,
        -48,
        -33,
        -20,
        -15,
        -20,
        -21,
        -28,
        -28,
        -35,
        -41,
        -38,
        -50,
        -47,
        -48,
        -45,
        -47,
        -49,
        -47,
        -38,
        -30,
        -22,
        -8,
        -2,
        22,
        7,
        5,
        28,
        46,
        58,
        59,
        65,
        69,
        65,
        66,
        78,
        93,
        114,
        135,
        147,
        152,
        155,
        154,
        153,
        155,
        154,
        144,
        134,
        125,
        115,
        108,
        108,
        106,
        105,
        105,
        103,
        98,
        92,
        88,
        84,
        68,
        55,
        38,
        22,
        -1,
        -23,
        -46,
        -56,
        -66,
        -80,
        -106,
        -132,
        -156,
        -178,
        -191,
        -192,
        -191,
        -186,
        -187,
        -183,
        -168,
        -151,
        -134,
        -125,
        -110,
        -96,
        -83,
        -70,
        -57,
        -44,
        -35,
        -35,
        -37,
        -36,
        -37,
        -40,
        -42,
        -45,
        -49,
        -53,
        -56,
        -55,
        -55,
        -54,
        -52,
        -52,
        -50,
        -52,
        -55,
        -58,
        -58,
        -57,
        -54,
        -53,
        -52,
        -52,
        -52,
        -52,
        -52,
        -52,
        -858993460,
        -858993460
    ],
    "leftElbow_y": [
        0,
        0,
        -755,
        -756,
        -747,
        -735,
        -732,
        -735,
        -736,
        -737,
        -738,
        -740,
        -737,
        -740,
        -740,
        -734,
        -739,
        -736,
        -735,
        -729,
        -726,
        -720,
        -711,
        -702,
        -697,
        -692,
        -688,
        -698,
        -682,
        -666,
        -661,
        -678,
        -691,
        -700,
        -705,
        -708,
        -717,
        -706,
        -696,
        -685,
        -682,
        -685,
        -689,
        -692,
        -690,
        -688,
        -688,
        -688,
        -686,
        -677,
        -669,
        -667,
        -664,
        -662,
        -661,
        -661,
        -665,
        -671,
        -678,
        -683,
        -685,
        -690,
        -701,
        -708,
        -716,
        -722,
        -727,
        -729,
        -728,
        -728,
        -731,
        -732,
        -732,
        -732,
        -727,
        -728,
        -726,
        -726,
        -738,
        -738,
        -737,
        -737,
        -735,
        -733,
        -735,
        -732,
        -726,
        -728,
        -715,
        -709,
        -706,
        -706,
        -710,
        -714,
        -712,
        -712,
        -715,
        -719,
        -722,
        -725,
        -728,
        -725,
        -727,
        -729,
        -732,
        -731,
        -731,
        -732,
        -734,
        -733,
        -732,
        -732,
        -733,
        -734,
        -734,
        -734,
        -736,
        -737,
        -737,
        -738,
        -738,
        -737,
        -737,
        -858993460,
        -858993460
    ],
    "leftElbow_z": [
        0,
        0,
        2234,
        2235,
        2245,
        2239,
        2233,
        2231,
        2233,
        2230,
        2232,
        2231,
        2235,
        2239,
        2235,
        2245,
        2240,
        2240,
        2235,
        2234,
        2236,
        2234,
        2223,
        2213,
        2207,
        2199,
        2199,
        2170,
        2169,
        2165,
        2150,
        2114,
        2093,
        2079,
        2072,
        2070,
        2076,
        2087,
        2091,
        2106,
        2098,
        2102,
        2108,
        2113,
        2119,
        2120,
        2118,
        2117,
        2118,
        2115,
        2116,
        2116,
        2114,
        2112,
        2111,
        2107,
        2104,
        2099,
        2090,
        2083,
        2080,
        2079,
        2076,
        2065,
        2055,
        2042,
        2035,
        2030,
        2031,
        2033,
        2039,
        2048,
        2053,
        2058,
        2070,
        2081,
        2096,
        2112,
        2108,
        2116,
        2127,
        2149,
        2170,
        2185,
        2187,
        2190,
        2192,
        2193,
        2201,
        2208,
        2214,
        2216,
        2220,
        2227,
        2234,
        2239,
        2241,
        2242,
        2240,
        2236,
        2234,
        2236,
        2235,
        2234,
        2233,
        2232,
        2229,
        2227,
        2229,
        2231,
        2232,
        2235,
        2233,
        2232,
        2230,
        2229,
        2228,
        2227,
        2227,
        2227,
        2227,
        2230,
        2232,
        -858993460,
        -858993460
    ],
    "leftEye_x": [
        0,
        0,
        75,
        74,
        63,
        64,
        73,
        76,
        71,
        72,
        64,
        63,
        52,
        51,
        55,
        42,
        46,
        42,
        38,
        28,
        17,
        15,
        12,
        3,
        -7,
        -12,
        -28,
        -16,
        -30,
        -47,
        -63,
        -75,
        -90,
        -102,
        -110,
        -111,
        -111,
        -105,
        -100,
        -124,
        -112,
        -99,
        -96,
        -95,
        -93,
        -90,
        -94,
        -94,
        -101,
        -111,
        -120,
        -140,
        -153,
        -158,
        -161,
        -163,
        -163,
        -160,
        -159,
        -158,
        -163,
        -169,
        -178,
        -180,
        -186,
        -186,
        -184,
        -182,
        -178,
        -181,
        -180,
        -183,
        -182,
        -181,
        -180,
        -180,
        -177,
        -169,
        -170,
        -164,
        -156,
        -147,
        -139,
        -118,
        -96,
        -76,
        -64,
        -41,
        -27,
        -8,
        8,
        25,
        42,
        51,
        49,
        46,
        48,
        50,
        50,
        50,
        49,
        42,
        40,
        41,
        47,
        43,
        42,
        43,
        46,
        50,
        46,
        44,
        42,
        42,
        43,
        45,
        48,
        47,
        46,
        44,
        42,
        43,
        43,
        -858993460,
        -858993460
    ],
    "leftEye_y": [
        0,
        0,
        -1150,
        -1151,
        -1147,
        -1133,
        -1123,
        -1120,
        -1124,
        -1123,
        -1126,
        -1130,
        -1130,
        -1128,
        -1124,
        -1128,
        -1133,
        -1135,
        -1136,
        -1143,
        -1147,
        -1148,
        -1144,
        -1146,
        -1151,
        -1147,
        -1151,
        -1165,
        -1163,
        -1152,
        -1132,
        -1143,
        -1150,
        -1154,
        -1156,
        -1161,
        -1172,
        -1173,
        -1169,
        -1158,
        -1170,
        -1183,
        -1191,
        -1195,
        -1198,
        -1196,
        -1190,
        -1179,
        -1173,
        -1160,
        -1154,
        -1149,
        -1147,
        -1147,
        -1144,
        -1143,
        -1146,
        -1155,
        -1162,
        -1171,
        -1170,
        -1170,
        -1168,
        -1173,
        -1174,
        -1178,
        -1178,
        -1183,
        -1183,
        -1186,
        -1189,
        -1188,
        -1187,
        -1187,
        -1188,
        -1186,
        -1177,
        -1179,
        -1179,
        -1174,
        -1180,
        -1177,
        -1173,
        -1164,
        -1152,
        -1139,
        -1131,
        -1132,
        -1130,
        -1119,
        -1105,
        -1104,
        -1114,
        -1120,
        -1127,
        -1130,
        -1129,
        -1129,
        -1128,
        -1126,
        -1127,
        -1126,
        -1129,
        -1130,
        -1131,
        -1134,
        -1135,
        -1136,
        -1136,
        -1135,
        -1135,
        -1134,
        -1135,
        -1134,
        -1134,
        -1135,
        -1134,
        -1136,
        -1135,
        -1138,
        -1138,
        -1139,
        -1138,
        -858993460,
        -858993460
    ],
    "leftEye_z": [
        0,
        0,
        2381,
        2387,
        2388,
        2364,
        2347,
        2343,
        2346,
        2338,
        2342,
        2335,
        2345,
        2349,
        2348,
        2369,
        2361,
        2363,
        2361,
        2369,
        2375,
        2367,
        2349,
        2335,
        2329,
        2319,
        2326,
        2303,
        2288,
        2279,
        2268,
        2245,
        2227,
        2219,
        2205,
        2194,
        2216,
        2216,
        2213,
        2180,
        2180,
        2190,
        2198,
        2195,
        2192,
        2197,
        2207,
        2217,
        2226,
        2227,
        2227,
        2232,
        2236,
        2233,
        2234,
        2234,
        2234,
        2226,
        2218,
        2215,
        2220,
        2228,
        2236,
        2239,
        2239,
        2237,
        2238,
        2238,
        2239,
        2239,
        2237,
        2233,
        2229,
        2229,
        2226,
        2218,
        2221,
        2222,
        2236,
        2241,
        2249,
        2237,
        2252,
        2263,
        2279,
        2299,
        2327,
        2341,
        2341,
        2349,
        2360,
        2354,
        2341,
        2343,
        2342,
        2345,
        2351,
        2353,
        2355,
        2357,
        2358,
        2360,
        2356,
        2353,
        2350,
        2350,
        2344,
        2343,
        2347,
        2349,
        2352,
        2355,
        2354,
        2357,
        2355,
        2350,
        2353,
        2351,
        2351,
        2351,
        2351,
        2354,
        2357,
        -858993460,
        -858993460
    ],
    "leftFoot_x": [
        0,
        0,
        -150,
        -142,
        29,
        -27,
        -84,
        -124,
        -116,
        -144,
        -132,
        -154,
        -123,
        -132,
        -157,
        37,
        -23,
        -38,
        -76,
        79,
        181,
        232,
        255,
        266,
        274,
        111,
        163,
        200,
        262,
        257,
        296,
        301,
        292,
        282,
        281,
        259,
        65,
        -84,
        -156,
        -59,
        -126,
        -181,
        -223,
        -256,
        -291,
        -316,
        -319,
        -315,
        -304,
        -297,
        -298,
        -287,
        -277,
        -269,
        -259,
        -250,
        -235,
        -225,
        -219,
        -223,
        -231,
        -231,
        -216,
        -213,
        -200,
        -197,
        -202,
        -204,
        -218,
        -216,
        -206,
        -188,
        -177,
        -178,
        -183,
        -129,
        -156,
        -179,
        77,
        207,
        93,
        -22,
        -93,
        -137,
        -167,
        -180,
        -174,
        -194,
        -186,
        -188,
        -200,
        -216,
        -224,
        -225,
        -226,
        -220,
        -214,
        -225,
        -223,
        -227,
        -227,
        -224,
        -223,
        -226,
        -225,
        -226,
        -230,
        -228,
        -229,
        -222,
        -217,
        -223,
        -222,
        -230,
        -235,
        -238,
        -234,
        -236,
        -229,
        -230,
        -231,
        -227,
        -226,
        -858993460,
        -858993460
    ],
    "leftFoot_y": [
        0,
        0,
        345,
        349,
        390,
        377,
        362,
        352,
        355,
        355,
        356,
        343,
        353,
        354,
        354,
        413,
        392,
        379,
        369,
        414,
        426,
        428,
        433,
        431,
        429,
        422,
        422,
        404,
        397,
        399,
        382,
        372,
        359,
        344,
        320,
        292,
        366,
        381,
        386,
        305,
        358,
        372,
        375,
        372,
        370,
        368,
        369,
        370,
        373,
        379,
        382,
        386,
        389,
        391,
        393,
        396,
        395,
        391,
        390,
        390,
        394,
        395,
        395,
        396,
        397,
        393,
        391,
        388,
        390,
        393,
        393,
        393,
        393,
        392,
        396,
        385,
        398,
        405,
        370,
        328,
        394,
        406,
        402,
        394,
        385,
        382,
        376,
        368,
        372,
        369,
        367,
        361,
        356,
        353,
        346,
        354,
        357,
        354,
        358,
        360,
        363,
        365,
        369,
        367,
        374,
        371,
        369,
        372,
        372,
        377,
        381,
        377,
        381,
        378,
        374,
        371,
        374,
        371,
        375,
        379,
        379,
        378,
        378,
        -858993460,
        -858993460
    ],
    "leftFoot_z": [
        0,
        0,
        2468,
        2470,
        2476,
        2571,
        2613,
        2610,
        2616,
        2603,
        2605,
        2609,
        2612,
        2618,
        2611,
        2578,
        2604,
        2630,
        2632,
        2570,
        2532,
        2518,
        2511,
        2509,
        2506,
        2538,
        2521,
        2313,
        2422,
        2509,
        2537,
        2399,
        2350,
        2339,
        2405,
        2469,
        2314,
        2360,
        2381,
        2556,
        2475,
        2415,
        2381,
        2374,
        2381,
        2390,
        2390,
        2385,
        2378,
        2378,
        2382,
        2391,
        2394,
        2404,
        2413,
        2419,
        2427,
        2438,
        2451,
        2460,
        2468,
        2469,
        2462,
        2445,
        2442,
        2439,
        2437,
        2441,
        2439,
        2441,
        2446,
        2452,
        2453,
        2453,
        2452,
        2477,
        2478,
        2481,
        2348,
        2348,
        2322,
        2348,
        2399,
        2465,
        2501,
        2514,
        2506,
        2511,
        2543,
        2573,
        2590,
        2605,
        2602,
        2605,
        2634,
        2654,
        2654,
        2656,
        2648,
        2640,
        2631,
        2635,
        2629,
        2631,
        2624,
        2633,
        2630,
        2626,
        2626,
        2643,
        2639,
        2642,
        2634,
        2629,
        2630,
        2634,
        2632,
        2634,
        2631,
        2625,
        2622,
        2625,
        2625,
        -858993460,
        -858993460
    ],
    "leftHip_x": [
        0,
        0,
        131,
        130,
        148,
        160,
        165,
        168,
        170,
        166,
        168,
        169,
        172,
        169,
        166,
        173,
        171,
        172,
        170,
        174,
        175,
        176,
        174,
        173,
        171,
        174,
        167,
        151,
        144,
        139,
        132,
        113,
        92,
        69,
        57,
        42,
        20,
        -6,
        -23,
        -19,
        -27,
        -38,
        -54,
        -75,
        -105,
        -131,
        -142,
        -148,
        -152,
        -162,
        -175,
        -185,
        -196,
        -203,
        -200,
        -197,
        -186,
        -178,
        -175,
        -175,
        -177,
        -172,
        -157,
        -154,
        -141,
        -133,
        -126,
        -118,
        -121,
        -114,
        -98,
        -76,
        -58,
        -51,
        -51,
        -49,
        -55,
        -51,
        -38,
        -24,
        -5,
        -6,
        3,
        24,
        43,
        70,
        96,
        108,
        127,
        142,
        151,
        163,
        173,
        178,
        182,
        183,
        185,
        182,
        181,
        180,
        178,
        174,
        170,
        169,
        167,
        168,
        170,
        171,
        171,
        170,
        167,
        166,
        163,
        162,
        162,
        162,
        163,
        163,
        163,
        162,
        161,
        163,
        164,
        -858993460,
        -858993460
    ],
    "leftHip_y": [
        0,
        0,
        -491,
        -489,
        -485,
        -477,
        -475,
        -475,
        -474,
        -473,
        -475,
        -479,
        -476,
        -475,
        -473,
        -472,
        -477,
        -477,
        -477,
        -478,
        -478,
        -475,
        -470,
        -469,
        -472,
        -472,
        -477,
        -488,
        -487,
        -482,
        -475,
        -480,
        -487,
        -492,
        -494,
        -499,
        -508,
        -501,
        -493,
        -487,
        -483,
        -486,
        -493,
        -504,
        -509,
        -518,
        -524,
        -528,
        -528,
        -523,
        -519,
        -514,
        -513,
        -514,
        -510,
        -506,
        -504,
        -507,
        -512,
        -514,
        -511,
        -508,
        -505,
        -506,
        -508,
        -510,
        -512,
        -517,
        -513,
        -513,
        -515,
        -516,
        -516,
        -517,
        -510,
        -505,
        -495,
        -489,
        -489,
        -486,
        -491,
        -494,
        -496,
        -494,
        -491,
        -483,
        -474,
        -472,
        -461,
        -454,
        -447,
        -445,
        -448,
        -452,
        -454,
        -454,
        -456,
        -458,
        -458,
        -457,
        -457,
        -456,
        -457,
        -457,
        -457,
        -457,
        -457,
        -457,
        -458,
        -460,
        -459,
        -458,
        -456,
        -456,
        -456,
        -457,
        -458,
        -459,
        -460,
        -460,
        -460,
        -460,
        -460,
        -858993460,
        -858993460
    ],
    "leftHip_z": [
        0,
        0,
        2322,
        2325,
        2353,
        2382,
        2393,
        2394,
        2395,
        2388,
        2385,
        2380,
        2382,
        2381,
        2379,
        2382,
        2382,
        2385,
        2384,
        2383,
        2384,
        2382,
        2376,
        2371,
        2368,
        2358,
        2358,
        2295,
        2326,
        2349,
        2365,
        2293,
        2256,
        2235,
        2234,
        2233,
        2240,
        2271,
        2278,
        2306,
        2288,
        2275,
        2267,
        2258,
        2250,
        2242,
        2235,
        2229,
        2221,
        2212,
        2206,
        2207,
        2205,
        2204,
        2205,
        2205,
        2212,
        2215,
        2220,
        2229,
        2244,
        2259,
        2267,
        2265,
        2267,
        2265,
        2265,
        2265,
        2267,
        2271,
        2277,
        2280,
        2278,
        2272,
        2265,
        2257,
        2252,
        2256,
        2214,
        2213,
        2223,
        2244,
        2265,
        2289,
        2302,
        2304,
        2301,
        2303,
        2316,
        2325,
        2334,
        2342,
        2345,
        2350,
        2361,
        2372,
        2375,
        2376,
        2374,
        2371,
        2368,
        2368,
        2365,
        2363,
        2362,
        2365,
        2366,
        2367,
        2371,
        2374,
        2374,
        2374,
        2371,
        2369,
        2369,
        2368,
        2369,
        2370,
        2370,
        2371,
        2372,
        2371,
        2371,
        -858993460,
        -858993460
    ],
    "leftKnee_x": [
        0,
        0,
        16,
        19,
        92,
        89,
        77,
        67,
        70,
        55,
        64,
        58,
        74,
        72,
        58,
        132,
        108,
        100,
        87,
        145,
        179,
        197,
        200,
        205,
        207,
        152,
        166,
        192,
        216,
        204,
        230,
        217,
        198,
        170,
        149,
        118,
        34,
        -50,
        -94,
        -158,
        -172,
        -192,
        -204,
        -219,
        -239,
        -260,
        -266,
        -266,
        -263,
        -268,
        -282,
        -290,
        -296,
        -298,
        -291,
        -287,
        -274,
        -259,
        -251,
        -248,
        -250,
        -247,
        -231,
        -226,
        -214,
        -209,
        -205,
        -198,
        -204,
        -199,
        -186,
        -168,
        -152,
        -147,
        -149,
        -150,
        -169,
        -161,
        -137,
        -92,
        -66,
        -102,
        -105,
        -100,
        -88,
        -76,
        -58,
        -50,
        -37,
        -26,
        -21,
        -13,
        -8,
        -4,
        5,
        9,
        13,
        9,
        9,
        8,
        7,
        11,
        11,
        8,
        4,
        5,
        3,
        4,
        3,
        2,
        1,
        0,
        1,
        -1,
        -1,
        -2,
        -1,
        0,
        3,
        1,
        0,
        2,
        3,
        -858993460,
        -858993460
    ],
    "leftKnee_y": [
        0,
        0,
        -124,
        -122,
        -105,
        -104,
        -107,
        -108,
        -108,
        -108,
        -107,
        -113,
        -106,
        -104,
        -103,
        -86,
        -96,
        -101,
        -103,
        -89,
        -85,
        -81,
        -76,
        -75,
        -77,
        -80,
        -83,
        -88,
        -93,
        -89,
        -92,
        -95,
        -102,
        -107,
        -114,
        -123,
        -109,
        -103,
        -98,
        -110,
        -109,
        -115,
        -120,
        -130,
        -131,
        -139,
        -143,
        -144,
        -141,
        -134,
        -130,
        -123,
        -120,
        -119,
        -115,
        -110,
        -108,
        -111,
        -116,
        -119,
        -117,
        -112,
        -108,
        -108,
        -109,
        -113,
        -115,
        -121,
        -118,
        -118,
        -120,
        -122,
        -122,
        -125,
        -119,
        -117,
        -114,
        -109,
        -100,
        -90,
        -93,
        -108,
        -118,
        -126,
        -131,
        -130,
        -123,
        -122,
        -117,
        -114,
        -109,
        -109,
        -113,
        -116,
        -116,
        -114,
        -114,
        -115,
        -113,
        -111,
        -109,
        -103,
        -102,
        -104,
        -104,
        -104,
        -105,
        -104,
        -105,
        -109,
        -106,
        -106,
        -100,
        -100,
        -100,
        -103,
        -102,
        -103,
        -103,
        -101,
        -101,
        -102,
        -102,
        -858993460,
        -858993460
    ],
    "leftKnee_z": [
        0,
        0,
        2405,
        2408,
        2431,
        2483,
        2504,
        2502,
        2505,
        2496,
        2494,
        2491,
        2493,
        2492,
        2489,
        2481,
        2491,
        2505,
        2503,
        2478,
        2465,
        2457,
        2451,
        2444,
        2440,
        2448,
        2441,
        2319,
        2372,
        2414,
        2452,
        2358,
        2320,
        2303,
        2330,
        2354,
        2278,
        2307,
        2316,
        2310,
        2301,
        2281,
        2275,
        2273,
        2280,
        2283,
        2279,
        2271,
        2262,
        2253,
        2245,
        2244,
        2243,
        2248,
        2253,
        2257,
        2266,
        2277,
        2291,
        2308,
        2323,
        2333,
        2332,
        2325,
        2323,
        2322,
        2324,
        2327,
        2330,
        2334,
        2337,
        2337,
        2335,
        2335,
        2334,
        2333,
        2341,
        2350,
        2264,
        2249,
        2248,
        2299,
        2347,
        2394,
        2419,
        2426,
        2421,
        2422,
        2443,
        2459,
        2471,
        2479,
        2479,
        2482,
        2500,
        2514,
        2515,
        2515,
        2511,
        2506,
        2501,
        2502,
        2498,
        2500,
        2497,
        2503,
        2503,
        2502,
        2504,
        2511,
        2511,
        2513,
        2508,
        2505,
        2505,
        2506,
        2506,
        2506,
        2505,
        2503,
        2502,
        2504,
        2504,
        -858993460,
        -858993460
    ],
    "leftShoulder_x": [
        0,
        0,
        146,
        144,
        131,
        153,
        173,
        183,
        177,
        180,
        171,
        172,
        161,
        159,
        164,
        138,
        145,
        142,
        145,
        131,
        120,
        115,
        121,
        122,
        119,
        128,
        115,
        118,
        94,
        79,
        95,
        92,
        88,
        83,
        82,
        84,
        77,
        78,
        84,
        83,
        88,
        99,
        102,
        105,
        104,
        107,
        108,
        111,
        107,
        98,
        88,
        70,
        57,
        50,
        49,
        48,
        49,
        50,
        50,
        47,
        41,
        36,
        32,
        25,
        20,
        14,
        11,
        7,
        4,
        -3,
        -2,
        -3,
        -5,
        -15,
        -27,
        -39,
        -57,
        -63,
        -54,
        -46,
        -37,
        -33,
        -27,
        -5,
        18,
        41,
        51,
        71,
        86,
        102,
        118,
        134,
        151,
        161,
        159,
        157,
        160,
        162,
        161,
        161,
        160,
        153,
        151,
        151,
        154,
        152,
        154,
        156,
        157,
        158,
        153,
        151,
        148,
        149,
        150,
        153,
        155,
        155,
        155,
        155,
        154,
        154,
        154,
        -858993460,
        -858993460
    ],
    "leftShoulder_y": [
        0,
        0,
        -949,
        -950,
        -947,
        -931,
        -922,
        -921,
        -924,
        -923,
        -926,
        -927,
        -929,
        -928,
        -927,
        -934,
        -936,
        -936,
        -935,
        -940,
        -944,
        -943,
        -937,
        -934,
        -935,
        -934,
        -940,
        -959,
        -946,
        -934,
        -930,
        -950,
        -963,
        -972,
        -978,
        -981,
        -986,
        -976,
        -967,
        -960,
        -956,
        -957,
        -958,
        -962,
        -961,
        -959,
        -959,
        -958,
        -955,
        -947,
        -939,
        -936,
        -933,
        -932,
        -932,
        -932,
        -937,
        -943,
        -952,
        -957,
        -958,
        -959,
        -965,
        -969,
        -974,
        -980,
        -985,
        -989,
        -986,
        -986,
        -988,
        -992,
        -993,
        -992,
        -986,
        -984,
        -979,
        -973,
        -979,
        -975,
        -972,
        -964,
        -959,
        -951,
        -949,
        -943,
        -939,
        -938,
        -925,
        -916,
        -911,
        -908,
        -908,
        -912,
        -913,
        -913,
        -916,
        -917,
        -920,
        -921,
        -923,
        -922,
        -922,
        -922,
        -922,
        -924,
        -924,
        -924,
        -927,
        -928,
        -929,
        -929,
        -929,
        -930,
        -930,
        -929,
        -931,
        -933,
        -933,
        -934,
        -935,
        -935,
        -935,
        -858993460,
        -858993460
    ],
    "leftShoulder_z": [
        0,
        0,
        2210,
        2212,
        2214,
        2214,
        2212,
        2217,
        2219,
        2216,
        2215,
        2212,
        2217,
        2216,
        2214,
        2212,
        2209,
        2209,
        2211,
        2211,
        2212,
        2207,
        2197,
        2192,
        2189,
        2184,
        2185,
        2174,
        2172,
        2173,
        2176,
        2154,
        2142,
        2132,
        2123,
        2116,
        2140,
        2148,
        2152,
        2139,
        2138,
        2147,
        2155,
        2156,
        2156,
        2156,
        2159,
        2167,
        2173,
        2173,
        2174,
        2175,
        2172,
        2166,
        2165,
        2159,
        2158,
        2149,
        2139,
        2131,
        2134,
        2148,
        2162,
        2164,
        2164,
        2153,
        2149,
        2140,
        2140,
        2140,
        2142,
        2140,
        2132,
        2118,
        2104,
        2088,
        2075,
        2078,
        2080,
        2094,
        2114,
        2110,
        2122,
        2135,
        2142,
        2153,
        2172,
        2182,
        2188,
        2194,
        2201,
        2203,
        2203,
        2206,
        2208,
        2213,
        2217,
        2218,
        2218,
        2218,
        2218,
        2216,
        2212,
        2208,
        2205,
        2206,
        2205,
        2206,
        2211,
        2212,
        2211,
        2212,
        2211,
        2211,
        2210,
        2206,
        2207,
        2206,
        2206,
        2207,
        2208,
        2208,
        2209,
        -858993460,
        -858993460
    ],
    "leftWrist_x": [
        0,
        0,
        -232,
        -234,
        -237,
        -229,
        -216,
        -213,
        -217,
        -218,
        -223,
        -226,
        -231,
        -235,
        -232,
        -238,
        -236,
        -234,
        -231,
        -230,
        -225,
        -211,
        -198,
        -187,
        -178,
        -160,
        -152,
        -122,
        -127,
        -129,
        -119,
        -114,
        -116,
        -120,
        -117,
        -114,
        -119,
        -117,
        -108,
        -115,
        -92,
        -70,
        -60,
        -57,
        -56,
        -58,
        -61,
        -63,
        -67,
        -78,
        -90,
        -104,
        -116,
        -124,
        -124,
        -127,
        -128,
        -128,
        -130,
        -134,
        -140,
        -143,
        -148,
        -162,
        -174,
        -189,
        -204,
        -224,
        -244,
        -263,
        -272,
        -282,
        -291,
        -306,
        -323,
        -338,
        -349,
        -356,
        -373,
        -371,
        -366,
        -371,
        -368,
        -355,
        -339,
        -322,
        -313,
        -301,
        -288,
        -273,
        -259,
        -247,
        -239,
        -233,
        -234,
        -235,
        -235,
        -235,
        -237,
        -239,
        -242,
        -245,
        -251,
        -252,
        -251,
        -251,
        -251,
        -250,
        -248,
        -244,
        -246,
        -247,
        -250,
        -250,
        -249,
        -246,
        -244,
        -243,
        -243,
        -244,
        -245,
        -244,
        -244,
        -858993460,
        -858993460
    ],
    "leftWrist_y": [
        0,
        0,
        -852,
        -852,
        -843,
        -832,
        -833,
        -838,
        -838,
        -841,
        -842,
        -842,
        -839,
        -845,
        -847,
        -839,
        -846,
        -846,
        -847,
        -845,
        -849,
        -853,
        -854,
        -853,
        -849,
        -842,
        -837,
        -842,
        -843,
        -834,
        -827,
        -831,
        -830,
        -831,
        -833,
        -836,
        -841,
        -837,
        -827,
        -787,
        -791,
        -795,
        -793,
        -794,
        -787,
        -784,
        -779,
        -771,
        -763,
        -752,
        -739,
        -722,
        -711,
        -706,
        -702,
        -701,
        -702,
        -706,
        -712,
        -717,
        -721,
        -724,
        -727,
        -732,
        -735,
        -736,
        -735,
        -738,
        -740,
        -742,
        -747,
        -755,
        -764,
        -777,
        -789,
        -805,
        -817,
        -831,
        -825,
        -832,
        -835,
        -835,
        -830,
        -831,
        -832,
        -833,
        -823,
        -824,
        -815,
        -814,
        -814,
        -816,
        -815,
        -817,
        -812,
        -813,
        -815,
        -823,
        -827,
        -830,
        -833,
        -831,
        -833,
        -838,
        -843,
        -842,
        -842,
        -843,
        -846,
        -849,
        -848,
        -848,
        -848,
        -850,
        -851,
        -853,
        -857,
        -856,
        -858,
        -858,
        -857,
        -856,
        -855,
        -858993460,
        -858993460
    ],
    "leftWrist_z": [
        0,
        0,
        2312,
        2313,
        2326,
        2305,
        2291,
        2283,
        2291,
        2287,
        2295,
        2292,
        2303,
        2308,
        2305,
        2330,
        2322,
        2326,
        2318,
        2319,
        2326,
        2333,
        2311,
        2297,
        2290,
        2292,
        2297,
        2285,
        2273,
        2257,
        2223,
        2189,
        2164,
        2152,
        2143,
        2136,
        2146,
        2149,
        2142,
        2076,
        2084,
        2089,
        2096,
        2097,
        2097,
        2100,
        2103,
        2108,
        2110,
        2111,
        2112,
        2121,
        2125,
        2125,
        2127,
        2127,
        2129,
        2128,
        2121,
        2117,
        2117,
        2121,
        2119,
        2118,
        2112,
        2108,
        2106,
        2111,
        2115,
        2127,
        2137,
        2143,
        2156,
        2177,
        2194,
        2210,
        2231,
        2243,
        2228,
        2233,
        2239,
        2252,
        2274,
        2284,
        2283,
        2283,
        2288,
        2286,
        2289,
        2293,
        2298,
        2297,
        2295,
        2298,
        2309,
        2316,
        2318,
        2319,
        2318,
        2315,
        2313,
        2318,
        2314,
        2314,
        2312,
        2312,
        2306,
        2302,
        2308,
        2310,
        2313,
        2318,
        2320,
        2318,
        2314,
        2311,
        2311,
        2309,
        2309,
        2308,
        2307,
        2312,
        2315,
        -858993460,
        -858993460
    ],
    "length": 123,
    "neck_x": [
        0,
        0,
        228,
        226,
        217,
        226,
        239,
        244,
        240,
        240,
        234,
        234,
        225,
        225,
        229,
        214,
        218,
        214,
        212,
        200,
        188,
        185,
        183,
        175,
        165,
        162,
        148,
        156,
        143,
        130,
        116,
        97,
        76,
        62,
        52,
        47,
        44,
        43,
        42,
        20,
        23,
        29,
        27,
        27,
        23,
        28,
        31,
        35,
        31,
        24,
        14,
        -2,
        -12,
        -15,
        -16,
        -16,
        -13,
        -9,
        -6,
        -6,
        -9,
        -16,
        -24,
        -27,
        -30,
        -27,
        -22,
        -16,
        -14,
        -15,
        -13,
        -13,
        -8,
        -3,
        -3,
        -4,
        -3,
        1,
        -1,
        6,
        12,
        21,
        31,
        55,
        80,
        103,
        115,
        136,
        150,
        169,
        186,
        204,
        219,
        229,
        226,
        224,
        228,
        231,
        231,
        232,
        230,
        225,
        221,
        223,
        227,
        224,
        223,
        224,
        228,
        233,
        228,
        226,
        223,
        223,
        224,
        226,
        229,
        228,
        228,
        225,
        224,
        225,
        225,
        -858993460,
        -858993460
    ],
    "neck_y": [
        0,
        0,
        -1013,
        -1012,
        -1013,
        -1005,
        -1002,
        -1003,
        -1005,
        -1005,
        -1009,
        -1014,
        -1014,
        -1014,
        -1011,
        -1013,
        -1017,
        -1019,
        -1020,
        -1022,
        -1023,
        -1019,
        -1014,
        -1014,
        -1017,
        -1018,
        -1023,
        -1032,
        -1031,
        -1026,
        -1019,
        -1025,
        -1029,
        -1032,
        -1035,
        -1039,
        -1047,
        -1043,
        -1035,
        -1031,
        -1027,
        -1029,
        -1034,
        -1042,
        -1041,
        -1041,
        -1042,
        -1039,
        -1037,
        -1029,
        -1022,
        -1019,
        -1017,
        -1018,
        -1017,
        -1015,
        -1018,
        -1026,
        -1035,
        -1039,
        -1037,
        -1037,
        -1041,
        -1043,
        -1048,
        -1052,
        -1054,
        -1060,
        -1056,
        -1057,
        -1062,
        -1067,
        -1069,
        -1070,
        -1063,
        -1058,
        -1045,
        -1038,
        -1038,
        -1033,
        -1036,
        -1039,
        -1040,
        -1037,
        -1034,
        -1027,
        -1018,
        -1016,
        -1006,
        -1000,
        -992,
        -991,
        -994,
        -999,
        -1002,
        -1003,
        -1007,
        -1009,
        -1010,
        -1009,
        -1010,
        -1009,
        -1011,
        -1012,
        -1011,
        -1012,
        -1012,
        -1013,
        -1014,
        -1016,
        -1015,
        -1015,
        -1013,
        -1013,
        -1013,
        -1014,
        -1014,
        -1016,
        -1017,
        -1017,
        -1018,
        -1018,
        -1018,
        -858993460,
        -858993460
    ],
    "neck_z": [
        0,
        0,
        2343,
        2346,
        2343,
        2346,
        2344,
        2350,
        2353,
        2351,
        2349,
        2345,
        2350,
        2349,
        2348,
        2343,
        2341,
        2341,
        2345,
        2346,
        2348,
        2343,
        2336,
        2334,
        2333,
        2331,
        2333,
        2325,
        2313,
        2309,
        2320,
        2307,
        2298,
        2288,
        2277,
        2268,
        2293,
        2299,
        2301,
        2279,
        2277,
        2285,
        2290,
        2288,
        2286,
        2286,
        2290,
        2301,
        2308,
        2309,
        2311,
        2313,
        2312,
        2307,
        2307,
        2303,
        2303,
        2294,
        2285,
        2278,
        2282,
        2296,
        2310,
        2314,
        2314,
        2306,
        2304,
        2296,
        2298,
        2297,
        2298,
        2295,
        2287,
        2272,
        2257,
        2239,
        2222,
        2220,
        2230,
        2245,
        2263,
        2254,
        2261,
        2271,
        2278,
        2290,
        2311,
        2322,
        2327,
        2330,
        2338,
        2337,
        2337,
        2340,
        2342,
        2346,
        2350,
        2350,
        2350,
        2351,
        2352,
        2350,
        2346,
        2342,
        2339,
        2341,
        2341,
        2342,
        2346,
        2344,
        2346,
        2346,
        2346,
        2348,
        2347,
        2342,
        2343,
        2343,
        2343,
        2346,
        2348,
        2347,
        2348,
        -858993460,
        -858993460
    ],
    "nose_x": [
        0,
        0,
        74,
        75,
        69,
        64,
        70,
        71,
        65,
        64,
        58,
        56,
        46,
        47,
        51,
        46,
        48,
        45,
        39,
        31,
        21,
        18,
        11,
        -1,
        -13,
        -21,
        -35,
        -30,
        -44,
        -61,
        -79,
        -97,
        -115,
        -127,
        -134,
        -136,
        -138,
        -135,
        -132,
        -156,
        -148,
        -137,
        -135,
        -133,
        -132,
        -128,
        -128,
        -127,
        -132,
        -141,
        -150,
        -169,
        -181,
        -185,
        -188,
        -189,
        -189,
        -186,
        -184,
        -183,
        -187,
        -195,
        -205,
        -208,
        -213,
        -211,
        -208,
        -205,
        -202,
        -204,
        -204,
        -206,
        -203,
        -198,
        -196,
        -194,
        -186,
        -178,
        -181,
        -177,
        -173,
        -163,
        -151,
        -128,
        -100,
        -78,
        -66,
        -43,
        -31,
        -10,
        8,
        24,
        36,
        44,
        42,
        39,
        42,
        45,
        45,
        45,
        44,
        39,
        36,
        39,
        44,
        40,
        37,
        37,
        40,
        46,
        42,
        41,
        39,
        39,
        39,
        42,
        45,
        44,
        44,
        40,
        38,
        40,
        40,
        -858993460,
        -858993460
    ],
    "nose_y": [
        0,
        0,
        -1123,
        -1125,
        -1124,
        -1112,
        -1101,
        -1096,
        -1100,
        -1099,
        -1100,
        -1104,
        -1105,
        -1102,
        -1097,
        -1104,
        -1109,
        -1111,
        -1113,
        -1121,
        -1126,
        -1128,
        -1125,
        -1128,
        -1133,
        -1128,
        -1133,
        -1146,
        -1145,
        -1133,
        -1115,
        -1124,
        -1128,
        -1132,
        -1135,
        -1142,
        -1149,
        -1149,
        -1144,
        -1137,
        -1146,
        -1158,
        -1164,
        -1166,
        -1169,
        -1166,
        -1156,
        -1144,
        -1137,
        -1124,
        -1118,
        -1113,
        -1112,
        -1113,
        -1110,
        -1108,
        -1111,
        -1120,
        -1127,
        -1135,
        -1135,
        -1134,
        -1133,
        -1138,
        -1138,
        -1142,
        -1141,
        -1145,
        -1145,
        -1150,
        -1153,
        -1151,
        -1151,
        -1151,
        -1155,
        -1155,
        -1148,
        -1152,
        -1151,
        -1147,
        -1153,
        -1151,
        -1146,
        -1137,
        -1125,
        -1112,
        -1102,
        -1102,
        -1103,
        -1092,
        -1077,
        -1076,
        -1087,
        -1092,
        -1101,
        -1104,
        -1102,
        -1100,
        -1099,
        -1096,
        -1097,
        -1097,
        -1101,
        -1102,
        -1103,
        -1107,
        -1108,
        -1109,
        -1107,
        -1105,
        -1107,
        -1105,
        -1107,
        -1106,
        -1106,
        -1107,
        -1106,
        -1108,
        -1108,
        -1111,
        -1111,
        -1112,
        -1111,
        -858993460,
        -858993460
    ],
    "nose_z": [
        0,
        0,
        2428,
        2435,
        2437,
        2414,
        2397,
        2393,
        2396,
        2387,
        2392,
        2384,
        2394,
        2398,
        2397,
        2419,
        2412,
        2414,
        2412,
        2420,
        2427,
        2420,
        2402,
        2388,
        2382,
        2371,
        2379,
        2354,
        2340,
        2330,
        2320,
        2293,
        2273,
        2265,
        2251,
        2240,
        2259,
        2258,
        2252,
        2222,
        2216,
        2223,
        2229,
        2224,
        2220,
        2226,
        2236,
        2247,
        2257,
        2259,
        2258,
        2265,
        2271,
        2270,
        2271,
        2271,
        2271,
        2263,
        2256,
        2252,
        2257,
        2265,
        2272,
        2274,
        2274,
        2274,
        2274,
        2275,
        2276,
        2276,
        2275,
        2270,
        2268,
        2270,
        2270,
        2264,
        2269,
        2271,
        2284,
        2288,
        2296,
        2284,
        2300,
        2312,
        2328,
        2348,
        2375,
        2389,
        2390,
        2398,
        2409,
        2403,
        2390,
        2392,
        2392,
        2396,
        2401,
        2402,
        2404,
        2406,
        2407,
        2409,
        2406,
        2403,
        2401,
        2401,
        2395,
        2394,
        2397,
        2398,
        2402,
        2405,
        2404,
        2408,
        2406,
        2401,
        2404,
        2402,
        2402,
        2402,
        2402,
        2406,
        2408,
        -858993460,
        -858993460
    ],
    "ownerID": "Jacob",
    "pelvis_x": [
        0,
        0,
        179,
        180,
        201,
        202,
        200,
        198,
        200,
        194,
        198,
        198,
        204,
        202,
        198,
        215,
        212,
        214,
        210,
        217,
        220,
        221,
        216,
        211,
        208,
        206,
        201,
        170,
        171,
        170,
        140,
        105,
        72,
        43,
        24,
        3,
        -13,
        -38,
        -58,
        -52,
        -59,
        -72,
        -91,
        -113,
        -145,
        -169,
        -180,
        -186,
        -191,
        -203,
        -217,
        -228,
        -237,
        -242,
        -238,
        -234,
        -222,
        -213,
        -208,
        -206,
        -207,
        -205,
        -194,
        -192,
        -181,
        -170,
        -161,
        -150,
        -152,
        -144,
        -129,
        -107,
        -88,
        -72,
        -63,
        -53,
        -46,
        -37,
        -24,
        -15,
        0,
        9,
        27,
        51,
        73,
        103,
        131,
        146,
        165,
        182,
        191,
        200,
        207,
        212,
        215,
        216,
        218,
        215,
        214,
        213,
        211,
        209,
        205,
        204,
        202,
        203,
        203,
        203,
        203,
        204,
        201,
        201,
        197,
        197,
        196,
        197,
        198,
        198,
        198,
        195,
        194,
        197,
        198,
        -858993460,
        -858993460
    ],
    "pelvis_y": [
        0,
        0,
        -491,
        -490,
        -492,
        -490,
        -490,
        -490,
        -490,
        -488,
        -490,
        -494,
        -491,
        -490,
        -487,
        -488,
        -493,
        -494,
        -493,
        -494,
        -496,
        -493,
        -488,
        -488,
        -491,
        -490,
        -495,
        -494,
        -500,
        -501,
        -494,
        -488,
        -490,
        -493,
        -498,
        -504,
        -512,
        -512,
        -507,
        -507,
        -502,
        -504,
        -511,
        -523,
        -530,
        -541,
        -546,
        -548,
        -546,
        -539,
        -536,
        -530,
        -527,
        -527,
        -523,
        -519,
        -517,
        -522,
        -529,
        -534,
        -533,
        -529,
        -525,
        -525,
        -526,
        -528,
        -530,
        -536,
        -533,
        -532,
        -534,
        -535,
        -535,
        -536,
        -529,
        -525,
        -515,
        -509,
        -500,
        -494,
        -497,
        -505,
        -508,
        -508,
        -506,
        -496,
        -484,
        -480,
        -471,
        -464,
        -457,
        -456,
        -460,
        -464,
        -467,
        -468,
        -471,
        -472,
        -472,
        -471,
        -470,
        -469,
        -470,
        -471,
        -470,
        -471,
        -471,
        -471,
        -472,
        -475,
        -473,
        -472,
        -469,
        -469,
        -469,
        -470,
        -471,
        -473,
        -474,
        -473,
        -474,
        -474,
        -474,
        -858993460,
        -858993460
    ],
    "pelvis_z": [
        0,
        0,
        2397,
        2399,
        2425,
        2460,
        2474,
        2477,
        2478,
        2473,
        2469,
        2465,
        2466,
        2465,
        2463,
        2461,
        2462,
        2464,
        2465,
        2462,
        2461,
        2459,
        2455,
        2452,
        2449,
        2442,
        2441,
        2384,
        2413,
        2434,
        2455,
        2384,
        2346,
        2323,
        2320,
        2316,
        2325,
        2356,
        2362,
        2389,
        2372,
        2358,
        2349,
        2339,
        2329,
        2322,
        2316,
        2310,
        2303,
        2293,
        2286,
        2287,
        2287,
        2287,
        2289,
        2290,
        2297,
        2300,
        2306,
        2315,
        2330,
        2344,
        2350,
        2348,
        2349,
        2348,
        2349,
        2350,
        2352,
        2357,
        2363,
        2365,
        2364,
        2361,
        2356,
        2348,
        2342,
        2346,
        2304,
        2304,
        2314,
        2333,
        2352,
        2375,
        2386,
        2388,
        2385,
        2386,
        2399,
        2407,
        2416,
        2425,
        2430,
        2435,
        2446,
        2457,
        2461,
        2462,
        2460,
        2457,
        2455,
        2454,
        2451,
        2449,
        2447,
        2451,
        2453,
        2454,
        2458,
        2461,
        2460,
        2460,
        2458,
        2456,
        2456,
        2455,
        2456,
        2456,
        2457,
        2458,
        2459,
        2458,
        2458,
        -858993460,
        -858993460
    ],
    "rightAnkle_x": [
        0,
        0,
        423,
        427,
        466,
        479,
        464,
        456,
        462,
        456,
        464,
        463,
        479,
        476,
        465,
        497,
        487,
        488,
        483,
        498,
        509,
        511,
        508,
        506,
        505,
        494,
        497,
        210,
        339,
        405,
        160,
        15,
        -60,
        -102,
        -122,
        -143,
        -148,
        -164,
        -172,
        -112,
        -149,
        -211,
        -293,
        -389,
        -509,
        -643,
        -763,
        -871,
        -936,
        -962,
        -967,
        -964,
        -962,
        -963,
        -964,
        -967,
        -974,
        -977,
        -959,
        -911,
        -827,
        -716,
        -596,
        -508,
        -454,
        -422,
        -415,
        -414,
        -421,
        -417,
        -395,
        -355,
        -310,
        -263,
        -215,
        -138,
        -45,
        30,
        1,
        -34,
        -54,
        151,
        272,
        337,
        374,
        404,
        440,
        444,
        462,
        466,
        464,
        460,
        459,
        461,
        473,
        480,
        479,
        474,
        474,
        472,
        472,
        478,
        478,
        476,
        470,
        476,
        478,
        479,
        479,
        475,
        481,
        482,
        488,
        487,
        487,
        483,
        482,
        482,
        484,
        485,
        484,
        486,
        487,
        -858993460,
        -858993460
    ],
    "rightAnkle_y": [
        0,
        0,
        228,
        227,
        221,
        225,
        228,
        231,
        232,
        235,
        235,
        233,
        235,
        240,
        246,
        241,
        239,
        238,
        241,
        235,
        229,
        228,
        229,
        227,
        224,
        231,
        223,
        280,
        253,
        233,
        256,
        279,
        278,
        271,
        262,
        253,
        251,
        245,
        246,
        142,
        25,
        -74,
        -145,
        -196,
        -250,
        -319,
        -403,
        -513,
        -633,
        -745,
        -834,
        -882,
        -912,
        -922,
        -907,
        -881,
        -823,
        -747,
        -654,
        -551,
        -457,
        -381,
        -337,
        -332,
        -336,
        -342,
        -344,
        -341,
        -339,
        -325,
        -306,
        -282,
        -257,
        -216,
        -165,
        -122,
        -66,
        -27,
        147,
        208,
        237,
        220,
        202,
        195,
        197,
        210,
        217,
        229,
        240,
        255,
        270,
        280,
        280,
        279,
        276,
        275,
        275,
        277,
        278,
        281,
        283,
        284,
        284,
        283,
        285,
        284,
        282,
        281,
        281,
        280,
        280,
        281,
        282,
        284,
        284,
        284,
        285,
        283,
        282,
        280,
        280,
        281,
        281,
        -858993460,
        -858993460
    ],
    "rightAnkle_z": [
        0,
        0,
        2312,
        2304,
        2382,
        2513,
        2583,
        2606,
        2603,
        2598,
        2588,
        2584,
        2576,
        2569,
        2572,
        2553,
        2562,
        2564,
        2565,
        2557,
        2548,
        2550,
        2557,
        2561,
        2561,
        2562,
        2557,
        2522,
        2576,
        2600,
        2704,
        2524,
        2417,
        2369,
        2371,
        2381,
        2400,
        2497,
        2533,
        2599,
        2534,
        2481,
        2449,
        2424,
        2399,
        2375,
        2351,
        2320,
        2294,
        2273,
        2260,
        2260,
        2270,
        2278,
        2278,
        2285,
        2292,
        2298,
        2315,
        2337,
        2355,
        2365,
        2374,
        2386,
        2395,
        2406,
        2412,
        2421,
        2427,
        2437,
        2447,
        2460,
        2472,
        2493,
        2508,
        2518,
        2527,
        2533,
        2466,
        2457,
        2454,
        2477,
        2455,
        2465,
        2469,
        2439,
        2389,
        2365,
        2391,
        2406,
        2422,
        2460,
        2486,
        2498,
        2524,
        2543,
        2550,
        2550,
        2546,
        2538,
        2531,
        2527,
        2527,
        2531,
        2531,
        2537,
        2544,
        2550,
        2548,
        2547,
        2539,
        2537,
        2530,
        2525,
        2527,
        2530,
        2532,
        2537,
        2538,
        2539,
        2541,
        2536,
        2533,
        -858993460,
        -858993460
    ],
    "rightClavicle_x": [
        0,
        0,
        250,
        249,
        242,
        248,
        259,
        262,
        258,
        257,
        253,
        252,
        244,
        245,
        248,
        238,
        241,
        238,
        234,
        225,
        215,
        211,
        208,
        199,
        189,
        185,
        171,
        175,
        165,
        153,
        134,
        108,
        82,
        65,
        53,
        45,
        42,
        40,
        37,
        17,
        18,
        22,
        18,
        16,
        10,
        14,
        17,
        21,
        18,
        11,
        1,
        -14,
        -23,
        -25,
        -25,
        -24,
        -20,
        -16,
        -13,
        -12,
        -15,
        -22,
        -29,
        -32,
        -35,
        -29,
        -23,
        -16,
        -13,
        -14,
        -11,
        -9,
        -3,
        6,
        9,
        11,
        15,
        20,
        18,
        24,
        29,
        39,
        50,
        74,
        101,
        125,
        138,
        160,
        173,
        193,
        211,
        228,
        241,
        250,
        248,
        246,
        250,
        253,
        253,
        254,
        252,
        247,
        244,
        246,
        250,
        247,
        246,
        246,
        249,
        255,
        250,
        248,
        245,
        245,
        245,
        248,
        251,
        250,
        250,
        247,
        245,
        246,
        247,
        -858993460,
        -858993460
    ],
    "rightClavicle_y": [
        0,
        0,
        -979,
        -978,
        -981,
        -975,
        -972,
        -974,
        -976,
        -975,
        -979,
        -985,
        -984,
        -984,
        -981,
        -983,
        -988,
        -989,
        -990,
        -992,
        -993,
        -990,
        -985,
        -985,
        -988,
        -990,
        -994,
        -1001,
        -1003,
        -999,
        -994,
        -996,
        -998,
        -1001,
        -1004,
        -1009,
        -1016,
        -1012,
        -1005,
        -1003,
        -998,
        -999,
        -1003,
        -1010,
        -1010,
        -1010,
        -1010,
        -1007,
        -1004,
        -996,
        -989,
        -986,
        -984,
        -985,
        -983,
        -981,
        -985,
        -993,
        -1001,
        -1006,
        -1005,
        -1005,
        -1009,
        -1010,
        -1015,
        -1019,
        -1021,
        -1028,
        -1023,
        -1025,
        -1030,
        -1036,
        -1038,
        -1039,
        -1032,
        -1027,
        -1014,
        -1008,
        -1005,
        -1000,
        -1004,
        -1007,
        -1009,
        -1007,
        -1004,
        -997,
        -988,
        -984,
        -975,
        -969,
        -961,
        -960,
        -963,
        -968,
        -972,
        -974,
        -977,
        -978,
        -980,
        -979,
        -979,
        -979,
        -980,
        -981,
        -980,
        -981,
        -982,
        -982,
        -984,
        -985,
        -984,
        -984,
        -982,
        -982,
        -982,
        -983,
        -983,
        -985,
        -986,
        -986,
        -986,
        -987,
        -987,
        -858993460,
        -858993460
    ],
    "rightClavicle_z": [
        0,
        0,
        2365,
        2368,
        2365,
        2373,
        2374,
        2382,
        2384,
        2383,
        2380,
        2376,
        2381,
        2380,
        2379,
        2371,
        2369,
        2369,
        2374,
        2374,
        2375,
        2370,
        2365,
        2364,
        2363,
        2362,
        2364,
        2356,
        2344,
        2341,
        2357,
        2343,
        2334,
        2323,
        2313,
        2305,
        2328,
        2336,
        2337,
        2317,
        2315,
        2321,
        2325,
        2322,
        2319,
        2319,
        2323,
        2333,
        2340,
        2341,
        2343,
        2345,
        2345,
        2341,
        2340,
        2337,
        2337,
        2329,
        2320,
        2314,
        2318,
        2332,
        2345,
        2349,
        2350,
        2342,
        2340,
        2332,
        2333,
        2333,
        2334,
        2331,
        2323,
        2308,
        2292,
        2273,
        2254,
        2253,
        2260,
        2274,
        2293,
        2285,
        2292,
        2302,
        2308,
        2320,
        2338,
        2348,
        2354,
        2357,
        2364,
        2365,
        2366,
        2370,
        2372,
        2377,
        2381,
        2381,
        2381,
        2381,
        2382,
        2380,
        2376,
        2372,
        2369,
        2371,
        2372,
        2373,
        2378,
        2375,
        2377,
        2377,
        2377,
        2379,
        2378,
        2373,
        2374,
        2374,
        2374,
        2377,
        2379,
        2378,
        2379,
        -858993460,
        -858993460
    ],
    "rightEar_x": [
        0,
        0,
        108,
        109,
        102,
        94,
        98,
        97,
        92,
        89,
        84,
        81,
        72,
        73,
        76,
        74,
        76,
        73,
        67,
        60,
        52,
        49,
        42,
        30,
        18,
        9,
        -5,
        0,
        -14,
        -33,
        -55,
        -73,
        -93,
        -105,
        -112,
        -112,
        -118,
        -114,
        -112,
        -135,
        -128,
        -116,
        -116,
        -116,
        -116,
        -111,
        -113,
        -114,
        -119,
        -129,
        -139,
        -157,
        -167,
        -170,
        -173,
        -173,
        -173,
        -169,
        -167,
        -165,
        -169,
        -177,
        -189,
        -192,
        -198,
        -195,
        -193,
        -188,
        -184,
        -186,
        -186,
        -190,
        -186,
        -178,
        -172,
        -167,
        -155,
        -144,
        -147,
        -145,
        -142,
        -132,
        -121,
        -99,
        -73,
        -52,
        -40,
        -16,
        -1,
        18,
        35,
        51,
        63,
        72,
        71,
        68,
        70,
        73,
        72,
        72,
        71,
        66,
        64,
        66,
        72,
        68,
        65,
        65,
        68,
        73,
        70,
        68,
        67,
        67,
        67,
        69,
        73,
        72,
        71,
        68,
        66,
        68,
        68,
        -858993460,
        -858993460
    ],
    "rightEar_y": [
        0,
        0,
        -1159,
        -1160,
        -1162,
        -1151,
        -1142,
        -1138,
        -1142,
        -1140,
        -1142,
        -1146,
        -1146,
        -1144,
        -1140,
        -1147,
        -1152,
        -1154,
        -1156,
        -1163,
        -1168,
        -1170,
        -1166,
        -1168,
        -1174,
        -1169,
        -1174,
        -1187,
        -1185,
        -1174,
        -1160,
        -1167,
        -1171,
        -1175,
        -1177,
        -1185,
        -1190,
        -1189,
        -1184,
        -1178,
        -1183,
        -1191,
        -1196,
        -1198,
        -1200,
        -1197,
        -1188,
        -1178,
        -1172,
        -1159,
        -1154,
        -1150,
        -1149,
        -1151,
        -1148,
        -1146,
        -1149,
        -1158,
        -1164,
        -1172,
        -1171,
        -1171,
        -1170,
        -1174,
        -1176,
        -1179,
        -1177,
        -1182,
        -1182,
        -1186,
        -1190,
        -1188,
        -1189,
        -1190,
        -1193,
        -1193,
        -1187,
        -1189,
        -1187,
        -1183,
        -1188,
        -1188,
        -1184,
        -1177,
        -1167,
        -1154,
        -1143,
        -1143,
        -1143,
        -1133,
        -1120,
        -1118,
        -1128,
        -1133,
        -1142,
        -1145,
        -1143,
        -1142,
        -1141,
        -1138,
        -1139,
        -1140,
        -1144,
        -1145,
        -1146,
        -1149,
        -1151,
        -1151,
        -1150,
        -1148,
        -1149,
        -1148,
        -1149,
        -1149,
        -1149,
        -1150,
        -1150,
        -1151,
        -1151,
        -1154,
        -1154,
        -1155,
        -1154,
        -858993460,
        -858993460
    ],
    "rightEar_z": [
        0,
        0,
        2440,
        2445,
        2442,
        2422,
        2408,
        2406,
        2409,
        2403,
        2407,
        2400,
        2409,
        2413,
        2412,
        2428,
        2421,
        2422,
        2422,
        2428,
        2432,
        2424,
        2408,
        2395,
        2390,
        2382,
        2388,
        2368,
        2352,
        2344,
        2332,
        2311,
        2294,
        2286,
        2271,
        2259,
        2283,
        2284,
        2280,
        2246,
        2246,
        2257,
        2265,
        2261,
        2258,
        2264,
        2274,
        2284,
        2294,
        2295,
        2295,
        2300,
        2306,
        2303,
        2304,
        2304,
        2304,
        2296,
        2289,
        2285,
        2291,
        2298,
        2306,
        2309,
        2309,
        2308,
        2309,
        2309,
        2310,
        2310,
        2308,
        2303,
        2300,
        2300,
        2296,
        2288,
        2287,
        2287,
        2302,
        2308,
        2318,
        2305,
        2318,
        2329,
        2343,
        2363,
        2391,
        2405,
        2405,
        2412,
        2423,
        2418,
        2407,
        2409,
        2408,
        2411,
        2417,
        2419,
        2421,
        2423,
        2425,
        2426,
        2421,
        2418,
        2415,
        2415,
        2410,
        2409,
        2414,
        2416,
        2418,
        2421,
        2420,
        2423,
        2421,
        2416,
        2419,
        2417,
        2417,
        2417,
        2418,
        2420,
        2422,
        -858993460,
        -858993460
    ],
    "rightElbow_x": [
        0,
        0,
        323,
        323,
        327,
        320,
        318,
        317,
        320,
        316,
        320,
        325,
        333,
        335,
        332,
        342,
        339,
        339,
        333,
        332,
        326,
        324,
        310,
        302,
        290,
        282,
        270,
        229,
        218,
        212,
        127,
        -39,
        -159,
        -226,
        -270,
        -295,
        -306,
        -317,
        -327,
        -325,
        -328,
        -326,
        -325,
        -320,
        -318,
        -303,
        -285,
        -250,
        -227,
        -220,
        -210,
        -209,
        -215,
        -214,
        -221,
        -214,
        -197,
        -181,
        -161,
        -157,
        -152,
        -154,
        -158,
        -153,
        -156,
        -126,
        -120,
        -106,
        -104,
        -94,
        -93,
        -97,
        -89,
        -47,
        -13,
        7,
        33,
        47,
        28,
        41,
        55,
        80,
        119,
        142,
        172,
        207,
        246,
        279,
        298,
        321,
        335,
        348,
        352,
        357,
        367,
        364,
        367,
        366,
        370,
        373,
        376,
        375,
        374,
        369,
        365,
        365,
        358,
        355,
        351,
        360,
        355,
        354,
        353,
        353,
        356,
        356,
        356,
        354,
        362,
        354,
        352,
        354,
        356,
        -858993460,
        -858993460
    ],
    "rightElbow_y": [
        0,
        0,
        -691,
        -685,
        -692,
        -699,
        -706,
        -704,
        -704,
        -703,
        -701,
        -705,
        -702,
        -695,
        -693,
        -689,
        -694,
        -695,
        -695,
        -700,
        -704,
        -709,
        -704,
        -707,
        -708,
        -708,
        -715,
        -710,
        -727,
        -729,
        -722,
        -738,
        -778,
        -817,
        -855,
        -885,
        -899,
        -908,
        -907,
        -881,
        -883,
        -879,
        -869,
        -862,
        -848,
        -839,
        -832,
        -810,
        -793,
        -773,
        -762,
        -757,
        -747,
        -743,
        -746,
        -738,
        -739,
        -752,
        -763,
        -777,
        -785,
        -797,
        -807,
        -798,
        -809,
        -815,
        -817,
        -818,
        -808,
        -809,
        -817,
        -820,
        -825,
        -829,
        -814,
        -806,
        -786,
        -779,
        -758,
        -741,
        -735,
        -743,
        -741,
        -737,
        -732,
        -713,
        -701,
        -693,
        -684,
        -677,
        -669,
        -675,
        -677,
        -680,
        -691,
        -695,
        -698,
        -701,
        -695,
        -690,
        -683,
        -676,
        -671,
        -669,
        -666,
        -673,
        -678,
        -680,
        -691,
        -706,
        -705,
        -701,
        -695,
        -685,
        -685,
        -685,
        -684,
        -690,
        -690,
        -690,
        -690,
        -685,
        -685,
        -858993460,
        -858993460
    ],
    "rightElbow_z": [
        0,
        0,
        2578,
        2575,
        2570,
        2603,
        2620,
        2628,
        2627,
        2626,
        2616,
        2609,
        2608,
        2599,
        2602,
        2575,
        2580,
        2580,
        2591,
        2584,
        2587,
        2593,
        2593,
        2596,
        2596,
        2604,
        2608,
        2594,
        2590,
        2587,
        2615,
        2586,
        2550,
        2517,
        2493,
        2472,
        2481,
        2483,
        2474,
        2469,
        2465,
        2465,
        2469,
        2469,
        2472,
        2488,
        2510,
        2549,
        2574,
        2579,
        2595,
        2611,
        2602,
        2592,
        2594,
        2589,
        2598,
        2603,
        2607,
        2611,
        2629,
        2656,
        2677,
        2676,
        2682,
        2682,
        2681,
        2673,
        2669,
        2671,
        2673,
        2667,
        2660,
        2650,
        2628,
        2602,
        2566,
        2557,
        2537,
        2541,
        2547,
        2537,
        2537,
        2547,
        2551,
        2547,
        2555,
        2553,
        2559,
        2558,
        2565,
        2578,
        2582,
        2584,
        2595,
        2606,
        2611,
        2615,
        2605,
        2598,
        2587,
        2575,
        2562,
        2557,
        2554,
        2565,
        2574,
        2582,
        2600,
        2611,
        2616,
        2613,
        2610,
        2600,
        2597,
        2592,
        2593,
        2598,
        2593,
        2600,
        2601,
        2594,
        2593,
        -858993460,
        -858993460
    ],
    "rightEye_x": [
        0,
        0,
        108,
        109,
        102,
        94,
        98,
        97,
        92,
        89,
        84,
        81,
        72,
        73,
        76,
        74,
        76,
        73,
        67,
        60,
        52,
        49,
        42,
        30,
        18,
        9,
        -5,
        0,
        -14,
        -33,
        -55,
        -73,
        -93,
        -105,
        -112,
        -112,
        -118,
        -114,
        -112,
        -135,
        -128,
        -116,
        -116,
        -116,
        -116,
        -111,
        -113,
        -114,
        -119,
        -129,
        -139,
        -157,
        -167,
        -170,
        -173,
        -173,
        -173,
        -169,
        -167,
        -165,
        -169,
        -177,
        -189,
        -192,
        -198,
        -195,
        -193,
        -188,
        -184,
        -186,
        -186,
        -190,
        -186,
        -178,
        -172,
        -167,
        -155,
        -144,
        -147,
        -145,
        -142,
        -132,
        -121,
        -99,
        -73,
        -52,
        -40,
        -16,
        -1,
        18,
        35,
        51,
        63,
        72,
        71,
        68,
        70,
        73,
        72,
        72,
        71,
        66,
        64,
        66,
        72,
        68,
        65,
        65,
        68,
        73,
        70,
        68,
        67,
        67,
        67,
        69,
        73,
        72,
        71,
        68,
        66,
        68,
        68,
        -858993460,
        -858993460
    ],
    "rightEye_y": [
        0,
        0,
        -1159,
        -1160,
        -1162,
        -1151,
        -1142,
        -1138,
        -1142,
        -1140,
        -1142,
        -1146,
        -1146,
        -1144,
        -1140,
        -1147,
        -1152,
        -1154,
        -1156,
        -1163,
        -1168,
        -1170,
        -1166,
        -1168,
        -1174,
        -1169,
        -1174,
        -1187,
        -1185,
        -1174,
        -1160,
        -1167,
        -1171,
        -1175,
        -1177,
        -1185,
        -1190,
        -1189,
        -1184,
        -1178,
        -1183,
        -1191,
        -1196,
        -1198,
        -1200,
        -1197,
        -1188,
        -1178,
        -1172,
        -1159,
        -1154,
        -1150,
        -1149,
        -1151,
        -1148,
        -1146,
        -1149,
        -1158,
        -1164,
        -1172,
        -1171,
        -1171,
        -1170,
        -1174,
        -1176,
        -1179,
        -1177,
        -1182,
        -1182,
        -1186,
        -1190,
        -1188,
        -1189,
        -1190,
        -1193,
        -1193,
        -1187,
        -1189,
        -1187,
        -1183,
        -1188,
        -1188,
        -1184,
        -1177,
        -1167,
        -1154,
        -1143,
        -1143,
        -1143,
        -1133,
        -1120,
        -1118,
        -1128,
        -1133,
        -1142,
        -1145,
        -1143,
        -1142,
        -1141,
        -1138,
        -1139,
        -1140,
        -1144,
        -1145,
        -1146,
        -1149,
        -1151,
        -1151,
        -1150,
        -1148,
        -1149,
        -1148,
        -1149,
        -1149,
        -1149,
        -1150,
        -1150,
        -1151,
        -1151,
        -1154,
        -1154,
        -1155,
        -1154,
        -858993460,
        -858993460
    ],
    "rightEye_z": [
        0,
        0,
        2440,
        2445,
        2442,
        2422,
        2408,
        2406,
        2409,
        2403,
        2407,
        2400,
        2409,
        2413,
        2412,
        2428,
        2421,
        2422,
        2422,
        2428,
        2432,
        2424,
        2408,
        2395,
        2390,
        2382,
        2388,
        2368,
        2352,
        2344,
        2332,
        2311,
        2294,
        2286,
        2271,
        2259,
        2283,
        2284,
        2280,
        2246,
        2246,
        2257,
        2265,
        2261,
        2258,
        2264,
        2274,
        2284,
        2294,
        2295,
        2295,
        2300,
        2306,
        2303,
        2304,
        2304,
        2304,
        2296,
        2289,
        2285,
        2291,
        2298,
        2306,
        2309,
        2309,
        2308,
        2309,
        2309,
        2310,
        2310,
        2308,
        2303,
        2300,
        2300,
        2296,
        2288,
        2287,
        2287,
        2302,
        2308,
        2318,
        2305,
        2318,
        2329,
        2343,
        2363,
        2391,
        2405,
        2405,
        2412,
        2423,
        2418,
        2407,
        2409,
        2408,
        2411,
        2417,
        2419,
        2421,
        2423,
        2425,
        2426,
        2421,
        2418,
        2415,
        2415,
        2410,
        2409,
        2414,
        2416,
        2418,
        2421,
        2420,
        2423,
        2421,
        2416,
        2419,
        2417,
        2417,
        2417,
        2418,
        2420,
        2422,
        -858993460,
        -858993460
    ],
    "rightFoot_x": [
        0,
        0,
        392,
        396,
        438,
        446,
        426,
        413,
        421,
        418,
        425,
        425,
        439,
        436,
        425,
        461,
        451,
        451,
        442,
        459,
        473,
        471,
        468,
        462,
        461,
        444,
        447,
        111,
        250,
        325,
        55,
        -88,
        -157,
        -193,
        -201,
        -210,
        -248,
        -278,
        -289,
        -171,
        -197,
        -247,
        -339,
        -470,
        -626,
        -788,
        -929,
        -1044,
        -1101,
        -1111,
        -1101,
        -1086,
        -1077,
        -1073,
        -1078,
        -1094,
        -1111,
        -1127,
        -1127,
        -1087,
        -1000,
        -874,
        -726,
        -605,
        -532,
        -494,
        -490,
        -497,
        -504,
        -499,
        -465,
        -408,
        -340,
        -278,
        -224,
        -134,
        -40,
        55,
        -29,
        -99,
        -138,
        113,
        259,
        339,
        380,
        411,
        436,
        434,
        452,
        452,
        446,
        441,
        438,
        441,
        452,
        465,
        463,
        460,
        458,
        456,
        456,
        458,
        457,
        456,
        454,
        460,
        461,
        461,
        460,
        466,
        470,
        470,
        474,
        471,
        469,
        465,
        464,
        463,
        465,
        469,
        468,
        468,
        470,
        -858993460,
        -858993460
    ],
    "rightFoot_y": [
        0,
        0,
        384,
        384,
        374,
        372,
        371,
        373,
        375,
        382,
        381,
        379,
        380,
        386,
        394,
        387,
        385,
        384,
        387,
        382,
        379,
        377,
        380,
        378,
        376,
        381,
        374,
        422,
        395,
        371,
        395,
        415,
        411,
        403,
        399,
        392,
        375,
        365,
        364,
        301,
        191,
        95,
        22,
        -43,
        -123,
        -224,
        -354,
        -521,
        -688,
        -835,
        -947,
        -1007,
        -1044,
        -1059,
        -1041,
        -1002,
        -934,
        -840,
        -708,
        -564,
        -424,
        -304,
        -218,
        -185,
        -178,
        -181,
        -185,
        -186,
        -184,
        -170,
        -145,
        -115,
        -85,
        -42,
        9,
        52,
        108,
        145,
        316,
        369,
        388,
        386,
        370,
        362,
        364,
        379,
        386,
        398,
        409,
        422,
        436,
        444,
        444,
        442,
        438,
        439,
        439,
        441,
        442,
        446,
        448,
        448,
        446,
        445,
        449,
        447,
        445,
        445,
        444,
        447,
        446,
        446,
        447,
        449,
        448,
        448,
        449,
        446,
        445,
        445,
        444,
        445,
        445,
        -858993460,
        -858993460
    ],
    "rightFoot_z": [
        0,
        0,
        2369,
        2360,
        2446,
        2591,
        2665,
        2689,
        2685,
        2676,
        2669,
        2666,
        2659,
        2650,
        2652,
        2636,
        2645,
        2649,
        2648,
        2639,
        2627,
        2630,
        2631,
        2634,
        2632,
        2634,
        2628,
        2534,
        2620,
        2669,
        2718,
        2492,
        2360,
        2303,
        2298,
        2304,
        2333,
        2445,
        2485,
        2564,
        2520,
        2477,
        2448,
        2424,
        2399,
        2372,
        2345,
        2311,
        2285,
        2264,
        2255,
        2258,
        2269,
        2278,
        2277,
        2282,
        2286,
        2290,
        2306,
        2329,
        2345,
        2354,
        2364,
        2381,
        2396,
        2413,
        2418,
        2428,
        2435,
        2446,
        2459,
        2475,
        2492,
        2516,
        2532,
        2544,
        2554,
        2557,
        2498,
        2473,
        2458,
        2507,
        2493,
        2507,
        2514,
        2477,
        2424,
        2401,
        2430,
        2449,
        2468,
        2513,
        2542,
        2555,
        2587,
        2605,
        2612,
        2611,
        2608,
        2599,
        2591,
        2592,
        2594,
        2600,
        2597,
        2604,
        2612,
        2617,
        2615,
        2609,
        2604,
        2603,
        2597,
        2591,
        2595,
        2598,
        2600,
        2606,
        2607,
        2605,
        2608,
        2603,
        2600,
        -858993460,
        -858993460
    ],
    "rightHip_x": [
        0,
        0,
        223,
        225,
        250,
        240,
        232,
        225,
        228,
        219,
        225,
        224,
        232,
        232,
        226,
        254,
        248,
        252,
        246,
        256,
        261,
        262,
        253,
        245,
        242,
        235,
        232,
        186,
        195,
        197,
        147,
        98,
        53,
        20,
        -6,
        -32,
        -42,
        -67,
        -89,
        -83,
        -89,
        -103,
        -124,
        -148,
        -180,
        -204,
        -214,
        -221,
        -227,
        -239,
        -256,
        -267,
        -274,
        -277,
        -273,
        -267,
        -255,
        -246,
        -238,
        -234,
        -234,
        -235,
        -228,
        -227,
        -216,
        -204,
        -194,
        -179,
        -180,
        -171,
        -156,
        -135,
        -114,
        -91,
        -75,
        -57,
        -37,
        -24,
        -12,
        -7,
        5,
        24,
        50,
        76,
        101,
        133,
        163,
        180,
        199,
        218,
        228,
        235,
        238,
        241,
        245,
        246,
        247,
        244,
        243,
        242,
        241,
        240,
        236,
        235,
        234,
        235,
        233,
        231,
        231,
        234,
        232,
        232,
        229,
        228,
        228,
        229,
        229,
        229,
        229,
        225,
        223,
        228,
        229,
        -858993460,
        -858993460
    ],
    "rightHip_y": [
        0,
        0,
        -492,
        -490,
        -499,
        -502,
        -504,
        -504,
        -504,
        -502,
        -504,
        -508,
        -505,
        -503,
        -500,
        -502,
        -507,
        -509,
        -508,
        -509,
        -511,
        -509,
        -505,
        -505,
        -508,
        -507,
        -512,
        -500,
        -513,
        -518,
        -511,
        -495,
        -492,
        -494,
        -501,
        -509,
        -516,
        -521,
        -520,
        -524,
        -519,
        -520,
        -526,
        -540,
        -550,
        -561,
        -566,
        -567,
        -562,
        -554,
        -550,
        -544,
        -539,
        -539,
        -535,
        -530,
        -529,
        -535,
        -545,
        -552,
        -552,
        -549,
        -544,
        -542,
        -542,
        -545,
        -547,
        -553,
        -550,
        -549,
        -551,
        -552,
        -551,
        -553,
        -546,
        -543,
        -533,
        -527,
        -510,
        -502,
        -503,
        -514,
        -519,
        -521,
        -519,
        -508,
        -493,
        -488,
        -480,
        -474,
        -466,
        -466,
        -470,
        -474,
        -479,
        -482,
        -484,
        -485,
        -485,
        -483,
        -481,
        -480,
        -481,
        -483,
        -482,
        -483,
        -484,
        -484,
        -485,
        -488,
        -485,
        -484,
        -482,
        -480,
        -480,
        -483,
        -483,
        -485,
        -486,
        -486,
        -486,
        -486,
        -486,
        -858993460,
        -858993460
    ],
    "rightHip_z": [
        0,
        0,
        2465,
        2466,
        2489,
        2530,
        2547,
        2552,
        2553,
        2549,
        2545,
        2541,
        2542,
        2540,
        2539,
        2533,
        2535,
        2536,
        2538,
        2533,
        2531,
        2529,
        2527,
        2526,
        2523,
        2518,
        2517,
        2465,
        2491,
        2510,
        2536,
        2467,
        2427,
        2403,
        2397,
        2390,
        2402,
        2433,
        2438,
        2464,
        2447,
        2432,
        2424,
        2412,
        2401,
        2394,
        2388,
        2383,
        2376,
        2366,
        2359,
        2360,
        2361,
        2363,
        2364,
        2366,
        2373,
        2376,
        2383,
        2393,
        2407,
        2420,
        2425,
        2422,
        2424,
        2423,
        2425,
        2427,
        2430,
        2435,
        2440,
        2443,
        2442,
        2441,
        2437,
        2429,
        2423,
        2426,
        2386,
        2387,
        2397,
        2414,
        2431,
        2453,
        2463,
        2464,
        2460,
        2461,
        2474,
        2481,
        2490,
        2499,
        2506,
        2512,
        2523,
        2534,
        2538,
        2539,
        2538,
        2535,
        2533,
        2531,
        2528,
        2527,
        2525,
        2529,
        2531,
        2533,
        2537,
        2539,
        2538,
        2538,
        2536,
        2534,
        2534,
        2533,
        2534,
        2535,
        2535,
        2537,
        2538,
        2536,
        2536,
        -858993460,
        -858993460
    ],
    "rightKnee_x": [
        0,
        0,
        288,
        292,
        317,
        325,
        320,
        317,
        319,
        312,
        320,
        323,
        333,
        336,
        328,
        347,
        341,
        341,
        340,
        343,
        346,
        344,
        333,
        327,
        325,
        324,
        321,
        141,
        220,
        262,
        96,
        -15,
        -77,
        -119,
        -142,
        -164,
        -169,
        -190,
        -207,
        -293,
        -397,
        -470,
        -517,
        -547,
        -571,
        -579,
        -574,
        -573,
        -574,
        -578,
        -585,
        -589,
        -592,
        -595,
        -594,
        -590,
        -587,
        -586,
        -582,
        -581,
        -580,
        -582,
        -580,
        -584,
        -577,
        -569,
        -560,
        -550,
        -550,
        -548,
        -544,
        -534,
        -520,
        -499,
        -473,
        -428,
        -356,
        -294,
        -222,
        -193,
        -160,
        -30,
        66,
        126,
        168,
        203,
        235,
        254,
        272,
        285,
        292,
        299,
        300,
        303,
        318,
        324,
        324,
        322,
        321,
        320,
        319,
        325,
        324,
        323,
        317,
        321,
        319,
        318,
        317,
        315,
        316,
        318,
        322,
        322,
        322,
        322,
        321,
        323,
        323,
        322,
        321,
        323,
        324,
        -858993460,
        -858993460
    ],
    "rightKnee_y": [
        0,
        0,
        -107,
        -106,
        -112,
        -119,
        -123,
        -124,
        -123,
        -120,
        -121,
        -125,
        -122,
        -120,
        -115,
        -115,
        -120,
        -120,
        -119,
        -121,
        -122,
        -120,
        -116,
        -117,
        -119,
        -119,
        -124,
        -102,
        -117,
        -128,
        -121,
        -109,
        -111,
        -118,
        -125,
        -133,
        -136,
        -139,
        -136,
        -182,
        -261,
        -356,
        -456,
        -547,
        -631,
        -702,
        -743,
        -761,
        -767,
        -775,
        -786,
        -790,
        -791,
        -792,
        -786,
        -780,
        -766,
        -761,
        -765,
        -766,
        -765,
        -753,
        -730,
        -715,
        -703,
        -699,
        -702,
        -704,
        -706,
        -692,
        -666,
        -629,
        -586,
        -529,
        -462,
        -388,
        -305,
        -247,
        -173,
        -147,
        -137,
        -123,
        -124,
        -127,
        -127,
        -115,
        -99,
        -93,
        -85,
        -77,
        -69,
        -69,
        -72,
        -76,
        -82,
        -84,
        -86,
        -86,
        -85,
        -82,
        -81,
        -80,
        -81,
        -82,
        -81,
        -82,
        -82,
        -83,
        -83,
        -84,
        -82,
        -82,
        -80,
        -78,
        -79,
        -80,
        -80,
        -83,
        -84,
        -84,
        -85,
        -84,
        -84,
        -858993460,
        -858993460
    ],
    "rightKnee_z": [
        0,
        0,
        2428,
        2426,
        2485,
        2557,
        2592,
        2601,
        2602,
        2596,
        2589,
        2584,
        2581,
        2575,
        2575,
        2573,
        2576,
        2581,
        2579,
        2580,
        2581,
        2584,
        2588,
        2587,
        2585,
        2575,
        2575,
        2509,
        2559,
        2585,
        2630,
        2489,
        2403,
        2361,
        2348,
        2340,
        2376,
        2446,
        2466,
        2484,
        2450,
        2415,
        2388,
        2370,
        2357,
        2355,
        2358,
        2356,
        2351,
        2339,
        2330,
        2330,
        2334,
        2338,
        2339,
        2344,
        2350,
        2354,
        2359,
        2364,
        2360,
        2348,
        2333,
        2325,
        2322,
        2327,
        2335,
        2347,
        2357,
        2371,
        2382,
        2396,
        2412,
        2444,
        2474,
        2500,
        2530,
        2541,
        2463,
        2434,
        2432,
        2482,
        2493,
        2510,
        2514,
        2502,
        2480,
        2466,
        2487,
        2498,
        2507,
        2523,
        2536,
        2544,
        2559,
        2572,
        2578,
        2577,
        2574,
        2568,
        2563,
        2560,
        2557,
        2559,
        2559,
        2563,
        2568,
        2572,
        2574,
        2576,
        2573,
        2571,
        2565,
        2561,
        2561,
        2562,
        2563,
        2565,
        2566,
        2567,
        2568,
        2566,
        2564,
        -858993460,
        -858993460
    ],
    "rightShoulder_x": [
        0,
        0,
        305,
        304,
        305,
        297,
        300,
        299,
        296,
        294,
        293,
        294,
        290,
        292,
        292,
        295,
        296,
        293,
        286,
        283,
        275,
        272,
        264,
        253,
        242,
        231,
        219,
        198,
        185,
        166,
        118,
        62,
        14,
        -13,
        -34,
        -46,
        -52,
        -58,
        -65,
        -79,
        -77,
        -74,
        -78,
        -80,
        -85,
        -78,
        -69,
        -60,
        -60,
        -67,
        -75,
        -90,
        -98,
        -97,
        -98,
        -93,
        -86,
        -79,
        -69,
        -63,
        -65,
        -72,
        -81,
        -80,
        -83,
        -69,
        -62,
        -50,
        -46,
        -44,
        -42,
        -39,
        -29,
        -6,
        9,
        21,
        37,
        44,
        44,
        39,
        39,
        61,
        85,
        112,
        140,
        168,
        187,
        214,
        229,
        251,
        267,
        282,
        293,
        301,
        300,
        298,
        303,
        306,
        307,
        308,
        308,
        305,
        302,
        303,
        305,
        301,
        297,
        295,
        298,
        307,
        301,
        300,
        297,
        296,
        297,
        300,
        302,
        301,
        302,
        296,
        294,
        297,
        297,
        -858993460,
        -858993460
    ],
    "rightShoulder_y": [
        0,
        0,
        -947,
        -943,
        -950,
        -951,
        -953,
        -953,
        -953,
        -953,
        -955,
        -960,
        -958,
        -954,
        -952,
        -951,
        -956,
        -956,
        -956,
        -960,
        -964,
        -965,
        -960,
        -961,
        -962,
        -958,
        -964,
        -969,
        -983,
        -984,
        -976,
        -975,
        -978,
        -982,
        -988,
        -996,
        -1006,
        -1007,
        -1001,
        -1007,
        -1000,
        -996,
        -996,
        -999,
        -997,
        -994,
        -992,
        -988,
        -984,
        -974,
        -968,
        -964,
        -962,
        -964,
        -963,
        -959,
        -962,
        -971,
        -979,
        -986,
        -985,
        -987,
        -989,
        -988,
        -993,
        -998,
        -998,
        -1003,
        -997,
        -999,
        -1006,
        -1010,
        -1015,
        -1022,
        -1017,
        -1016,
        -1008,
        -1006,
        -1001,
        -991,
        -990,
        -996,
        -995,
        -991,
        -986,
        -972,
        -959,
        -953,
        -943,
        -937,
        -930,
        -934,
        -938,
        -943,
        -949,
        -952,
        -956,
        -959,
        -957,
        -953,
        -950,
        -945,
        -942,
        -942,
        -941,
        -946,
        -949,
        -951,
        -959,
        -969,
        -967,
        -965,
        -959,
        -953,
        -953,
        -954,
        -954,
        -958,
        -958,
        -958,
        -958,
        -955,
        -955,
        -858993460,
        -858993460
    ],
    "rightShoulder_z": [
        0,
        0,
        2477,
        2479,
        2473,
        2490,
        2495,
        2505,
        2506,
        2506,
        2502,
        2498,
        2501,
        2498,
        2499,
        2485,
        2485,
        2484,
        2491,
        2488,
        2488,
        2485,
        2481,
        2482,
        2481,
        2482,
        2483,
        2482,
        2474,
        2472,
        2488,
        2466,
        2446,
        2429,
        2412,
        2399,
        2420,
        2423,
        2421,
        2408,
        2407,
        2412,
        2415,
        2412,
        2410,
        2413,
        2423,
        2436,
        2446,
        2448,
        2450,
        2453,
        2454,
        2451,
        2452,
        2450,
        2452,
        2446,
        2440,
        2436,
        2441,
        2455,
        2468,
        2473,
        2473,
        2468,
        2467,
        2459,
        2461,
        2461,
        2462,
        2459,
        2453,
        2440,
        2426,
        2406,
        2386,
        2384,
        2390,
        2406,
        2425,
        2414,
        2418,
        2427,
        2433,
        2442,
        2457,
        2464,
        2470,
        2472,
        2480,
        2483,
        2486,
        2490,
        2493,
        2498,
        2502,
        2503,
        2502,
        2502,
        2501,
        2497,
        2492,
        2488,
        2486,
        2490,
        2493,
        2495,
        2502,
        2500,
        2501,
        2501,
        2500,
        2501,
        2501,
        2495,
        2497,
        2497,
        2497,
        2501,
        2503,
        2500,
        2501,
        -858993460,
        -858993460
    ],
    "rightWrist_x": [
        0,
        0,
        228,
        234,
        232,
        221,
        216,
        219,
        229,
        225,
        233,
        237,
        241,
        255,
        247,
        259,
        252,
        252,
        244,
        239,
        231,
        226,
        206,
        203,
        198,
        188,
        174,
        158,
        153,
        154,
        111,
        -240,
        -335,
        -337,
        -325,
        -312,
        -294,
        -284,
        -292,
        -328,
        -329,
        -326,
        -330,
        -344,
        -363,
        -377,
        -387,
        -368,
        -361,
        -364,
        -358,
        -354,
        -385,
        -403,
        -421,
        -424,
        -412,
        -399,
        -383,
        -381,
        -373,
        -366,
        -355,
        -351,
        -345,
        -323,
        -316,
        -308,
        -311,
        -303,
        -295,
        -281,
        -262,
        -222,
        -219,
        -218,
        -204,
        -184,
        -199,
        -188,
        -166,
        -108,
        -36,
        7,
        49,
        100,
        134,
        162,
        193,
        220,
        241,
        251,
        267,
        271,
        294,
        291,
        292,
        289,
        308,
        312,
        320,
        321,
        342,
        340,
        343,
        333,
        322,
        308,
        275,
        254,
        242,
        249,
        258,
        274,
        280,
        279,
        278,
        265,
        285,
        266,
        266,
        276,
        279,
        -858993460,
        -858993460
    ],
    "rightWrist_y": [
        0,
        0,
        -515,
        -506,
        -521,
        -518,
        -521,
        -510,
        -505,
        -500,
        -495,
        -497,
        -494,
        -481,
        -480,
        -480,
        -486,
        -485,
        -485,
        -495,
        -505,
        -513,
        -509,
        -507,
        -504,
        -496,
        -504,
        -486,
        -502,
        -502,
        -488,
        -643,
        -822,
        -926,
        -987,
        -1023,
        -1036,
        -1038,
        -1034,
        -1036,
        -1048,
        -1053,
        -1051,
        -1048,
        -1033,
        -1014,
        -988,
        -943,
        -907,
        -871,
        -843,
        -817,
        -802,
        -791,
        -786,
        -773,
        -763,
        -764,
        -768,
        -777,
        -777,
        -773,
        -775,
        -765,
        -768,
        -771,
        -768,
        -767,
        -759,
        -750,
        -754,
        -766,
        -777,
        -783,
        -780,
        -784,
        -758,
        -726,
        -700,
        -692,
        -659,
        -608,
        -571,
        -551,
        -538,
        -508,
        -498,
        -493,
        -476,
        -468,
        -457,
        -465,
        -462,
        -463,
        -470,
        -475,
        -478,
        -482,
        -469,
        -462,
        -452,
        -443,
        -434,
        -431,
        -427,
        -436,
        -441,
        -446,
        -464,
        -494,
        -498,
        -489,
        -478,
        -460,
        -458,
        -459,
        -459,
        -468,
        -463,
        -468,
        -467,
        -459,
        -458,
        -858993460,
        -858993460
    ],
    "rightWrist_z": [
        0,
        0,
        2691,
        2690,
        2692,
        2706,
        2713,
        2707,
        2703,
        2694,
        2680,
        2669,
        2660,
        2651,
        2651,
        2644,
        2645,
        2643,
        2647,
        2656,
        2670,
        2679,
        2675,
        2672,
        2670,
        2651,
        2656,
        2621,
        2622,
        2622,
        2649,
        2505,
        2398,
        2339,
        2304,
        2282,
        2290,
        2288,
        2277,
        2291,
        2297,
        2306,
        2319,
        2325,
        2333,
        2348,
        2366,
        2392,
        2414,
        2417,
        2427,
        2431,
        2443,
        2455,
        2468,
        2480,
        2493,
        2503,
        2513,
        2525,
        2537,
        2546,
        2544,
        2544,
        2540,
        2553,
        2553,
        2553,
        2558,
        2569,
        2560,
        2522,
        2501,
        2493,
        2511,
        2524,
        2553,
        2581,
        2577,
        2576,
        2583,
        2582,
        2587,
        2595,
        2600,
        2595,
        2594,
        2593,
        2598,
        2603,
        2613,
        2629,
        2632,
        2630,
        2646,
        2662,
        2666,
        2672,
        2657,
        2644,
        2624,
        2607,
        2590,
        2585,
        2581,
        2597,
        2609,
        2620,
        2639,
        2663,
        2671,
        2667,
        2661,
        2645,
        2640,
        2636,
        2638,
        2643,
        2636,
        2643,
        2644,
        2635,
        2634,
        -858993460,
        -858993460
    ],
    "spineChest_x": [
        0,
        0,
        215,
        214,
        214,
        220,
        229,
        232,
        229,
        228,
        226,
        227,
        221,
        221,
        223,
        218,
        220,
        217,
        213,
        207,
        199,
        195,
        191,
        183,
        176,
        173,
        161,
        163,
        156,
        148,
        132,
        105,
        77,
        57,
        43,
        30,
        25,
        17,
        10,
        -5,
        -5,
        -3,
        -10,
        -17,
        -29,
        -32,
        -30,
        -27,
        -29,
        -37,
        -46,
        -59,
        -69,
        -72,
        -70,
        -68,
        -61,
        -56,
        -52,
        -51,
        -53,
        -56,
        -57,
        -59,
        -57,
        -51,
        -43,
        -34,
        -33,
        -31,
        -25,
        -17,
        -9,
        -1,
        -1,
        -2,
        -2,
        2,
        2,
        9,
        17,
        21,
        32,
        56,
        83,
        112,
        127,
        147,
        161,
        181,
        198,
        215,
        227,
        235,
        235,
        234,
        237,
        239,
        240,
        240,
        239,
        233,
        230,
        231,
        234,
        231,
        231,
        231,
        234,
        239,
        232,
        231,
        226,
        226,
        226,
        229,
        231,
        230,
        230,
        227,
        226,
        228,
        228,
        -858993460,
        -858993460
    ],
    "spineChest_y": [
        0,
        0,
        -802,
        -801,
        -803,
        -798,
        -795,
        -796,
        -797,
        -796,
        -800,
        -804,
        -803,
        -803,
        -800,
        -801,
        -806,
        -807,
        -808,
        -810,
        -811,
        -808,
        -803,
        -803,
        -805,
        -806,
        -811,
        -816,
        -819,
        -816,
        -810,
        -810,
        -813,
        -816,
        -820,
        -824,
        -832,
        -829,
        -822,
        -820,
        -815,
        -816,
        -821,
        -829,
        -830,
        -832,
        -832,
        -830,
        -826,
        -817,
        -810,
        -806,
        -804,
        -804,
        -802,
        -800,
        -802,
        -810,
        -818,
        -823,
        -821,
        -820,
        -823,
        -824,
        -829,
        -833,
        -835,
        -841,
        -836,
        -838,
        -843,
        -848,
        -850,
        -851,
        -844,
        -840,
        -828,
        -822,
        -820,
        -815,
        -819,
        -824,
        -826,
        -824,
        -821,
        -813,
        -803,
        -800,
        -791,
        -784,
        -776,
        -775,
        -778,
        -783,
        -786,
        -788,
        -791,
        -792,
        -792,
        -791,
        -791,
        -791,
        -792,
        -793,
        -792,
        -793,
        -793,
        -793,
        -795,
        -797,
        -796,
        -795,
        -793,
        -793,
        -793,
        -794,
        -794,
        -796,
        -797,
        -797,
        -797,
        -798,
        -798,
        -858993460,
        -858993460
    ],
    "spineChest_z": [
        0,
        0,
        2367,
        2370,
        2379,
        2395,
        2398,
        2403,
        2405,
        2402,
        2399,
        2394,
        2399,
        2397,
        2396,
        2393,
        2391,
        2393,
        2396,
        2397,
        2399,
        2397,
        2391,
        2389,
        2387,
        2382,
        2384,
        2359,
        2363,
        2366,
        2384,
        2351,
        2332,
        2318,
        2310,
        2304,
        2321,
        2332,
        2333,
        2330,
        2318,
        2316,
        2316,
        2308,
        2302,
        2300,
        2302,
        2310,
        2315,
        2315,
        2316,
        2320,
        2321,
        2318,
        2319,
        2315,
        2317,
        2311,
        2304,
        2301,
        2308,
        2322,
        2335,
        2336,
        2338,
        2330,
        2328,
        2322,
        2323,
        2324,
        2327,
        2325,
        2319,
        2307,
        2294,
        2277,
        2263,
        2265,
        2258,
        2270,
        2287,
        2289,
        2301,
        2316,
        2324,
        2332,
        2343,
        2350,
        2359,
        2363,
        2370,
        2372,
        2375,
        2378,
        2384,
        2391,
        2395,
        2395,
        2394,
        2393,
        2392,
        2391,
        2387,
        2384,
        2381,
        2384,
        2385,
        2387,
        2391,
        2390,
        2391,
        2392,
        2391,
        2392,
        2391,
        2387,
        2388,
        2389,
        2389,
        2391,
        2393,
        2392,
        2392,
        -858993460,
        -858993460
    ],
    "spineNaval_x": [
        0,
        0,
        200,
        200,
        207,
        211,
        217,
        218,
        217,
        214,
        215,
        215,
        214,
        214,
        213,
        216,
        216,
        215,
        211,
        209,
        205,
        203,
        197,
        190,
        185,
        184,
        174,
        163,
        160,
        156,
        134,
        102,
        71,
        46,
        30,
        13,
        4,
        -12,
        -24,
        -34,
        -34,
        -36,
        -48,
        -62,
        -82,
        -94,
        -97,
        -96,
        -98,
        -107,
        -118,
        -130,
        -139,
        -142,
        -140,
        -136,
        -127,
        -121,
        -116,
        -114,
        -116,
        -117,
        -113,
        -113,
        -107,
        -99,
        -91,
        -81,
        -80,
        -76,
        -66,
        -53,
        -40,
        -29,
        -25,
        -21,
        -17,
        -11,
        -9,
        -1,
        9,
        14,
        27,
        52,
        78,
        108,
        128,
        147,
        163,
        182,
        196,
        211,
        222,
        229,
        230,
        231,
        233,
        234,
        234,
        233,
        232,
        227,
        224,
        224,
        226,
        224,
        225,
        224,
        225,
        229,
        223,
        222,
        218,
        217,
        217,
        219,
        221,
        220,
        220,
        217,
        216,
        219,
        219,
        -858993460,
        -858993460
    ],
    "spineNaval_y": [
        0,
        0,
        -664,
        -663,
        -666,
        -662,
        -660,
        -661,
        -661,
        -660,
        -663,
        -667,
        -665,
        -664,
        -661,
        -663,
        -667,
        -669,
        -669,
        -670,
        -671,
        -669,
        -664,
        -663,
        -666,
        -666,
        -671,
        -673,
        -678,
        -676,
        -670,
        -667,
        -669,
        -673,
        -677,
        -682,
        -691,
        -689,
        -683,
        -682,
        -677,
        -678,
        -684,
        -694,
        -698,
        -703,
        -705,
        -704,
        -700,
        -692,
        -685,
        -680,
        -677,
        -677,
        -674,
        -671,
        -672,
        -679,
        -687,
        -692,
        -690,
        -689,
        -689,
        -689,
        -692,
        -696,
        -698,
        -704,
        -700,
        -700,
        -704,
        -708,
        -709,
        -711,
        -704,
        -700,
        -689,
        -683,
        -678,
        -673,
        -676,
        -682,
        -685,
        -684,
        -682,
        -673,
        -662,
        -658,
        -649,
        -642,
        -635,
        -633,
        -637,
        -641,
        -644,
        -646,
        -648,
        -649,
        -650,
        -649,
        -648,
        -647,
        -649,
        -649,
        -648,
        -649,
        -650,
        -650,
        -651,
        -653,
        -652,
        -651,
        -649,
        -648,
        -649,
        -650,
        -650,
        -652,
        -653,
        -653,
        -653,
        -654,
        -654,
        -858993460,
        -858993460
    ],
    "spineNaval_z": [
        0,
        0,
        2382,
        2385,
        2403,
        2426,
        2434,
        2438,
        2440,
        2435,
        2432,
        2427,
        2431,
        2429,
        2428,
        2426,
        2425,
        2427,
        2430,
        2429,
        2431,
        2430,
        2425,
        2422,
        2419,
        2413,
        2414,
        2373,
        2388,
        2399,
        2418,
        2368,
        2340,
        2321,
        2315,
        2309,
        2324,
        2344,
        2347,
        2356,
        2343,
        2336,
        2332,
        2323,
        2315,
        2312,
        2311,
        2313,
        2314,
        2310,
        2308,
        2311,
        2311,
        2310,
        2311,
        2310,
        2314,
        2311,
        2310,
        2312,
        2323,
        2337,
        2347,
        2347,
        2348,
        2343,
        2342,
        2339,
        2341,
        2343,
        2348,
        2347,
        2343,
        2335,
        2324,
        2311,
        2300,
        2302,
        2281,
        2287,
        2301,
        2311,
        2327,
        2345,
        2354,
        2359,
        2365,
        2368,
        2379,
        2385,
        2392,
        2397,
        2400,
        2405,
        2413,
        2421,
        2425,
        2425,
        2424,
        2422,
        2420,
        2419,
        2416,
        2413,
        2411,
        2414,
        2415,
        2417,
        2421,
        2422,
        2423,
        2423,
        2421,
        2421,
        2421,
        2418,
        2419,
        2419,
        2420,
        2422,
        2423,
        2422,
        2422,
        -858993460,
        -858993460
    ],
    "techniqueType": "c2",
    "timeStamp": 1563330158, 
    "notes": 'Default Technique'
}


