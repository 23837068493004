import React from 'react';
import { Chart } from '@bit/primefaces.primereact.chart';
import { getTechnique } from '../../data.js';
import { getCompTechnique } from '../../compData.js';

class PositionChart extends React.Component {



    constructor(props) {
        super(props)
        this.state = {
            data: [], options: [], view: 'leftFoot', charts: [], counter: 0, fixedHeight: false}
    }
  
    componentDidMount() {
     
        var self = this;
        let data = {
            labels: [],
            datasets: [
                {
                    label: 'First Dataset',
                    data: [],
                    fill: true,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)'
                    ],
                    borderWidth: 1
                },
            ]
        };
        let options = {
            title: {
              
            },

        };
        if (this.state.fixedHeight) {
          
        }

        let technique = getTechnique();
        let compTechnique = getCompTechnique();
        //Initialize with right foot if as a default. 
        if (this.state.counter === 0) { this.updateGraph('leftFootPos', technique.leftFootPos, compTechnique.leftFootPos); } 

        self.setState({ data, options});
    }

    handleFixedHeightChange = async (event) => {
        event.persist()
        let options = {};
        if (this.state.fixedHeight) {
            options = {title: {}}
            await this.setState({ options, fixedHeight: false });
        }
        else {
            options = {
                title: {},
                scales: {
                    yAxes: [{
                        ticks: {
                            min: 0,
                            max: 2000
                        }
                    }]
                }
            };
            await this.setState({ options, fixedHeight: true });
        }
       
        this.updateGraph(this.state.view, this.state.positions, this.state.compPositions );
    }

    async updateGraph(view, positions, compPositions) {
   
        var self = this;
 
            let data2 = {
                labels: [],
                datasets: [
                    {label: 'First Dataset',
                        data: [],
                        fill: true,
                        backgroundColor: ['rgba(255, 99, 132, 0.2)'],
                        borderColor: ['rgba(255, 99, 132, 1)'],
                        borderWidth: 1},
                    {label: 'Comparison Dataset',
                        data: [],
                        fill: true,
                        backgroundColor: ['rgba(128, 128, 128, 0.2)'],
                        borderColor: ['rgba(128, 128, 128, 1)'],
                        borderWidth: 1},
                ]
            };
            let labels = [];
            let heightData = [];
        let compHeightData = [];
        for (var k = 0; k < compPositions.length - 1; k++) {
            compHeightData[k] = compPositions[k].y
        }

        for (var j = 0; j < positions.length - 1; j++) {
                labels[j] = j;
                heightData[j] = positions[j].y
            }
       
    
        data2.labels = labels;
        data2.datasets[0].data = heightData; //positions
        data2.datasets[0].label = view;

        data2.datasets[1].data = compHeightData; //positions
        data2.datasets[1].label = 'Comparison';
    
        let charts = self.state.charts;
        let i = self.state.counter+1;
        if (charts.length !== 0) { charts.pop(); }
       
        charts.push(<div  style={{ width: 375, height: 375}}><Chart type='line' key={i} data={data2} options={this.state.options} width={350} height={350}/> </div>);
        self.setState({ charts: charts, counter: i, view: view, positions: positions, compPositions: compPositions});
    }

    


    handleChange = async (event) => {
      event.persist()
        let compTech = await getCompTechnique();
      
      let technique = getTechnique();
  
    switch (event.target.value) {
        case 'leftFootPos': this.updateGraph('leftFootPos', technique.leftFootPos, compTech.leftFootPos); break;
        case 'rightFootPos': this.updateGraph('rightFootPos', technique.rightFootPos, compTech.rightFootPos); break;
        case 'leftAnklePos': this.updateGraph('leftAnklePos', technique.leftAnklePos, compTech.leftAnklePos); break;
        case 'rightAnklePos': this.updateGraph('rightAnklePos', technique.rightAnklePos, compTech.rightAnklePos); break;
        case 'leftKneePos': this.updateGraph('leftKneePos', technique.leftKneePos, compTech.leftKneePos); break;
        case 'rightKneePos': this.updateGraph('rightKneePos', technique.rightKneePos, compTech.rightKneePos); break;
        case 'leftHipPos': this.updateGraph('leftHipPos', technique.leftHipPos, compTech.leftHipPos); break;
        case 'rightHipPos': this.updateGraph('rightHipPos', technique.rightHipPos, compTech.rightHipPos); break;
        case 'leftShoulderPos': this.updateGraph('leftShoulderPos', technique.leftShoulderPos, compTech.leftShoulderPos); break;
        case 'rightShoulderPos': this.updateGraph('rightShoulderPos', technique.rightShoulderPos, compTech.rightShoulderPos); break;
        case 'leftElbowPos': this.updateGraph('leftElbowPos', technique.leftElbowPos, compTech.leftElbowPos); break;
        case 'rightElbowPos': this.updateGraph('rightElbowPos', technique.rightElbowPos, compTech.rightElbowPos); break;
        case 'leftWristPos': this.updateGraph('leftWristPos', technique.leftWristPos, compTech.leftWristPos); break;
        case 'rightWristPos': this.updateGraph('rightWristPos', technique.rightWristPos, compTech.rightWristPos); break;
        case 'PelvisPos': this.updateGraph('PelvisPos', technique.PelvisPos, compTech.PelvisPos); break;
        case 'headPos': this.updateGraph('headPos', technique.headPos, compTech.headPos); break;
        default: this.updateGraph('leftFootPos', technique.leftFootPos, compTech.leftFootPos); break;
    }
}

    render() {
     
        return (
            <div className='ui grid'>
                <div className='eight wide centered column'>
             
                    <h3 style={{ color: 'rgba(255, 99, 132, 1)' }}>Height [mm]</h3>
               
                </div>
                <div className='row'>
                <div className='eight wide column'>
                <label>
                    <input style={{ cursor: 'pointer' }} type="checkbox"
                        checked={this.state.fixedHeight}
                        onChange={this.handleFixedHeightChange}
                    />
                    <span style={{ 'color': 'white' }}>Fix Height Axis</span>
                    </label>
                    </div>
                <div className="five wide column">
                <select style={{ cursor: 'pointer' }} onChange={this.handleChange} >
                        <option key={1} value={'leftFootPos'}> Left Foot </option>
                        <option key={2} value={'rightFootPos'}> Right Foot </option>
                        <option key={3} value={'leftAnklePos'}> Left Ankle </option>
                        <option key={4} value={'rightAnklePos'}> Right Ankle </option>
                        <option key={5} value={'leftKneePos'}> Left Knee </option>
                        <option key={6} value={'rightKneePos'}> Right Knee </option>
                        <option key={7} value={'leftHipPos'}> Left Hip </option>
                        <option key={8} value={'rightHipPos'}> Right Hip </option>
                        <option key={9} value={'leftShoulderPos'}> Left Shoulder </option>
                        <option key={10} value={'rightShoulderPos'}> Right Shoulder </option>
                        <option key={11} value={'leftElbowPos'}> Left Elbow </option>
                        <option key={12} value={'rightElbowPos'}> Right Elbow </option>
                        <option key={13} value={'leftWristPos'}> Left Wrist </option>
                        <option key={14} value={'rightWristPos'}> Right Wrist </option>
                        <option key={15} value={'Pelvis'}> Pelvis </option>
                        <option key={16} value={'Head'}> Head </option>
                    </select>
                    </div>
                    </div>
              
       
                {this.state.charts[0]}
         
            </div>
              
               
        );
}

}




export default PositionChart;

