import React from 'react';
import { getTechnique } from '../data.js';
import { getNameForId } from '../userInfo.js';
import { getCompTechnique } from '../compData.js';
import { getHRTechType } from '../techTypes.js';


class InfoCard extends React.Component {


    constructor(props) {
        super(props)
        this.state = { isComparison: props.isComparison, loaded: false }
    }

    componentDidMount = async () => {
        let technique, owner
        if (!this.state.isComparison) {
            technique = getTechnique();
        }
        else {
            technique = getCompTechnique();
        }
        owner = await getNameForId(technique.ownerId);
        let hrType = getHRTechType(technique.techniqueType);
        this.setState({ technique, owner, hrType, loaded: true })
    }

    render() {
   
        if (!this.state.loaded) {
            return <div> loading... </div>
        }
        else
            return (
                <div className="ui raised centered link blue card" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }} >
                    <div className="content">
                        <div className="blue centered header" style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>Technique Info</div>
                    </div>
                    <div className="content">
                        <div className="ui small feed">
                            <div className="event">
                                <div className="content">
                                    <div className="summary">
                                        <p style={{ color: 'rgba(64, 160, 255, 1)' }}>Type: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> {this.state.hrType} </span> </p>
                                        <p style={{ color: 'rgba(64, 160, 255, 1)' }}>Score: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> {this.state.isComparison} </span> </p>
                                        <p style={{ color: 'rgba(64, 160, 255, 1)' }}>Max Speed: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> {this.state.technique.maxFootSpeed / 1000} m/s </span> </p>
                                        <p style={{ color: 'rgba(64, 160, 255, 1)' }}>Max Foot Height: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> {this.state.technique.maxFootHeight / 1000} m </span> </p>
                                        <p style={{ color: 'rgba(64, 160, 255, 1)' }}>Calculated Foot: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> {this.state.technique.calculatedFoot} </span> </p>
                                        <p style={{ color: 'rgba(64, 160, 255, 1)' }}>User: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> {this.state.owner} </span> </p>
                                        <p style={{ color: 'rgba(64, 160, 255, 1)' }}>Privacy: <span style={{ color: 'rgba(255, 0, 0, 1)' }}> {this.state.technique.privacy} </span> </p>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>

            );
    }

}


 


export default InfoCard;



