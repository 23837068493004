import React from 'react'
import Files from 'react-files'
import { uploadImage } from '../../azureBlob.js';


class ImageUpload extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            fileNum: 0,
            imageType: props.imageType,
            image: '', 
            setUploadStatus: props.setUploadStatus, 
            status: 1
        }
    }

    updateImage = (image) => {
        this.setState({ image: image });
    }

    onFilesChange = (files) => {
        var self = this;
        var read = new FileReader();

        var file = read.readAsDataURL(files[this.state.fileNum]);

        read.onloadend = function (file) {
            var fileContent = file.target.result;
            uploadImage(fileContent, self.state.imageType) 
           
        }
        this.setState({ fileNum: this.state.fileNum + 1, status: 3});
    }

    onFilesError = (error, file) => {
        console.log('error code ' + error.code + ': ' + error.message)
    }

    render() {
        return (
            <div className="files">
                {this.state.status === 1 && <Files
                    className='files-dropzone'
                    onChange={this.onFilesChange}
                    onError={this.onFilesError}
                    accepts={['image/*']}
                    multiple
                    maxFiles={10}
                    maxFileSize={3000000}
                    minFileSize={0}
                    clickable
                >
                    <button className="ui inverted blue button">Upload Image</button>

                </Files>}
                {this.state.status === 3 && <button className="ui inverted gray button">Upload Successful!</button>}
                {this.state.image !== '' && <img src={this.state.image} alt='uploaded'/>}
            </div>
        )
    }

}

export default ImageUpload;