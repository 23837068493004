let token = {};


//function to expose the current session token. 
export function getToken() {
    return token;
}

//function to set the current session token. 
export function setToken(newToken) {
    token = newToken;
  
}