import NavBar from "./NavBar";
import React from 'react';

function LoginCard(props) {


    return (
        <div className="ui raised blue card" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }} >
            <div className="content">
                <div className="blue centered header" style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>Account Actions</div>
            </div>
            <div className="content">
                <div className="ui small feed">
                    <div className="event">
                        <div className="content">
                            <div className="summary">
                                <NavBar />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}







export default LoginCard;




