import React from 'react';
import { Chart } from '@bit/primefaces.primereact.chart';
import { getTechnique } from '../../data.js';
import { getCompTechnique } from '../../compData.js';

class AccelerationChart extends React.Component {




    constructor(props) {
        super(props)
        this.state = {
            data: [], options: [], view: 'leftFoot', charts: [], counter: 0, comparison: props.comparison, Acc3d: false
        }
    }

    componentDidMount() {

        var self = this;
        let data = {
            labels: [],
            datasets: [
                {
                    label: 'First Dataset',
                    data: [],
                    fill: true,
                    backgroundColor: [
                        'rgba(231, 76, 50, 0.2)'
                    ],
                    borderColor: [
                        'rgba(231, 76, 50, 1)'
                    ],
                    borderWidth: 1
                },
            ]
        };
        let options = {
            title: {

            },

        };

        if (this.state.fixedHeight) {

        }

        let technique = getTechnique();
        let compTechnique = getCompTechnique();
        //Initialize with right foot if as a default. 
        if (this.state.counter === 0) { this.updateGraph('leftFootAcc', technique.leftFootAcc, compTechnique.leftFootAcc); }

        self.setState({ data, options });
    }

    handleFixedHeightChange = async (event) => {
        event.persist()
        let options = {};
        if (this.state.fixedHeight) {
            options = { title: {} }
            this.setState({ options, fixedHeight: false });
        }
        else {
            options = {
                title: {},
                scales: {
                    yAxes: [{
                        ticks: {
                            min: 0,
                            max: 2000
                        }
                    }]
                }
            };
            this.setState({ options, fixedHeight: true });
        }

        this.updateGraph(this.state.view, this.state.positions, this.state.compPositions);
    }

    async updateGraph(view, positions, compPositions) {

        var self = this;

        let data2 = {
            labels: [],
            datasets: [
                {
                    label: 'First Dataset',
                    data: [],
                    fill: true,
                    backgroundColor: ['rgba(231, 76, 50, 0.2)'],
                    borderColor: ['rgba(231, 76, 50, 1)'],
                    borderWidth: 1
                },
                {
                    label: 'Comparison Dataset',
                    data: [],
                    fill: true,
                    backgroundColor: ['rgba(128, 128, 128, 0.2)'],
                    borderColor: ['rgba(128, 128, 128, 1)'],
                    borderWidth: 1
                },
            ]
        };
        let labels = [];
        let heightData = [];
        let compHeightData = [];
        for (var k = 0; k < compPositions.length - 1; k++) {
            compHeightData[k] = compPositions[k]
        }

        for (var j = 0; j < positions.length - 1; j++) {
            labels[j] = j;
            heightData[j] = positions[j]
        }


        data2.labels = labels;
        data2.datasets[0].data = heightData; //positions
        data2.datasets[0].label = view;

        data2.datasets[1].data = compHeightData; //positions
        data2.datasets[1].label = 'Comparison';

        let charts = self.state.charts;
        let i = self.state.counter + 1;
        if (charts.length !== 0) { charts.pop(); }
        charts.push(<div  style={{ width: 375, height: 375 }}> <Chart type='line' key={i} data={data2} options={this.state.options} height={350} width={350} /> </div>);
        self.setState({ charts: charts, counter: i, view: view, positions: positions, compPositions: compPositions });
    }


    handleAcc3dChange = () => {

    }

    handleChange = async (event) => {
        event.persist()
        let compTech = await getCompTechnique();
      
        let technique = getTechnique();

        switch (event.target.value) {
            case 'leftFootAcc': this.updateGraph('leftFootAcc', technique.leftFootAcc, compTech.leftFootAcc); break;
            case 'rightFootAcc': this.updateGraph('rightFootAcc', technique.rightFootAcc, compTech.rightFootAcc); break;
            case 'leftAnkleAcc': this.updateGraph('leftAnkleAcc', technique.leftAnkleAcc, compTech.leftAnkleAcc); break;
            case 'rightAnkleAcc': this.updateGraph('rightAnkleAcc', technique.rightAnkleAcc, compTech.rightAnkleAcc); break;
            case 'leftKneeAcc': this.updateGraph('leftKneeAcc', technique.leftKneeAcc, compTech.leftKneeAcc); break;
            case 'rightKneeAcc': this.updateGraph('rightKneeAcc', technique.rightKneeAcc, compTech.rightKneeAcc); break;
            case 'leftHipAcc': this.updateGraph('leftHipAcc', technique.leftHipAcc, compTech.leftHipAcc); break;
            case 'rightHipAcc': this.updateGraph('rightHipAcc', technique.rightHipAcc, compTech.rightHipAcc); break;
            case 'leftShoulderAcc': this.updateGraph('leftShoulderAcc', technique.leftShoulderAcc, compTech.leftShoulderAcc); break;
            case 'rightShoulderAcc': this.updateGraph('rightShoulderAcc', technique.rightShoulderAcc, compTech.rightShoulderAcc); break;
            case 'leftElbowAcc': this.updateGraph('leftElbowAcc', technique.leftElbowAcc, compTech.leftElbowAcc); break;
            case 'rightElbowAcc': this.updateGraph('rightElbowAcc', technique.rightElbowAcc, compTech.rightElbowAcc); break;
            case 'leftWristAcc': this.updateGraph('leftWristAcc', technique.leftWristAcc, compTech.leftWristAcc); break;
            case 'rightWristAcc': this.updateGraph('rightWristAcc', technique.rightWristAcc, compTech.rightWristAcc); break;
            case 'PelvisAcc': this.updateGraph('PelvisAcc', technique.PelvisAcc, compTech.PelvisAcc); break;
            case 'headAcc': this.updateGraph('headAcc', technique.headAcc, compTech.headAcc); break;
            default: this.updateGraph('leftFootAcc', technique.leftFootAcc, compTech.leftFootAcc); break;
        }
    }

    render() {
      

        return (
            <div className='ui grid'>
                <div className='ten wide centered column'>

                    <h3 style={{ color: 'rgba(231, 76, 50, 1)' }}>Acceleration [mm/s^2]</h3>
                </div>
                <div className='row'>
                    <div className='eight wide column'>
                    <label>
                            <input style={{ cursor: 'pointer' }} type="checkbox"
                                checked={this.state.Acc3d}
                                onChange={this.handleAcc3dChange}
                        />
                            <span style={{ 'color': 'white' }}>3D Acc (pending)</span>
                    </label>
                </div>

                <div className="five wide column">
                        <select style={{ cursor: 'pointer' }} onChange={this.handleChange} >
                        <option key={1} value={'leftFootAcc'}> Left Foot </option>
                        <option key={2} value={'rightFootAcc'}> Right Foot </option>
                        <option key={3} value={'leftAnkleAcc'}> Left Ankle </option>
                        <option key={4} value={'rightAnkleAcc'}> Right Ankle </option>
                        <option key={5} value={'leftKneeAcc'}> Left Knee </option>
                        <option key={6} value={'rightKneeAcc'}> Right Knee </option>
                        <option key={7} value={'leftHipAcc'}> Left Hip </option>
                        <option key={8} value={'rightHipAcc'}> Right Hip </option>
                        <option key={9} value={'leftShoulderAcc'}> Left Shoulder </option>
                        <option key={10} value={'rightShoulderAcc'}> Right Shoulder </option>
                        <option key={11} value={'leftElbowAcc'}> Left Elbow </option>
                        <option key={12} value={'rightElbowAcc'}> Right Elbow </option>
                        <option key={13} value={'leftWristAcc'}> Left Wrist </option>
                        <option key={14} value={'rightWristAcc'}> Right Wrist </option>
                        <option key={15} value={'Pelvis'}> Pelvis </option>
                        <option key={16} value={'Head'}> Head </option>
                    </select>
                </div>
                </div>

                {this.state.charts[0]}

            </div>


        );
    }

}










export default AccelerationChart;

/*
 *    
                    */