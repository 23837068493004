import React from 'react';
import TechComponent from './TechComponent';
import HeaderSection from './HeaderSection';
import MinMaxer from './MinMaxer';
import { addCustomTechniqueToDb } from '../customTech';



class TechniqueAdder extends React.Component {



    //      <input type="submit" value="Submit" / >
    constructor(props) {
        super(props)
        let nullMinMax = {footHeight: false, footSpeed: false, handSpeed: false, handHeight: false, kneeExtensionAngle: false, kneeExtensionSpeed: false, elbowExtensionAngle: false, elbowExtensionSpeed: false, shoulderRotationSpeed: false, hipRotationSpeed: false}
        this.state = {components: [], componentAdders: [], componentRows: [], techId: 0, maximizers: nullMinMax, minimizers: nullMinMax, name: '', description: '', setView: props.setView}
    }

    componentDidMount() {
        let componentAdders = []
        componentAdders.push(<TechComponent key={0} techComponentSubmit={this.techComponentSubmit}/>);
        this.setState({ componentAdders });
        console.log(componentAdders);
    }
 

    techComponentSubmit = (techComponent) => {

        console.log(techComponent);
    
        let components = this.state.components;
        components.push(techComponent)
        let componentRows = this.state.componentRows;
        componentRows.push(<tr key={componentRows.length}><td>{techComponent.firstJoint} -- {techComponent.relationship} -- {techComponent.secondJoint} -- {techComponent.time}</td></tr> )
        this.setState({ components });
        let componentAdders = this.state.componentAdders;
        componentAdders.push(<TechComponent key={componentAdders.length} techComponentSubmit={this.techComponentSubmit} />);
        this.setState({ componentAdders, componentRows });

    }

    handleNameChange = (event) => {
        this.setState({ name: event.target.value });
    }

    handleDescriptionChange = (event) => {
        this.setState({ description: event.target.value });
    }


    maxSubmit = (maximizers) => {
        this.setState({ maximizers});
        console.log(this.state)
    }

    minSubmit = (minimizers) => {
        this.setState({minimizers});
        console.log(this.state)
    }


    handleSubmit = async (event) => {

     
        event.preventDefault();
        let customTechnique = {
            name: this.state.name,
            description: this.state.description,
            max: this.state.maximizers,
            min: this.state.minimizers,
            attributes: this.state.components
        }
       
        let techId = await addCustomTechniqueToDb(customTechnique)
        this.setState({ techId });
    }
 
    render() {
   
        return (
            <div className="ui relaxed stackable grid">
                <HeaderSection title="Define Custom Technique" color='blue' />
                <div className ='sixteen wide column'>
                {this.state.techId === 0 && <div className="ui segment">

             
                    <p>Define a new custom technique for My-ai-coach. You will be the owner of the record. New techniques should only be used if you cannot find a suitable already defined technique.</p>
                    <table className="ui blue striped table">
                        <thead>
                            <tr>
                                <th scope="col" >Technical Component</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.componentRows}
                        </tbody>
                    </table>
                    {this.state.componentAdders}
                    <div className="ui section divider"></div>
                    <MinMaxer max={true} submit={this.maxSubmit} />
                    <MinMaxer min={true} submit={this.maxSubmit} />
                <div className ='ui divider' />
                    <div className='ui grid'>     
                            <div className='fourteen wide column'>
                                <div className="ui fluid labeled input"><div className="ui label">Technique Name: </div><input type="text" placeholder="Enter a short technique name" value={this.state.name} onChange={this.handleNameChange} /></div> 
                        </div>
                        <div className='fourteen wide column'>
                                <div className="ui fluid labeled input"><div className="ui label">Description: </div><input className='ui input' type="text" placeholder="Briefly describe the technique"  value={this.state.description} onChange={this.handleDescriptionChange} /></div> 
                        </div>      
                            <div className='fourteen wide centered column'>
                                <button className="ui inverted tiny red button" onClick={() => this.state.setView('publicTechniques')} >Cancel</button>
                                <button className="ui inverted tiny green button" onClick={this.handleSubmit} >Submit</button>
                            </div>
                    
                        </div>
                    </div>

                    }
                    
                    {this.state.techId !== 0 && <div className='ui segment'> <p> Congrats! You have successfully defined custom technique {this.state.techId} for technique: {this.state.name}</p> </div>}
                    </div>
            </div>
        );
    }


}
export default TechniqueAdder;
