import React from 'react';


class TechComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {firstJoint: 'First Joint', secondJoint: 'Second Joint', relationship: 'relationship', time: 'at time', finalized: false}
    }

    submit = () => {
        let techComponent = { firstJoint: this.state.firstJoint, relationship: this.state.relationship, secondJoint: this.state.secondJoint, time: this.state.time }
        this.setState({finalized: true})
        this.props.techComponentSubmit(techComponent);
    }

    handleFirstJointChange = (event) => {
        this.setState({ firstJoint: event.target.value });
    }

    handleRelationshipChange = (event) => {
        this.setState({ relationship: event.target.value });
    }

    handleSecondJointChange = (event) => {
        this.setState({ secondJoint: event.target.value });
    }

    handleFirstJointChange = (event) => {
        this.setState({ firstJoint: event.target.value });
    }

    render() {
        return (

            <div className="ui compact menu">
    

                <select onChange={this.handleFirstJointChange} >
                    <option key={1} value={'leftFootPos'}> Left Foot </option>
                    <option key={2} value={'rightFootPos'}> Right Foot </option>
                    <option key={3} value={'leftAnklePos'}> Left Foot </option>
                    <option key={4} value={'rightAnklePos'}> Right Ankle </option>
                    <option key={5} value={'leftKneePos'}> Left Knee </option>
                    <option key={6} value={'rightKneePos'}> Right Knee </option>
                    <option key={7} value={'leftHipPos'}> Left Hip </option>
                    <option key={8} value={'rightHipPos'}> Right Hip </option>
                    <option key={9} value={'leftShoulderPos'}> Left Shoulder </option>
                    <option key={10} value={'rightShoulderPos'}> Right Shoulder </option>
                    <option key={11} value={'leftElbowPos'}> Left Elbow </option>
                    <option key={12} value={'rightElbowPos'}> Right Elbow </option>
                    <option key={13} value={'leftWristPos'}> Left Wrist </option>
                    <option key={14} value={'rightWristPos'}> Right Wrist </option>
                    <option key={15} value={'Pelvis'}> Pelvis </option>
                    <option key={16} value={'Head'}> Head </option>
                </select>
          

                <select onChange={this.handleRelationshipChange} >
                    <option key={1} value={'Directly Above [y]'}> Directly Above [y] </option>
                    <option key={2} value={'Height Directly Below [y]'}> Height Directly Below [y] </option>
                    <option key={3} value={'Higher Than [y]'}> Higher Than [y] </option>
                    <option key={4} value={'Lower Than [y]'}> Lower Than [y] </option>

                </select>

                <select onChange={this.handleSecondJointChange} >
                    <option key={1} value={'leftFootPos'}> Left Foot </option>
                    <option key={2} value={'rightFootPos'}> Right Foot </option>
                    <option key={3} value={'leftAnklePos'}> Left Foot </option>
                    <option key={4} value={'rightAnklePos'}> Right Ankle </option>
                    <option key={5} value={'leftKneePos'}> Left Knee </option>
                    <option key={6} value={'rightKneePos'}> Right Knee </option>
                    <option key={7} value={'leftHipPos'}> Left Hip </option>
                    <option key={8} value={'rightHipPos'}> Right Hip </option>
                    <option key={9} value={'leftShoulderPos'}> Left Shoulder </option>
                    <option key={10} value={'rightShoulderPos'}> Right Shoulder </option>
                    <option key={11} value={'leftElbowPos'}> Left Elbow </option>
                    <option key={12} value={'rightElbowPos'}> Right Elbow </option>
                    <option key={13} value={'leftWristPos'}> Left Wrist </option>
                    <option key={14} value={'rightWristPos'}> Right Wrist </option>
                    <option key={15} value={'Pelvis'}> Pelvis </option>
                    <option key={16} value={'Head'}> Head </option>
                </select>

                <select onChange={this.handleTimeChange} >
                    <option key={1} value={'Always'}> Always </option>
                    <option key={2} value={'Never'}> Never </option>
                    <option key={3} value={'At greatest right knee extension'}> At greatest right knee extension </option>
                    <option key={4} value={'At greatest left knee extension'}> At greatest left knee extension </option>
                    <option key={5} value={'At greatest right elbow extension'}> At greatest right elbow extension</option>
                    <option key={6} value={'At greatest left elbow extension'}> At greatest left elbow extension </option>
                    <option key={7} value={'At greatest right foot position'}> At greatest right foot position </option>
                    <option key={8} value={'At greatest left foot position'}> At greatest left foot position </option>
                </select>
          
                     <button className='green inverted ui button' onClick={this.submit}> Add </button >
           
               
                </div>
            
  
        );
    }
}

export default TechComponent;










