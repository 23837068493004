import React from 'react';
import { getImage } from '../azureBlob';


class ProfilePicture extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            image: ''
        }
    }
    componentDidMount() {
        this.loadImage()
    }
    
    loadImage = async () => {
        let image = await getImage('user');   
      
        this.setState({ image: image });
    }
    
    
render() {
    
    return (
        <div>
            <img className='small ui image' src={this.state.image} alt='profile pic' />
        </div>

    );
}

}





export default ProfilePicture;



