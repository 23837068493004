import React from 'react';
import { Chart } from '@bit/primefaces.primereact.chart';
import { getTechnique } from '../../data.js';
import { getCompTechnique } from '../../compData.js';
import { fill3DSpeedArray } from '../../ma-motion.js';

class SpeedChart extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            data: [], options: [], view: 'leftFoot', charts: [], counter: 0, comparison: props.comparison, threeDView: false
        }
    }

    componentDidMount() {

        var self = this;
        let data = {
            labels: [],
            datasets: [
                {
                    label: 'First Dataset',
                    data: [],
                    fill: true,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)'
                    ],
                    borderWidth: 1
                },
            ]
        };
        let options = {
            title: {

            },

        };

        if (this.state.fixedHeight) {

        }

        let technique = getTechnique();
        let compTechnique = getCompTechnique();
        //Initialize with right foot if as a default. 
        if (this.state.counter === 0) { this.updateGraph('leftFootSpeed', technique.leftFootSpeed, compTechnique.leftFootSpeed); }

        self.setState({ data, options });
    }

    handle3DChangeChange = async (event) => {
        event.persist()
     
        if (this.state.threeDView) {
            this.setState({ threeDView: false });
        }
        else {
            this.setState({ threeDView: true });
        }

        this.updateGraph(this.state.view, this.state.positions, this.state.compPositions);
    }

    async updateGraph(view, positions, compPositions) {

        var self = this;

        let data2 = {
            labels: [],
            datasets: [
                {
                    label: 'First Dataset',
                    data: [],
                    fill: true,
                    backgroundColor: ['rgba(35, 132, 198, 0.2)'],
                    borderColor: ['rgba(64, 160, 255, 1)'],
                    borderWidth: 1
                },
                {
                    label: 'Comparison Dataset',
                    data: [],
                    fill: true,
                    backgroundColor: ['rgba(128, 128, 128, 0.2)'],
                    borderColor: ['rgba(128, 128, 128, 1)'],
                    borderWidth: 1
                },
            ]
        };
        let labels = [];
        let heightData = [];
        let compHeightData = [];

        for (var k = 0; k < compPositions.length - 1; k++) {
            compHeightData[k] = compPositions[k]
        }

        for (var j = 0; j < positions.length - 1; j++) {
            labels[j] = j;
            heightData[j] = positions[j]
        }

        // If we are looking for 3D data, we need to caluclate the 3d speed
        /*
        if (this.state.threeDView) {
            switch (view) {
                case 'leftFootSpeed':
                    positions = fill3DSpeedArray(technique.leftFootPos)
                    compPositions = fill3DSpeedArray(compTech.leftFootPos)
                        ; break;
                case 'rightFootSpeed': this.updateGraph('rightFootSpeed', technique.rightFootSpeed, compTech.rightFootSpeed); break;
                case 'leftAnkleSpeed': this.updateGraph('leftAnkleSpeed', technique.leftAnkleSpeed, compTech.leftAnkleSpeed); break;
                case 'rightAnkleSpeed': this.updateGraph('rightAnkleSpeed', technique.rightAnkleSpeed, compTech.rightAnkleSpeed); break;
                case 'leftKneeSpeed': this.updateGraph('leftKneeSpeed', technique.leftKneeSpeed, compTech.leftKneeSpeed); break;
                case 'rightKneeSpeed': this.updateGraph('rightKneeSpeed', technique.rightKneeSpeed, compTech.rightKneeSpeed); break;
                case 'leftHipSpeed': this.updateGraph('leftHipSpeed', technique.leftHipSpeed, compTech.leftHipSpeed); break;
                case 'rightHipSpeed': this.updateGraph('rightHipSpeed', technique.rightHipSpeed, compTech.rightHipSpeed); break;
                case 'leftShoulderSpeed': this.updateGraph('leftShoulderSpeed', technique.leftShoulderSpeed, compTech.leftShoulderSpeed); break;
                case 'rightShoulderSpeed': this.updateGraph('rightShoulderSpeed', technique.rightShoulderSpeed, compTech.rightShoulderSpeed); break;
                case 'leftElbowSpeed': this.updateGraph('leftElbowSpeed', technique.leftElbowSpeed, compTech.leftElbowSpeed); break;
                case 'rightElbowSpeed': this.updateGraph('rightElbowSpeed', technique.rightElbowSpeed, compTech.rightElbowSpeed); break;
                case 'leftWristSpeed': this.updateGraph('leftWristSpeed', technique.leftWristSpeed, compTech.leftWristSpeed); break;
                case 'rightWristSpeed': this.updateGraph('rightWristSpeed', technique.rightWristSpeed, compTech.rightWristSpeed); break;
                case 'PelvisSpeed': this.updateGraph('PelvisSpeed', technique.PelvisSpeed, compTech.PelvisSpeed); break;
                case 'headSpeed': this.updateGraph('headSpeed', technique.headSpeed, compTech.headSpeed); break;
            }
        }
        */
        data2.labels = labels;
        data2.datasets[0].data = heightData; //positions
        data2.datasets[0].label = view;

        data2.datasets[1].data = compHeightData; //positions
        data2.datasets[1].label = 'Comparison';

        let charts = self.state.charts;
        let i = self.state.counter + 1;
        if (charts.length !== 0) { charts.pop(); }
        charts.push(<div style={{ width: 375, height: 375 }}> <Chart type='line' key={i} data={data2} options={this.state.options} height={350} width={350} /> </div>);
        self.setState({ charts: charts, counter: i, view: view, positions: positions, compPositions: compPositions });
    }


    handleChange = async (event) => {
        event.persist()
        let compTech = await getCompTechnique();
        let technique = getTechnique();

        switch (event.target.value) {
            case 'leftFootSpeed': this.updateGraph('leftFootSpeed', technique.leftFootSpeed, compTech.leftFootSpeed); break;
            case 'rightFootSpeed': this.updateGraph('rightFootSpeed', technique.rightFootSpeed, compTech.rightFootSpeed); break;
            case 'leftAnkleSpeed': this.updateGraph('leftAnkleSpeed', technique.leftAnkleSpeed, compTech.leftAnkleSpeed); break;
            case 'rightAnkleSpeed': this.updateGraph('rightAnkleSpeed', technique.rightAnkleSpeed, compTech.rightAnkleSpeed); break;
            case 'leftKneeSpeed': this.updateGraph('leftKneeSpeed', technique.leftKneeSpeed, compTech.leftKneeSpeed); break;
            case 'rightKneeSpeed': this.updateGraph('rightKneeSpeed', technique.rightKneeSpeed, compTech.rightKneeSpeed); break;
            case 'leftHipSpeed': this.updateGraph('leftHipSpeed', technique.leftHipSpeed, compTech.leftHipSpeed); break;
            case 'rightHipSpeed': this.updateGraph('rightHipSpeed', technique.rightHipSpeed, compTech.rightHipSpeed); break;
            case 'leftShoulderSpeed': this.updateGraph('leftShoulderSpeed', technique.leftShoulderSpeed, compTech.leftShoulderSpeed); break;
            case 'rightShoulderSpeed': this.updateGraph('rightShoulderSpeed', technique.rightShoulderSpeed, compTech.rightShoulderSpeed); break;
            case 'leftElbowSpeed': this.updateGraph('leftElbowSpeed', technique.leftElbowSpeed, compTech.leftElbowSpeed); break;
            case 'rightElbowSpeed': this.updateGraph('rightElbowSpeed', technique.rightElbowSpeed, compTech.rightElbowSpeed); break;
            case 'leftWristSpeed': this.updateGraph('leftWristSpeed', technique.leftWristSpeed, compTech.leftWristSpeed); break;
            case 'rightWristSpeed': this.updateGraph('rightWristSpeed', technique.rightWristSpeed, compTech.rightWristSpeed); break;
            case 'PelvisSpeed': this.updateGraph('PelvisSpeed', technique.PelvisSpeed, compTech.PelvisSpeed); break;
            case 'headSpeed': this.updateGraph('headSpeed', technique.headSpeed, compTech.headSpeed); break;
            default: this.updateGraph('leftFootSpeed', technique.leftFootSpeed, compTech.leftFootSpeed); break;
        }
    }

    render() {
       

        return (
            <div className='ui grid'>
                <div className='twelve wide centered column'>

                    <h3 style={{ color: 'rgba(64, 160, 255, 1)' }}>Y Axis Speed [mm/s]</h3>
                   
                </div>
                <div className='row'>
                    <div className='eight wide column'>
                        <label>
                            <input style={{ cursor: 'pointer' }} type="checkbox"
                                checked={this.state.fixedHeight}

                            />
                            <span style={{ 'color': 'white' }}>3D Velocity (pending)</span>
                        </label>
                        </div>
                <div className="five wide column">
                  <select style={{ cursor: 'pointer' }} onChange={this.handleChange} >
                        <option key={1} value={'leftFootSpeed'}> Left Foot </option>
                        <option key={2} value={'rightFootSpeed'}> Right Foot </option>
                        <option key={3} value={'leftAnkleSpeed'}> Left Ankle </option>
                        <option key={4} value={'rightAnkleSpeed'}> Right Ankle </option>
                        <option key={5} value={'leftKneeSpeed'}> Left Knee </option>
                        <option key={6} value={'rightKneeSpeed'}> Right Knee </option>
                        <option key={7} value={'leftHipSpeed'}> Left Hip </option>
                        <option key={8} value={'rightHipSpeed'}> Right Hip </option>
                        <option key={9} value={'leftShoulderSpeed'}> Left Shoulder </option>
                        <option key={10} value={'rightShoulderSpeed'}> Right Shoulder </option>
                        <option key={11} value={'leftElbowSpeed'}> Left Elbow </option>
                        <option key={12} value={'rightElbowSpeed'}> Right Elbow </option>
                        <option key={13} value={'leftWristSpeed'}> Left Wrist </option>
                        <option key={14} value={'rightWristSpeed'}> Right Wrist </option>
                        <option key={15} value={'Pelvis'}> Pelvis </option>
                        <option key={16} value={'Head'}> Head </option>
                    </select>
                </div>
</div>

                {this.state.charts[0]}

            </div>


        );
    }

}

 


export default SpeedChart;

