import React from 'react';
import { getCurrentUser } from '../userInfo';
import { addGroup } from '../groupInfo';

class GroupAdder extends React.Component {

    constructor(props) {
        super(props)
        this.state = { unlisted: false, done: false, newId: 1, name: '', website: '', description: '', location: '' }
    }


    handleNameChange = (event) => {
        this.setState({ name: event.target.value });
    }

    handleLocationChange = (event) => {
        this.setState({ location: event.target.value });
    }

    handleDescriptionChange = (event) => {
        this.setState({ description: event.target.value });
    }

    handleWebsiteChange = (event) => {
        this.setState({ website: event.target.value });
    }

    handleUnlistedChange = (event) => {
        if (this.state.unlisted) { this.setState({ unlisted: false }); }
        else { this.setState({ unlisted: true }); }
        console.log(this.state.unlisted);

    }


    handleSubmit = async (event) => {

        if (this.state.name === '') { await this.setState({name: 'No name'})}
        if (this.state.website === '') { await this.setState({website: 'None'}) }
        if (this.state.description === '') { await this.setState({description: 'None' }) }
        if (this.state.location === '') { await this.setState({ location: 'None' }) }

        //event.preventDefault();
        let currentUser = getCurrentUser();
        let group = {
            name: this.state.name,
            website: this.state.website,
            description: this.state.description,
            location: this.state.location,
            unlisted: this.state.unlisted,
            logo: 'none',
            owner: currentUser.userId
        }
        let newId = await addGroup(group);
        this.setState({ newId, done: true });

    }

    render() {

        return (
            <div className="ui grid">

                {this.state.done === true &&
                    <div className="ui inverted segment"> <p> Thanks for adding a new group! Remember, all members will be able to see techniques of other members that are marked public - leaderboard, but not public - unlisted or private. Make sure to add https:// in front of your link</p>
                    <p> You can find your new group under <span style={{ 'color': 'rgba(64, 160, 255, 1)'}}> id: {this.state.newId} </span> </p>

                        <div className='ui divider' />

                        <button className="ui inverted red button" onClick={this.props.closeAdder}>Close</button>
                    </div>}
                {this.state.done === false &&
                    <div className="ui inverted segment"><p>Add a new group to My-Ai-Coach. Group members will be able to see each other's techniques that are public or unlisted, but not private. </p>
                        <div className="ui labeled fluid input"><div className="ui label">Group Name: </div><input type="text" value={this.state.name} onChange={this.handleNameChange} /></div>
                        <div className='ui divider' />
                        <div className="ui labeled fluid  input"><div className="ui label">Short Description: </div><input type="text" value={this.state.description} onChange={this.handleDescriptionChange} /></div>
                        <div className='ui divider' />
                        <div className="ui labeled fluid input"><div className="ui label">Website/Facebook: </div><input type="text" value={this.state.website} onChange={this.handleWebsiteChange} /></div>
                        <div className='ui divider' />
                        <div className="ui labeled fluid input"><div className="ui label">Location: </div><input type="text" value={this.state.location} onChange={this.handleLocationChange} /></div>
                        <div className='ui divider' />
                        <p>  Unlisted groups can only be joined my someone who knows their id. </p>
                        <div className="ui label">Unlisted? </div> <input type="checkbox" value={this.state.unlisted} onChange={this.handleUnlistedChange} />
                        <div className='ui divider' />
                        <button className="ui inverted red button" onClick={this.props.closeAdder}>Cancel</button>
                        <button className="ui inverted green button" onClick={this.handleSubmit}>Add Group</button> </div>}


            </div>




        );
    }


}
export default GroupAdder;

