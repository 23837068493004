import axios from 'axios'
import { getCurrentUser, getNameForId } from './userInfo';
import { getToken } from './token';
import { functionURL } from './config/default';


async function getGroupsForUser(publicGroups) {
    let currentUser = await getCurrentUser();
    let userId = null;
    let token = getToken();
   
    //if getting public groups.
    if (publicGroups === true) { userId = 'public'; }
    else { userId = currentUser.userId;}
   // console.log('userId', userId)
    let allGroupInfo = await axios.get(functionURL + `?userId=${userId}&type=getGroupsForUser`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }}, {});

    let groups = JSON.parse(allGroupInfo.request.response);
  console.log(groups)
    let array = [];
    let group = {}
    let groupInfo = {};
    let groupId = '';
    for (let i = 0; i < groups.length; i++) {
        if (publicGroups === true) { groupId = groups._items[i].id; }
        else { groupId = groups._items[i].inV; }
        if (groupId) {
            try {
                groupInfo = await axios.get(functionURL + `?groupId=${groupId}&type=getGroupInfo`, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }, {});
                groupInfo = JSON.parse(groupInfo.request.response);
                group = {}
                group.id = groupId;
                group.techniqueNumbers = groupInfo.techniqueNumbers._items[0]
                group.owner = await getNameForId(groupInfo.owner._items[0].outV)
                group.memberNumbers = groupInfo.memberNumbers._items[0]
                group.name = groupInfo.groupInfo._items[0].properties.name[0].value;
                group.location = groupInfo.groupInfo._items[0].properties.location[0].value;
                group.description = groupInfo.groupInfo._items[0].properties.description[0].value;
                group.website = groupInfo.groupInfo._items[0].properties.website[0].value;
                group.unlisted = groupInfo.groupInfo._items[0].properties.unlisted[0].value;
                array.push(group);
            }
            catch {
                console.log('Group deleted, skipping')
            }
        }
    }

    console.log("Array returning from get groups", array)
   
    return array;

}

async function addGroup(group) {
    let token = getToken();
    let newId = "g-" + Math.round((new Date()).getTime() / 1000); 

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.post(functionURL, {
        headers: {
            'Authorization': 'Bearer ' + token
        }}, {
        params: {
            type: 'addGroup',
            name: group.name,
            groupId: newId,
            website: group.website,
           description: group.description,
           location: group.location,
            unlisted: group.unlisted,
            userId: group.owner
        }
})
    .then(function (response) {
        console.log(response);
    })
    .catch(function (error) {
        console.log(error);
    }); 

    return newId;

}

//Function which removes the currently logged in user from the selected group. 
async function leaveGroup(groupId) {
    let token = getToken();
    let currentUser = await getCurrentUser();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.post(functionURL, {
        headers: {
            'Authorization': 'Bearer ' + token
        }}, {
        params: {
            type: 'leaveGroup',
            userId: currentUser.userId,
            group: groupId
        }
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        }); 

}


//Function which adds the current user to the specified group. 
async function joinGroup(groupId) {
    let currentUser = await getCurrentUser();
    let token = getToken();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.post(functionURL, {
        headers: {
            'Authorization': 'Bearer ' + token
        }}, {
        params: {
            type: 'joinGroup',
            userId: currentUser.userId,
            groupId: groupId
        }
    })
        .then(function (response) {
            console.log(response);
            return 200
        })
        .catch(function (error) {
            console.log(error);
            return 400
        });

}




    export { addGroup, getGroupsForUser, leaveGroup, joinGroup}