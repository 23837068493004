import React from 'react';
import { getTechniqueFromDb } from '../../data';

class AdminButton extends React.Component {

    constructor(props) {
        super(props)
        this.state = { id: props.id, status: 1, setView: props.setView }
    }

    componentDidMount() {
     
    }

    handleClick = async (e) => {
        e.persist()
        this.setState({ status: 2 })
        await getTechniqueFromDb(this.state.id)
        e.preventDefault();
        this.state.setView('admin')
        this.setState({ status: 1 })
    }

    //check if technique is currently loaded and gray it out. 
    render() {
        return (
            <>

                {this.state.status === 1 && <i className="ui big edit outline icon" style={{ cursor: 'pointer' }} onClick={this.handleClick} />}
                {this.state.status === 2 && <span>Loading...</span>}
            </>

        );
    }

}


export default AdminButton;












