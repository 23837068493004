import React from 'react';


class CopyButton extends React.Component {

    constructor(props) {
        super(props)
        this.state = { id: props.id, active: false}     
    }


    handleClick= (e) => {
        
        if (this.state.active) {
            this.setState({ active: false })
        }
        else {
            navigator.clipboard.writeText(this.state.id) 
            this.setState({ active: true })
        }
        e.preventDefault();

    }

    render() {
        if (this.state.active === true) {
            return (<span> Copied! </span>)
        }
        return (
            <i className="copy outline icon" style={{ cursor: 'pointer' }} onClick={this.handleClick}></i>
         
        );
    }
}
export default CopyButton;










