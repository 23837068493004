import React from 'react'
import Files from 'react-files'
import { writeVideoToBlob, setVideo } from '../../azureBlob.js';
import { getTechnique } from '../../data.js';


class VideoUpload extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            fileNum: 0,
            videoB64: '',
            setUploadStatus: props.setUploadStatus
        }
    }


    onFilesChange = (files) => {

        var read = new FileReader();

        read.readAsDataURL(files[this.state.fileNum]);

        read.onload = function (file) {


        }
        read.onloadend = function () {

            let technique = getTechnique();
            setVideo(read.result)
            writeVideoToBlob(technique.id, read.result);
        }

        this.setState({ fileNum: this.state.fileNum + 1 });
        this.state.setUploadStatus(4);
    }

    onFilesError = (error, file) => {
        console.log('error code ' + error.code + ': ' + error.message)
    }

    render() {
        return (
            <div className="files">
                <Files
                    className='files-dropzone'
                    onChange={this.onFilesChange}
                    onError={this.onFilesError}
                    accepts={['video/mp4']}
                    multiple
                    maxFiles={10}
                    maxFileSize={100000000}
                    minFileSize={0}
                    clickable
                >
                    <button className="ui inverted blue button">Step 3 - Upload New Video (Mp4)</button>
                </Files>
            </div>
        )
    }

}

export default VideoUpload;