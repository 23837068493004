import React from 'react';
import ExampleChart from '../charts/ExampleChart';
import NavBar from '../NavBar';
import InfoCard2020 from '../InfoCard2020';
import InfoCardKinect from '../InfoCardKinect';

class HomeView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {showPrivacy: false}
    }

    render() {
        return (
            <div className='ui stackable grid'>
                < div className='sixteen wide column' >
                    <div className="ui stackable grid">
                        <div className='fourteen wide column'>
                            <h1 style={{ color: 'rgba(64, 160, 255, 1)' }}>My AI Coach</h1>
                        </div>
                        <div className='two wide column'>
                            <NavBar />
                        </div>
                    </div>
                </div>
                <div className='sixteen wide centered column'>
                    <div className="ui raised inverted centered basic segment">
                        <div className='ui grid'>
                            <div className='eight wide centered column'>
                                <p>The goal of My Ai Coach is unlock velocity, acceleration, joint extension etc. data that until now has only been available to athletes who have access to expensive motion-capture rigs. </p>
                            </div>
                            <div className='eight wide centered column'>
                                <p>  We use Microsoft's <a href='https://azure.microsoft.com/en-us/services/kinect-dk/' >Azure Kinect DK </a> to extract joint position data and then perform biomechanical analytics and modeling, allowing you to compare your techniques over time and with other athletes.</p>
                            </div>
                        </div>
                        <div style={{ 'position': 'relative', 'height': '0' }} >

                        </div>
                        <div className='ui grid'>
                            <div className='sixteen wide centered column'>

                                <iframe title='Main Video' width='100%' height='400' src="https://www.youtube.com/embed/fGBXbNqZaQk?rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>

                        </div>
                    </div>
                    <div className="ui raised centered inverted segment">
                        <div className='ui stackable grid'>
                            <div className='five wide column'>
                                <h3 style={{ color: 'rgba(64, 160, 255, 1)' }}> Speed Vs Time </h3>
                                <ExampleChart />
                            </div>
                            <div className='ten wide column'>

                                <p><b>Imagine your car was driving on a stretch of open road between two stop signs. </b></p>
                                <p>If you wanted to drive it as <span style={{ color: 'rgba(255, 99, 132, 1)' }}> efficiently </span> as possible, you would just barely hit the throttle and then slowly coast to the next stop sign. </p>
                                <p>If you wanted to really show off what the car could do, and drive as <span style={{ color: 'rgba(64, 160, 255, 1)' }}> explosively </span> as possible, you would slam on the throttle and then immediately slam on the brakes. </p>
                                <div className='ui divider' />
                                <p>Feel free to jump right on, or check out our <a href='https://medium.com/@bill_71728/introducing-my-ai-coach-1709ae171f4e'>detailed usage guide. </a></p>
                            </div>

                        </div>

                    </div>

                </div>

                <div className='four wide centered column'>
                    <InfoCard2020 type={1} />
                </div>
                <div className='four wide centered column'>
                    <InfoCardKinect />
                </div>
                <div className='five wide centered column'>
                    <InfoCard2020 type={2} />
                </div>


                <div className='sixteen wide column'>
                    {!this.state.showPrivacy && <div className='ui inverted segment'>
                        See the <span style={{ color: 'rgba(64, 160, 255, 1)' }} onClick={() => this.setState({ showPrivacy: true })}>Terms of Use and Privacy Policy </span>
                    </div>}
                    {this.state.showPrivacy && <div className='ui inverted segment'>
                       <h2> Terms of Use and Privacy Policy </h2>

                       <p> By using My-ai-coach you agree to the terms of use and privacy policy. </p>

                        <p>  Users agree to only upload data for themselves or others for whom they have consent. </p>

                        <p>  My Ai Coach is beta software for evaluation purposes only and offers no warranty to the accuracy of our data nor their suitability for any purpose</p>

                        <p>  Users choose a privacy level for all their techniques. 1- Private, 2 - Public unlisted, or 3 - Public</p>

                        <p>  Users may change the privacy leve of their techniques at any time. </p>

                        <p>  Users may delete their techniques and videos at any time </p>

                        <p> Anonymized data may be used for purposes of training machine learning systems </p>

                        <p> Email bill@my-ai-coach.com with any questions or issues </p>

                        <p> We do not set or read any cookies on any user's device </p>
                    </div>}


                    </div>
            </div>



        );

    }
}

export default HomeView;


