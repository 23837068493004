import React from 'react';
import { removeFavorite, addFavorite, isFavorite } from '../../favoriteInfo';

class FavoriteButton extends React.Component {

    constructor(props) {
        super(props)
        this.state = { id: props.id, active: false}     
    }

    componentDidMount() {
        this.setState({ active: isFavorite(this.state.id) });
    }

    handleClick= (e) => {
        
        if (this.state.active) {
            removeFavorite(this.state.id)
            this.setState({ active: false })
        }
        else {
            addFavorite(this.state.id)
            this.setState({ active: true })
        }
        e.preventDefault();

    }

    render() {
        if (this.state.active === true) {
            return (<i className="red big heart icon" style={{ cursor: 'pointer' }} onClick={this.handleClick}></i>)
        }
        return (
            <i className="red big heart outline icon" style={{ cursor: 'pointer' }} onClick={this.handleClick}></i>
         
        );
    }
}
export default FavoriteButton;










