// src/components/Profile.js

import React from "react";
import { useAuth0 } from "../react-auth0-wrapper";
import { updateUser } from "../userInfo";

const LoginFunction = () => {
    const { user } = useAuth0();

    if (user) {
        updateUser(user);
    }
        

    return (
        <div></div>
    );
};

export default LoginFunction;