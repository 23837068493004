import React from 'react';
import ProfilePicture from './ProfilePicture';
import { getTechniqueNumbersForUser } from '../data';
import { getCurrentUser } from '../userInfo';

class AccountInfoCard extends React.Component {


    //      <input type="submit" value="Submit" / >
    constructor(props) {
        super(props)
        console.log(props)
        this.state = {number: 'loading', editUser: props.editUser}
       
    }

    componentDidMount = async () => {
     
        let currentUser = await getCurrentUser();
        let number = await getTechniqueNumbersForUser();
        console.log(number)
        this.setState({number, currentUser})
    }
    

    render() {
        if (!this.state.currentUser) { return (<div> loading...</div>) }
        return (
            <div className="ui raised link blue card" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }} >
                <div className="content">
                    <div className="blue centered header" style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>User Info</div>
                </div>
                <div className="content">
                    <div className="ui small feed">
                        <div className="event">
                            <div className="content">

                                <div className="summary">
                                    <ProfilePicture />
                                    <p style={{ color: 'rgba(64, 160, 255, 1)' }}>User Name: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> {this.state.currentUser.displayName} </span> </p>
                                    <p style={{ color: 'rgba(64, 160, 255, 1)' }}>Weight: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> {this.state.currentUser.weight} </span> </p>
                                    <p style={{ color: 'rgba(64, 160, 255, 1)' }}>Techniques: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> {this.state.number} </span> </p>
                                    <p style={{ color: 'rgba(64, 160, 255, 1)' }}>World Ranking: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> TBD </span> </p>
                                    <p style={{ color: 'rgba(64, 160, 255, 1)' }}>Subscription Level: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> TBD</span> </p>
                                    <button className="ui inverted yellow button" onClick={this.state.editUser}> Edit </button>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

}


export default AccountInfoCard;



