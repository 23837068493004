
import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { getTechnique, point } from '../data';

//import {OrbitControls} from 'three'
//import { OrbitControls } from ';
//import { BVHLoader } from '../three/BVHLoader';

class ThreeScene extends Component {

    constructor(props) {
        super(props)
        this.state = { time: 0,  delay: 100}
    }

    componentDidMount = async () => {
        const width = 350//this.mount.clientWidth
        const height = 350//this.mount.clientHeight
        //ADD SCENE
        this.scene = new THREE.Scene()
        //ADD CAMERA
        this.camera = new THREE.PerspectiveCamera(
            85,
            width / height,
            0.1,
            5000
        )
        this.camera.position.set(0, 4, 4);
        this.camera.lookAt(new THREE.Vector3(0, 0, 0))
        this.scene.background = new THREE.Color(0xeeeeee);
        this.scene.add(new THREE.GridHelper(400, 10));
        let technique = getTechnique();
        technique = await this.scaleTechnique(technique, 25)
        await this.setState({ technique })

        //ADD RENDERER
        this.renderer = new THREE.WebGLRenderer({ antialias: true })
        this.renderer.setClearColor('#000000')
        this.renderer.setSize(width, height)
        this.renderer.timeScale = 0.1
        this.mount.appendChild(this.renderer.domElement)

        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.minDistance = 100;
        this.controls.maxDistance = 400;


        var clock = new THREE.Clock();

        var camera, controls, scene, renderer;

        //create a blue LineBasicMaterial
        var material = new THREE.LineBasicMaterial({ color: 0x000000, linewidth: 20 });
        var material2 = new THREE.LineBasicMaterial({ color: 0x00ff00, linewidth: 20 });
        var material3 = new THREE.LineBasicMaterial({ color: 0x0000ff, linewidth: 20 });
        var material4 = new THREE.LineBasicMaterial({ color: 0xff0000, linewidth: 20 });
        var geometry = new THREE.Geometry();
        var geometry2 = new THREE.Geometry();
        var geometry3 = new THREE.Geometry();
        var geometry4 = new THREE.Geometry();

        let points;

        //legs
        points = this.getBone(0, 5);

        geometry.vertices.push(new THREE.Vector3(points.point1.x, points.point1.y, points.point1.z));
        geometry.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        points = this.getBone(1, 5);
        geometry.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        points = this.getBone(2, 5);
        geometry.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        points = this.getBone(3, 5);
        geometry.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        points = this.getBone(4, 5);
        geometry.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        points = this.getBone(5, 5);
        geometry.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        points = this.getBone(6, 5);
        geometry.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        points = this.getBone(7, 5);
        geometry.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        //spine and head
        points = this.getBone(8, 5);
        geometry2.vertices.push(new THREE.Vector3(points.point1.x, points.point1.y, points.point1.z));
        geometry2.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        points = this.getBone(9, 5);
        geometry2.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        //Left arm
        points = this.getBone(10, 5);
        geometry3.vertices.push(new THREE.Vector3(points.point1.x, points.point1.y, points.point1.z));
        geometry3.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        points = this.getBone(11, 5);
        geometry3.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        points = this.getBone(12, 5);
        geometry3.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        points = this.getBone(13, 5);
        geometry3.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        //right arm
        points = this.getBone(14, 5);
        geometry4.vertices.push(new THREE.Vector3(points.point1.x, points.point1.y, points.point1.z));
        geometry4.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        points = this.getBone(15, 5);
        geometry4.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        points = this.getBone(16, 5);
        geometry4.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

        points = this.getBone(17, 5);
        geometry4.vertices.push(new THREE.Vector3(points.point2.x, points.point2.y, points.point2.z));

      

        var line = new THREE.Line(geometry, material);

        var line2 = new THREE.Line(geometry2, material2);
        var line3 = new THREE.Line(geometry3, material3);
        var line4 = new THREE.Line(geometry4, material4);
        this.scene.add(line);
        this.scene.add(line2);
        this.scene.add(line3);
        this.scene.add(line4);
        this.mixer2 = new THREE.AnimationMixer(line);

        let self = this;
 
        self.setState({ camera, controls, scene, renderer, clock, frame: 5 })
        this.animate()
        this.start()
    }

    animate = () => {
    
        let frame = this.state.frame

        setTimeout(() => {
            this.frameId = window.requestAnimationFrame(this.animate);

        }, this.state.delay);

        var delta = this.state.clock.getDelta();
        var time = this.state.clock.getElapsedTime();


        if (this.mixer) {
            this.mixer.update(delta);
            this.mixer.timeScale = 0.1
        }

        if (this.mixer2) {
            this.mixer2.update(delta);
            this.mixer2.timeScale = 0.1
        }

        this.renderer.render(this.scene, this.camera)

        let points;
        points = this.getBone(0, frame);
        this.scene.children[1].geometry.vertices[0].x = points.point1.x
        this.scene.children[1].geometry.vertices[0].y = points.point1.y
        this.scene.children[1].geometry.vertices[0].x = points.point1.z

        this.scene.children[1].geometry.vertices[1].x = points.point2.x
        this.scene.children[1].geometry.vertices[1].y = points.point2.y
        this.scene.children[1].geometry.vertices[1].x = points.point2.z

        points = this.getBone(1, frame);

        this.scene.children[1].geometry.vertices[2].x = points.point2.x
        this.scene.children[1].geometry.vertices[2].y = points.point2.y
        this.scene.children[1].geometry.vertices[2].x = points.point2.z

        points = this.getBone(2, frame);

        this.scene.children[1].geometry.vertices[3].x = points.point2.x
        this.scene.children[1].geometry.vertices[3].y = points.point2.y
        this.scene.children[1].geometry.vertices[3].x = points.point2.z

        points = this.getBone(3, frame);

        this.scene.children[1].geometry.vertices[4].x = points.point2.x
        this.scene.children[1].geometry.vertices[4].y = points.point2.y
        this.scene.children[1].geometry.vertices[4].x = points.point2.z

        points = this.getBone(4, frame);

        this.scene.children[1].geometry.vertices[5].x = points.point2.x
        this.scene.children[1].geometry.vertices[5].y = points.point2.y
        this.scene.children[1].geometry.vertices[5].x = points.point2.z

        points = this.getBone(5, frame);

        this.scene.children[1].geometry.vertices[6].x = points.point2.x
        this.scene.children[1].geometry.vertices[6].y = points.point2.y
        this.scene.children[1].geometry.vertices[6].x = points.point2.z

        points = this.getBone(6, frame);

        this.scene.children[1].geometry.vertices[7].x = points.point2.x
        this.scene.children[1].geometry.vertices[7].y = points.point2.y
        this.scene.children[1].geometry.vertices[7].x = points.point2.z

        points = this.getBone(7, frame);

        this.scene.children[1].geometry.vertices[8].x = points.point2.x
        this.scene.children[1].geometry.vertices[8].y = points.point2.y
        this.scene.children[1].geometry.vertices[8].x = points.point2.z

        //2nd line - spine chest

        points = this.getBone(8, frame);

        this.scene.children[2].geometry.vertices[0].x = points.point1.x
        this.scene.children[2].geometry.vertices[0].y = points.point1.y
        this.scene.children[2].geometry.vertices[0].x = points.point1.z

        this.scene.children[2].geometry.vertices[1].x = points.point2.x
        this.scene.children[2].geometry.vertices[1].y = points.point2.y
        this.scene.children[2].geometry.vertices[1].x = points.point2.z

        points = this.getBone(9, frame);
        this.scene.children[2].geometry.vertices[2].x = points.point2.x
        this.scene.children[2].geometry.vertices[2].y = points.point2.y
        this.scene.children[2].geometry.vertices[2].x = points.point2.z


        //3rd line - left hand


        points = this.getBone(10, frame);

        this.scene.children[3].geometry.vertices[0].x = points.point1.x
        this.scene.children[3].geometry.vertices[0].y = points.point1.y
        this.scene.children[3].geometry.vertices[0].x = points.point1.z

        this.scene.children[3].geometry.vertices[1].x = points.point2.x
        this.scene.children[3].geometry.vertices[1].y = points.point2.y
        this.scene.children[3].geometry.vertices[1].x = points.point2.z

        points = this.getBone(11, frame);
        this.scene.children[3].geometry.vertices[2].x = points.point2.x
        this.scene.children[3].geometry.vertices[2].y = points.point2.y
        this.scene.children[3].geometry.vertices[2].x = points.point2.z

        points = this.getBone(12, frame);
        this.scene.children[3].geometry.vertices[3].x = points.point2.x
        this.scene.children[3].geometry.vertices[3].y = points.point2.y
        this.scene.children[3].geometry.vertices[3].x = points.point2.z


        points = this.getBone(13, frame);
        this.scene.children[3].geometry.vertices[4].x = points.point2.x
        this.scene.children[3].geometry.vertices[4].y = points.point2.y
        this.scene.children[3].geometry.vertices[4].x = points.point2.z



        //4th line - right hand


        points = this.getBone(14, frame);

        this.scene.children[4].geometry.vertices[0].x = points.point1.x
        this.scene.children[4].geometry.vertices[0].y = points.point1.y
        this.scene.children[4].geometry.vertices[0].x = points.point1.z

        this.scene.children[4].geometry.vertices[1].x = points.point2.x
        this.scene.children[4].geometry.vertices[1].y = points.point2.y
        this.scene.children[4].geometry.vertices[1].x = points.point2.z

        points = this.getBone(15, frame);
        this.scene.children[4].geometry.vertices[2].x = points.point2.x
        this.scene.children[4].geometry.vertices[2].y = points.point2.y
        this.scene.children[4].geometry.vertices[2].x = points.point2.z

        points = this.getBone(16, frame);
        this.scene.children[4].geometry.vertices[3].x = points.point2.x
        this.scene.children[4].geometry.vertices[3].y = points.point2.y
        this.scene.children[4].geometry.vertices[3].x = points.point2.z

        points = this.getBone(17, frame);
        this.scene.children[4].geometry.vertices[4].x = points.point2.x
        this.scene.children[4].geometry.vertices[4].y = points.point2.y
        this.scene.children[4].geometry.vertices[4].x = points.point2.z

        this.scene.children[1].geometry.verticesNeedUpdate = true;
        this.scene.children[2].geometry.verticesNeedUpdate = true;
        this.scene.children[3].geometry.verticesNeedUpdate = true;
        this.scene.children[4].geometry.verticesNeedUpdate = true;

        if (frame == 88) {
            frame = 0
        }

        else { frame++; }
       
        this.setState({ frame })
    

    }

    //return the end positions of bone to animate the skeleton. 
    getBone(boneNum, frame) {
        let technique = this.state.technique;
        // console.log(technique)
        switch (boneNum) {
            //First line is the legs and hips
            case 0: //left FootPos
                // console.log(technique.leftFootPos, technique.leftAnklePos )
                return ({ point1: technique.leftFootPos[frame], point2: technique.leftAnklePos[frame] });
            case 1: //left lower leg
                return ({ point1: technique.leftAnklePos[frame], point2: technique.leftKneePos[frame] });
            case 2: //left upper leg
                return ({ point1: technique.leftKneePos[frame], point2: technique.leftHipPos[frame] });
            case 3: //left HipPos to pelvisPos
                return ({ point1: technique.leftHipPos[frame], point2: technique.pelvisPos[frame] });
            case 4: //pelvisPos to right HipPos
                return ({ point1: technique.pelvisPos[frame], point2: technique.rightHipPos[frame] });
            case 5: //right upper leg
                return ({ point1: technique.rightHipPos[frame], point2: technique.rightKneePos[frame] });
            case 6: //right lower leg
                return ({ point1: technique.rightKneePos[frame], point2: technique.rightAnklePos[frame] });
            case 7: //right FootPos
                return ({ point1: technique.rightAnklePos[frame], point2: technique.rightFootPos[frame] });

            //second line is the spine and head
            case 8:
                return ({ point1: technique.pelvisPos[frame], point2: technique.spineChestPos[frame] });
            case 9:
                return ({ point1: technique.spineChestPos[frame], point2: technique.headPos[frame] });

            //third line is left 
            case 10:
                return ({ point1: technique.spineChestPos[frame], point2: technique.leftClaviclePos[frame] });
            case 11:
                return ({ point1: technique.leftClaviclePos[frame], point2: technique.leftShoulderPos[frame] });
            case 12: //left upper arm
                return ({ point1: technique.leftShoulderPos[frame], point2: technique.leftElbowPos[frame] });
            case 13: //left lower arm
                return ({ point1: technique.leftElbowPos[frame], point2: technique.leftWristPos[frame] });

            //forth line is right
            case 14:
                return ({ point1: technique.spineChestPos[frame], point2: technique.rightClaviclePos[frame] });
            case 15:
                return ({ point1: technique.rightClaviclePos[frame], point2: technique.rightShoulderPos[frame] });
            case 16: //right upper arm
                return ({ point1: technique.rightShoulderPos[frame], point2: technique.rightElbowPos[frame] });
            case 17: //right lower arm
                return ({ point1: technique.rightElbowPos[frame], point2: technique.rightWristPos[frame] });

        }

    }

    componentWillUnmount() {
        
        this.stop()
        if (this.renderer) {
            this.mount.removeChild(this.renderer.domElement)
        }
    }

    start = () => {
       
        this.frameId = requestAnimationFrame(this.animate)
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate)
        }
    }

    stop = () => {
       
        cancelAnimationFrame(this.frameId)
    }
   
    scaleTechnique = async (technique, scale) => {

        let temp = {
            //raw point arrays for each joint - note, not sure which joints will actually be exposed in API
            leftFootPos: [],
            rightFootPos: [],
            leftAnklePos: [],
            rightAnklePos: [],
            leftKneePos: [],
            rightKneePos: [],
            leftHipPos: [],
            rightHipPos: [],
            leftClaviclePos: [],
            rightClaviclePos: [],
            leftShoulderPos: [],
            rightShoulderPos: [],
            leftElbowPos: [],
            rightElbowPos: [],
            leftWristPos: [],
            rightWristPos: [],
            pelvisPos: [],
            headPos: [],
            spineChestPos: [],
        }

        for (var i = 0; i < 89; i += 1) {
            temp.leftFootPos[i] = new point(technique.leftFootPos[i].x / scale, technique.leftFootPos[i].y / scale, technique.leftFootPos[i].z / scale);
            temp.rightFootPos[i] = new point(technique.rightFootPos[i].x / scale, technique.rightFootPos[i].y / scale, technique.rightFootPos[i].z / scale);

            temp.leftAnklePos[i] = new point(technique.leftAnklePos[i].x / scale, technique.leftAnklePos[i].y / scale, technique.leftAnklePos[i].z / scale);
            temp.rightAnklePos[i] = new point(technique.rightAnklePos[i].x / scale, technique.rightAnklePos[i].y / scale, technique.rightAnklePos[i].z / scale);

            temp.leftKneePos[i] = new point(technique.leftKneePos[i].x / scale, technique.leftKneePos[i].y / scale, technique.leftKneePos[i].z / scale);
            temp.rightKneePos[i] = new point(technique.rightKneePos[i].x / scale, technique.rightKneePos[i].y / scale, technique.rightKneePos[i].z / scale);

            temp.pelvisPos[i] = new point(technique.pelvisPos[i].x / scale, technique.pelvisPos[i].y / scale, technique.pelvisPos[i].z / scale);

            temp.leftWristPos[i] = new point(technique.leftWristPos[i].x / scale, technique.leftWristPos[i].y / scale, technique.leftWristPos[i].z / scale);
            temp.rightWristPos[i] = new point(technique.rightWristPos[i].x / scale, technique.rightWristPos[i].y / scale, technique.rightWristPos[i].z / scale);

            temp.leftElbowPos[i] = new point(technique.leftElbowPos[i].x / scale, technique.leftElbowPos[i].y / scale, technique.leftElbowPos[i].z / scale);
            temp.rightElbowPos[i] = new point(technique.rightElbowPos[i].x / scale, technique.rightElbowPos[i].y / scale, technique.rightElbowPos[i].z / scale);

            temp.leftHipPos[i] = new point(technique.leftHipPos[i].x / scale, technique.leftHipPos[i].y / scale, technique.leftHipPos[i].z / scale);
            temp.rightHipPos[i] = new point(technique.rightHipPos[i].x / scale, technique.rightHipPos[i].y / scale, technique.rightHipPos[i].z / scale);

            temp.leftShoulderPos[i] = new point(technique.leftShoulderPos[i].x / scale, technique.leftShoulderPos[i].y / scale, technique.leftShoulderPos[i].z / scale);
            temp.rightShoulderPos[i] = new point(technique.rightShoulderPos[i].x / scale, technique.rightShoulderPos[i].y / scale, technique.rightShoulderPos[i].z / scale);

            temp.spineChestPos[i] = new point(technique.spineChestPos[i].x / scale, technique.spineChestPos[i].y / scale, technique.spineChestPos[i].z / scale);

            temp.headPos[i] = new point(technique.headPos[i].x / scale, technique.headPos[i].y / scale, technique.headPos[i].z / scale);

            temp.leftClaviclePos[i] = new point(technique.leftClaviclePos[i].x / scale, technique.leftClaviclePos[i].y / scale, technique.leftClaviclePos[i].z / scale);
            temp.rightClaviclePos[i] = new point(technique.rightClaviclePos[i].x / scale, technique.rightClaviclePos[i].y / scale, technique.rightClaviclePos[i].z / scale);

        }
        return temp;
    }

   

    changeAnimationTime = async (event) => {
        await this.setState({ time: event.target.value })
        this.seekAnimationTime(this.state.time)
    }

    changeDelayTime = async (event) => {
        this.setState({ delay: event.target.value })

    }

    render() {
        return (
            <div className='ui grid'>
                <div className='row'>
                    <p style={{'color': 'white'}}> Click and drag or scroll to start and control the wireframe animation</p>
                    <div className="ui label">Delay: </div><input style={{ cursor: 'pointer' }} size="10" type="text" value={this.state.delay} onChange={this.changeDelayTime} />
                    <input type="range" style={{ cursor: 'pointer' }} name="points" min="0" max="10000" value={this.state.delay} onChange={this.changeDelayTime} />
                </div>
                <div
                    style={{ width: '400px', height: '400px' }}
                    ref={(mount) => { this.mount = mount }}
                />

            </div>
        )
    }
}
export default ThreeScene

