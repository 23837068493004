import React from 'react';
import PrivacySelector from './PrivacySelector';
import { getTechnique, deleteTechnique, changeNotes, loadDefault } from '../data';
import { switchOwner } from '../userInfo';


class TechniqueOptions extends React.Component {


    constructor(props) {
        super(props)
        this.state = { loaded: false, newOwner: '', notesChanged: false,  setView: props.setView, update: props.update, updateTable: props.updateTable, successfulChange: false, changeError: false, copied: false}
    }

    componentDidMount = async () => {
        let technique = await getTechnique();
        await this.setState({ loaded: true, technique, notes: technique.notes })
    }

    handleOwnerChange = (event) => {
        this.setState({ newOwner: event.target.value });
    }

    handleNotesChange = (event) => {
        this.setState({ notes: event.target.value });
    }

    handleNotesSubmit = async (event) => {
        event.persist()
        changeNotes(this.state.notes)
        event.preventDefault();
        this.setState({notesChanged: true})

    }


    handleSubmit = async (event) => {
        event.preventDefault();
        let result
        if (this.state.Owner !== '') {
            result = await switchOwner(this.state.newOwner, this.state.technique.id)
        }
        if (result === 200) {
            this.setState({ successfulChange: true })
        }
        else {
            this.setState({ changeError: true })
        }


    }

    handleCopy = () => {
        navigator.clipboard.writeText(JSON.stringify(this.state.technique))
        this.setState({copied: true})
    }

    handleDelete = async (event) => {

        await deleteTechnique(this.state.technique.id)
        //event.preventDefault();
        loadDefault()
        await this.state.update();
        this.state.setView('myTechniques');


    }

  

    render() {

        return (<div>
            {this.state.successfulChange && <div className="ui raised inverted fluid segment"> <div className="ui positive message">
                <i className="close icon" onClick={() => this.state.setView('publicTechniques')}></i>
                <p>You have successfully changed the technique owner! The new owner should now be able to log in and see the technique in their 'My Techniques' section. </p>
            </div> </div>}
            {this.state.changeError && <div className="ui raised inverted fluid segment"> <div className="ui negative message">
                <i className="close icon" onClick={() => this.state.setView('publicTechniques')}></i>
                <p>There was a problem changing the technique owner. Please check that the email address exists in our system. If the problem persists, email bill@my-ai-coach.com with details.</p>
            </div> </div>}
            {this.state.loaded && !this.state.successfulChange &&
                <div className="ui raised inverted segment">

                    <h2 style={{ color: 'rgba(64, 160, 255, 1)' }}> Technique Admin Options </h2>
                    <div className='ui grid'>
                        <div className='twelve wide column'>

                            Delete a technique and its video from the database. WARNING: Deleting a technique is IRREVERSABLE.

                    </div>
                        <div className='three wide column'>
                            <button className="ui inverted tiny red button" onClick={this.handleDelete}>Delete</button>

                        </div>
                        <div className='twelve wide column'>
                            <p>Changing a technique's privacy determines who can view it. </p>


                        </div>
                        <div className='three wide column'>
                            <PrivacySelector techniqueId={this.state.technique.id} privacy={this.state.technique.privacy} />

                        </div>
                        <div className='twelve wide column'>
                            <form onSubmit={this.handleNotesSubmit}>

                                <div className="ui labeled fluid input"><div className="ui label">Notes: </div><input type="text" value={this.state.notes} onChange={this.handleNotesChange} /></div>
                            </form>
                        </div>
                        <div className='three wide column'>
                        {!this.state.notesChanged && <button className='ui inverted tiny green button' onClick={this.handleNotesSubmit}>Update Notes</button>}
                         {this.state.notesChanged && <button className='ui inverted tiny gray button'>Notes Updated!</button>}
                        </div>
                        <div className='twelve wide column'>
                            <form onSubmit={this.handleSubmit}>

                                <div className="ui labeled fluid input"><div className="ui label">New Owner: </div><input type="text" placeholder="Enter new owner's email address" value={this.state.newOwner} onChange={this.handleOwnerChange} /></div>
                            </form>
                        </div>
                        <div className='three wide column'>
                            <button className='ui inverted tiny green button' onClick={this.handleSubmit}>Change Owner</button>
                        </div>
                        <div className='twelve wide centered column'>
                            <p> <span style={{ color: 'rgba(64, 160, 255, 1)' }} >Technique id: </span> {this.state.technique.id} </p>
                        <p>You can download the MP4 file by doing to the 'dashboard' section and right clicking on the file. You can see the raw technique data in the console (CTRL-SHIFT-I)
                        {this.state.copied == false && <span style={{ color: 'rgba(64, 160, 255, 1)', textDecorationLine: 'underline' }} onClick={this.handleCopy}> or copy it. </span>}
                            {this.state.copied == true && <span style={{ color: 'rgba(255, 99, 132, 1)'}}> copied to clipboard! </span>}
                        </p>


                        <button className='ui inverted tiny red button' onClick={() => this.state.setView('publicTechniques')}> Cancel </button>
                        </div>

                    </div>
                </div>
            }
        </div>
        );
    }


}
export default TechniqueOptions;