import React from 'react';

function LoadGroupButton(props) {

    function handleClick(e) {

        props.loadTechniques(props.groupId);
        e.preventDefault();
   
    }

    return (
        //check if technique is currently loaded and gray it out. 
        <button className="ui inverted green button" style={{ cursor: 'pointer' }} onClick={handleClick}>View</button>
    );
}

export default LoadGroupButton;










