import React from 'react';
import '../../azureBlob.js';
import HeaderSection from '../HeaderSection';
//import { getImage } from '../../azureBlob.js';
import { getAllUsers } from '../../userInfo';

class TechniquesView extends React.Component {


  constructor(props) {
        super(props)
      this.state = {
          leaderboardTable: []}
    }


  

    componentDidMount() {
     
        this.updateLeaderBoard()
    }

   updateLeaderBoard = async () => {
        let allUsers = await getAllUsers();

     allUsers.sort(function (a, b) {
            return b.overallScore - a.overallScore;
        });
        
      // console.log(allUsers);

       let leaderboardTable = [];
 

       for (var i = 0; i < allUsers.length; i++) {
               leaderboardTable.push(
                   <tr key={i}>
                       <td data-label="Rank">{i+1}</td>
                       <td data-label="User">{allUsers[i].name}</td>
                       <td data-label="Overall Score">{allUsers[i].overallScore}</td>
                       <td data-label="Weight">{allUsers[i].weight}</td>
                   </tr>
               );
       }
      // console.log(leaderboardTable)
       this.setState({ leaderboardTable: leaderboardTable })

   }
    

    render() {
        return (
            <div className="ui grid">
             <div className='fourteen wide centered column'>
                    <div className="ui raised inverted segment">
                        <p style={{ color: 'rgba(255, 255, 255, 1)' }}>The leaderboard will be completed once we are confident in the accuracy of our data.</p>
                        <p style={{ color: 'rgba(255, 255, 255, 1)' }}>The user leaderboard will rank athletes by their overall score.</p>
                        <p style={{ color: 'rgba(255, 255, 255, 1)' }}>You will be able to sort the leaderboard by technique type. Typed techniques will be sorted by score, while general human motions will be sorted by speed.</p>
                    </div>
                     
                </div>

                <HeaderSection title="User Leaderboard" color='blue' />

                <table className="ui blue striped unstackable table">
                    <thead>
                        <tr>
                            <th scope="col" >Rank</th>
                            <th scope="col" >User</th>
                            <th scope="col" >Overall Score</th>
                            <th scope="col" >Weight</th>
                        </tr>
                    </thead>
                    <tbody>
                      
                        {this.state.leaderboardTable}
                    </tbody>
                </table>


                <HeaderSection title="Technique Leaderboard" color='blue' />

                <table className="ui blue striped unstackable table">
                    <thead>
                        <tr>
                            <th scope="col" >Rank</th>
                            <th scope="col" >Type</th>
                            <th scope="col" >ID</th>
                            <th scope="col" >Date</th>
                            <th scope="col" >Score</th>
                            <th scope="col">Max Speed</th>
                            <th scope="col">Note</th>
                       
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                           <td>1</td>
                            <td>Roundhouse</td>
                            <td>452</td>
                            <td>5/4/2019</td>
                            <td>9.68</td>
                            <td>8.2 m/s</td>
                            <td>The day after squats</td>
                        
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Front Kick</td>
                            <td>453</td>
                            <td>5/4/2019</td>
                            <td>9.38</td>
                            <td>8.2 m/s</td>
                            <td>The day after squats</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Back Kick</td>
                            <td>454</td>
                            <td>5/4/2019</td>
                            <td>9.68</td>
                            <td>8.2 m/s</td>
                            <td>The day after squats</td>
                        </tr>

                    </tbody>
                </table>

        </div>
        );
    }
}

export default TechniquesView;


