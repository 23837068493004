import React from 'react';



class InfoCard2020 extends React.Component {


    constructor(props) {
        super(props)
        this.state = {  }
    }


    render(props) {
   
     
        if (this.props.type === 1) {
            return (
                <div className="ui raised inverted centered link blue card" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }} >
                    <div className="content">
                        <p style={{ 'color': 'white' }}>There are two parts to understanding a move - the <span style={{ color: 'rgba(64, 160, 255, 1)' }}>technique </span> itself and the <span style={{ color: 'rgba(64, 160, 255, 1)' }}>result </span> (impact, damage, etc) of the technique. My AI Coach provides understanding of the technique
                            and recommends <a style={{ 'text-decoration': 'underline', color: 'rgba(64, 160, 255, 1)'}} href='https://www.2020armor.com'> 2020 Armor </a> for understanding the result.</p>
                    </div>
                </div>
            );
        }
        if (this.props.type === 2) {
            return (
                <div className="ui raised inverted centered link blue card" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }} >
                    <div className="content">
                        <p style={{ 'color': 'white' }}> During the beta period we will open up the tool for free. If you would like the software to extract data from Kinect recordings or would like to collaborate on entering body data from other sources, email <span style={{ color: 'rgba(64, 160, 255, 1)' }}> bill@my-ai-coach.com </span> </p> 
                    </div>
                </div>
            );
        }
       

    }

}


 


export default InfoCard2020;



