let techTypes = [
    { 'type': 'c1', name: 'Any Human Movement' },
    { 'type': 'c2', name: 'Front Snap Kick' },
    { 'type': 'c3', name: 'Side Kick' },
    { 'type': 'c4', name: 'Ax Kick' },
    { 'type': 'c5', name: 'Back Kick' },
    { 'type': 'c6', name: 'Straight Punch' },
    { 'type': 'c7', name: 'Double Kicks' },
    { 'type': 'c8', name: '360 Roundhouse Kick' },
    { 'type': 'c9', name: 'Low Block' },
    { 'type': 'c10', name: 'High Block' },
    { 'type': 'c11', name: 'Long Stance' },
    { 'type': 'c12', name: 'Crane Stance' },
    { 'type': 'c13', name: 'Hand Techniques' },
    { 'type': 'c14', name: 'Roundhouse Kick' },
    { 'type': 'c14', name: 'Spin Hook' },
];


//function to set the current session token. 
export function getHRTechType(techType) {
    for (let i = 0; i < techTypes.length; i++) {
        if (techTypes[i].type === techType) {
            return techTypes[i].name;
        }
    }
    return 'unknown'

}