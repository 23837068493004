import React from 'react';
import { getTechniqueTypes } from '../customTech';


class TechniqueTypeSelector extends React.Component {

    constructor(props) {
        super(props)
        this.state = { privacy: props.privacy, techniqueId: props.techniqueId }
    }


    componentDidMount = async () => {
        let options = [];

        let result = await getTechniqueTypes();
        console.log(result)
        options.push(<option key={9999999} value={99999999}>Select Technique Type</option>)
        for (let i = 0; i < result.length; i++) {
            options.push(<option key={'c' + i} value={'c' + (i + 1)}>{result[i].id} -- {result[i].properties.name[0].value}</option>);
        }

        this.setState({ options })
    }


    setType = (event) => {
        this.props.setType(event.target.value)
    }

    render() {
        return (
            <div>
                <select onChange={this.setType}>

                    {this.state.options}

                </select>
            </div>

        );
    }
}

export default TechniqueTypeSelector;










