import React from 'react';

class TechniqueSuggestions extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {

        return (
            <div className="ui raised inverted segment">
                <h2 style={{ color: 'rgba(64, 160, 255, 1)' }}> Automatically Generated Suggestions </h2>
                <p> Suggestions are currently <span style={{ color: 'rgba(255, 99, 132, 1)' }}> examples </span> of what we will be able to generate by comparing your technique with average values of all techniques of a particular type </p>
                <div className='ui grid'>
                    <div className='three wide column'>
                        <img className="ui tiny image" src="/images/site/strength.png" alt='strength' />
                    </div>
                    <div className='twelve wide column'>
                        <h3 style={{ color: 'rgba(64, 160, 255, 1)' }}>Strength </h3>
                        <p>Knee extension is slower than average </p>
                        <p>Add Power Snatch, Back Squat and leg extension machine </p>
                        <p>Research shows explosion is increased at sets of 80% 1RM for 3-5 reps </p>
                    </div>
                    <div className='three wide column'>

                        <img className="ui tiny image" src="/images/site/stretch.png" alt='flexibility' />
                    </div>
                    <div className='twelve wide column'>
                        <h3 style={{ color: 'rgba(64, 160, 255, 1)' }}>Technique </h3>
                        <p>At the moment of impact, shoulders are too far forward relative to hips</p>
                    </div>
                    <div className='three wide column'>
                        <img className="ui tiny image" src="/images/site/technique.png" alt='technique' />
                    </div >
                    <div className='twelve wide column'>
                        <h3 style={{ color: 'rgba(64, 160, 255, 1)' }}>Flexibility </h3>
                        <p>Kicking height is higher than average</p>
                        <p>No changes needed</p>
                    </div>
                    <div className="ui section divider"></div>
                    <div className='ui twelve wide centered column'>
                        <p>See <a href="http://www.wikidrills.com" > www.wikidrills.com </a> for more free training drills.  </p>
                    </div>

                </div>

            </div>
        );
    }


}
export default TechniqueSuggestions;