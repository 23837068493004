import React from 'react';
import { getImage } from '../azureBlob';

class DojangInfoCard extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            image: ''
        }
    }
    componentDidMount() {
        this.loadImage()
    }

    loadImage = async () => {
        let image = 'images/dojangs/dojang.jpg';
        console.log(this.props)
        if (this.props.dojang.id !== 0) {
          image = await getImage('dojang');
        }
        this.setState({ image: image });
    }


    render(props) {

        return (<div className="ui raised link blue card" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }} >
            <div className="content">
                <div className="blue centered header" style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>School Info</div>
            </div>
            <div className="content">
                <img className="ui small image" src={this.state.image} alt='dojang' />

                <div className="content">
                    <p style={{ color: 'rgba(64, 160, 255, 1)' }}>School: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> {this.props.dojang.name} </span> </p>
                    <p style={{ color: 'rgba(64, 160, 255, 1)' }}>ID: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> {this.props.dojang.id}  </span> </p>
                    <p style={{ color: 'rgba(64, 160, 255, 1)' }}>Members: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> {this.props.dojang.memberNumbers} </span> </p>
                    <p style={{ color: 'rgba(64, 160, 255, 1)' }}>Website: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> {this.props.dojang.website} </span> </p>
                    <p style={{ color: 'rgba(64, 160, 255, 1)' }}>Description: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> {this.props.dojang.description} </span> </p>
                    <p style={{ color: 'rgba(64, 160, 255, 1)' }}>Techniques: <span style={{ color: 'rgba(255, 255, 255, 1)' }}> {this.props.dojang.techniqueNumbers} </span> </p>

                    <div className="summary">
                        <button className="ui inverted blue button" onClick={this.props.changeDojang}>Change</button>

                    </div>
                </div>


            </div>
        </div>


        );
    }

}

export default DojangInfoCard;


