import React from 'react';
import HeaderSection from '../HeaderSection';
import GroupAdder from '../GroupAdder';
import {getGroupsForUser, leaveGroup, joinGroup} from '../../groupInfo.js'
import { getTechniquesForGroup, getTechniqueFromGraphDb, getTechnique } from '../../data';
import LoadButton from '../buttons/LoadButton';
import CompareButton from '../buttons/CompareButton';
import LoadGroupButton from '../buttons/LoadGroupButton';
import CopyButton from '../buttons/CopyButton';
import FavoriteButton from '../buttons/FavoriteButton';
import { getHRTechType } from '../../techTypes';
import NavBar from '../NavBar';
import { getCurrentUser } from '../../userInfo';
import { getCompTechnique } from '../../compData';
class GroupsView extends React.Component {


  constructor(props) {
        super(props)
      this.state = { view: 'public', techTable: [], groupsTable: [], joining: false, leaving: false, join: '', leave: '', setModule: props.setModule, techLoaded: false, compLoaded: false, noGroup: false}
    }

    componentDidMount = async () => {
        let currentUser = await getCurrentUser();
       
        if (currentUser.userId == 0) {
            this.setState({ view: 'public', mustLogin: true })
            this.loadGroups('public');
        }
        else {
            this.setState({ view: 'myGroups', mustLogin: false })
            this.loadGroups('myGroups');
        }
        
    }

    loadGroups = async (view) => {
        var self = this;
        let groups = {}
        if (view === 'public') {
            groups = await getGroupsForUser(true)
            self.setState({ view: 'public' });
         
        }
        else {
            groups = await getGroupsForUser(false)
            self.setState({ view: 'myGroups' });
        }
        let groupsTable = [];
     
        for (var i = 0; i < groups.length; i++) {
            groupsTable.push(
                <tr key={i}>
                    <td data-label="Name"><a href={groups[i].website}> {groups[i].name}</a> <CopyButton id={groups[i].id} /></td>
                    <td data-label="Location">{groups[i].location}</td>
                    <td data-label="Members">{groups[i].memberNumbers}</td>
                    <td data-label="Techniques">{groups[i].techniqueNumbers}</td>
                    <td data-label="Owner">{groups[i].owner}</td>
                    <td data-label="Description">{groups[i].description}</td>
                    <td data-label="Action"> {!this.state.mustLogin && <LoadGroupButton loadTechniques={this.loadTechniques} groupId={groups[i].id} />} </td>
                </tr>
            );
        }

        if (groupsTable.length === 0) {
            self.setState({noGroup: true})
        }
   
            self.setState({ groupsTable: groupsTable, leaving: false, joining: false })

   
    }

    loadTechniques = async (groupId) => {
       
        let techniques = await getTechniquesForGroup(groupId, this.state.view);
        let currentTech = {}
        let techTable = [];
        let humanDate = '';
        let humanType = [];
               console.log(techniques)
        for (var i = 0; i < techniques._items.length; i++) {
           
            currentTech = await getTechniqueFromGraphDb(techniques._items[i].id);
            humanDate = this.getHumanReadableDate(currentTech.timeStamp)
            humanType = getHRTechType(currentTech.techType);
            console.log(currentTech)
                techTable.push(
                    <tr key={i}>
                        <td data-label="Type">{humanType}</td>
                        <td data-label="Type">{humanType}</td>
                        <td data-label="Date">{humanDate}</td>
                        <td data-label="Score">{currentTech.calculatedScore}</td>
                        <td data-label="Action"> <LoadButton techniqueId={currentTech.id} refresh={this.updateTechnique}/>
                            <CompareButton techniqueId={currentTech.id} update={this.updateComparisonTechnique} /> <FavoriteButton id={currentTech.id}/> </td>
                    </tr>
                );
            }
        
        this.setState({ techTable: techTable })
    }

    closeAdder = () => {
        this.setState({ view: 'myGroups' });
    }


    handleJoinChange = (event) => {
        this.setState({ join: event.target.value });
    }

    handleLeaveChange = (event) => {
        this.setState({ leave: event.target.value });
    }

    handleJoinSubmit = async () => {
        this.setState({joining: true})
        await joinGroup(this.state.join.trim());
        this.loadGroups(this.state.view)

    }

    handleLeaveSubmit = async () => {
        this.setState({ leaving: true })
        await leaveGroup(this.state.leave.trim());
        this.loadGroups(this.state.view)

    }

    getHumanReadableDate(time) {
        time = parseInt(time, 10);
        let timestamp = new Date(time * 1000);
        var date = timestamp.getFullYear() + '-' + (timestamp.getMonth() + 1) + '-' + timestamp.getDate();
        time = timestamp.getHours() + ":" + timestamp.getMinutes() + ":" + timestamp.getSeconds();
        return (date + ' ' + time);
    }


    updateTechnique = () => {
        var self = this;
        let technique = getTechnique();
        let timeStamp = this.getHumanReadableDate(technique.timeStamp);
        let humanType = getHRTechType(technique.techniqueType);
        self.setState({ currentType: humanType, currentTimeStamp: timeStamp, currentScore: technique.calculatedScore, notes: technique.notes, currentId: technique.id , techLoaded: true});

    }

    updateComparisonTechnique = async () => {
        var self = this;
        let technique = await getCompTechnique();
        let timeStamp = this.getHumanReadableDate(technique.timeStamp);
        let humanType = getHRTechType(technique.techniqueType);
        self.setState({ compType: humanType, compTimeStamp: timeStamp, compNotes: technique.notes, compScore: technique.calculatedScore, compId: technique.id, compLoaded: true });


    }


    render() {

        return (
   
            <div className="ui grid">
                <div className='fourteen wide centered column'>
                   
                    {this.state.mustLogin === false && <div className="ui raised inverted segment">


                        <button className="ui inverted tiny blue basic button" onClick={() => this.loadGroups('myGroups')}>My Groups</button>
                        <button className="ui inverted tiny basic green button" onClick={() => this.loadGroups('public')}>Public Groups</button>
                        <button className="ui inverted tiny basic orange button" onClick={() => this.setState({ view: 'add' })}>Add New</button>
                        <div className='ui divider' />
                        <div className='ui stackable grid'>
                            <div className='eight wide column'>
                                <p style={{ color: 'rgba(255, 255, 255, 1)' }}>Enter a group's id to join. Find and copy (<i className='tiny copy outline icon' />) public group ids in the public list, or ask a member for a secret id.</p>
                                <input type="text" value={this.state.join} onChange={this.handleJoinChange} placeholder="Search groups..." />
                                {!this.state.joining && <span style={{ 'paddingLeft': 10 }}> <button className="ui inverted tiny green button" onClick={() => this.handleJoinSubmit()}>Join</button> </span>}
                                {this.state.joining && <span style={{ 'paddingLeft': 10 }}> <button className="ui inverted tiny gray button" >Joining...</button> </span>}
                            </div>
                            <div className='eight wide column'>
                                <p style={{ color: 'rgba(255, 255, 255, 1)' }}>Enter group's id to leave. If you are the owner of the group, the group will be deleted.</p>
                                <input type="text" value={this.state.leave} onChange={this.handleLeaveChange} placeholder="Leave group..." />
                                {!this.state.leaving && <span style={{ 'paddingLeft': 10 }}> <button className="ui inverted tiny red button" onClick={() => this.handleLeaveSubmit()}>Leave</button> </span>}
                                {this.state.leaving && <span style={{ 'paddingLeft': 10 }}> <button className="ui inverted tiny gray button">Leaving...</button> </span>}
                            </div>
                        </div>
                    </div>}
                    {this.state.mustLogin === true && <div className="ui raised inverted segment"> 
                        <p>Here are some of our public groups at My-Ai-Coach. Please register or log in to see your personal groups or to see techniques from public groups.  If you are logged in, please go to another section and come back here to see your groups.</p>
                        <NavBar />
                        </div>}

                </div>
                <div className='fourteen wide centered column'>
                {this.state.view === 'add' && <GroupAdder closeAdder={this.closeAdder} />}
                {this.state.view === 'myGroups' && <div className='ui grid'>
                        <HeaderSection title="My Groups" color={'rgba(64, 160, 255, 1)'} />
                        <div className='ui sixteen wide column'>
                    <table className="ui blue striped unstackable table">
                        <thead>
                            <tr>
                                <th scope="col" >Name</th>
                                <th scope="col" >Loc</th>
                                <th scope="col" >Mem</th>
                                <th scope="col" >Tech</th>
                                <th scope="col">Owner</th>
                                <th scope="col">Desc</th>
                                <th scope="col">Action</th>

                            </tr>
                        </thead>
                            <tbody>
                                    {this.state.groupsTable.length === 0 && this.state.noGroup === false && <tr><td><img src='images/loading.gif' /></td></tr>}
                               
                            {this.state.groupsTable.length !== 0 && this.state.groupsTable}
                        </tbody>
                            </table>
                            {this.state.noGroup === true && <div className='sixteen wide column'>
                                <div className='ui raised segment'>
                                    <p>You don't yet have any groups. Enter the group's Id above to join one or create your own.</p>
                                </div>
                            </div>}
                            </div>
                </div>}
                    {this.state.view === 'public' && <div className='ui grid'>
                        
                        <HeaderSection title="Public Groups" color='green' />
                        <div className='ui sixteen wide column'>
                    <table className="ui green unstackable table">
                        <thead>
                            <tr>
                                <th scope="col" >Name</th>
                                <th scope="col" >Loc</th>
                                <th scope="col" >Memb</th>
                                <th scope="col" >Tech</th>
                                <th scope="col">Owner</th>
                                <th scope="col">Desc</th>
                                <th scope="col">Action</th>

                            </tr>
                        </thead>
                        <tbody>
                            
                                {this.state.groupsTable.length !== 0 && this.state.groupsTable}
                        </tbody>
                        </table>
                            {this.state.groupsTable.length == 0 && <div className='sixteen wide centered column'><img src='images/loading.gif' /></div>}
                            </div>
                </div>}
                    {this.state.techTable.length !== 0 && <div className='ui grid'>
                        <div className = 'ui divider' />
                        <HeaderSection title="Group Techniques" color='orange' />
                        <div className='sixteen wide centered column'>
                            {this.state.techLoaded && <div className='ui raised fluid centered segment'>

                                <h3> Currently Loaded Technique </h3>
                                <table className="ui blue striped unstackable table">
                                    <thead>
                                        <tr>
                                            <th scope="col" >Type</th>
                                            <th scope="col" >Date</th>
                                            <th scope="col" >Notes</th>
                                            <th scope="col" >Score</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr key='currently loaded'>
                                            <td data-label="Type">{this.state.currentType}</td>
                                            <td data-label="Date">{this.state.currentTimeStamp}</td>
                                            <td data-label="Notes">{this.state.notes}</td>
                                            <td data-label="Score">{this.state.currentScore}</td>
                                            <td data-label="Action"><button className='ui fliud button' onClick={() => this.state.setModule('Dashboard')}>Analyze</button></td>

                                        </tr>

                                        {this.state.compLoaded && <tr key='comparison'>
                                            <td data-label="Type">{this.state.compType}</td>
                                            <td data-label="Date">{this.state.compTimeStamp}</td>
                                            <td data-label="Notes">{this.state.compNotes}</td>
                                            <td data-label="Score">{this.state.compScore}</td>

                                        </tr>}
                                    </tbody>
                                </table>

                            </div>}
                            </div>
                        <table className="ui orange striped fluid unstackable table">
                           
                    <thead>
                        <tr>

                            <th scope="col" >Type</th>
                            <th scope="col" >Owner</th>
                            <th scope="col" >Date</th>
                            <th scope="col" >Score</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.techTable}
                    </tbody>
                        </table>
                        {this.state.techTable.length == 0 && <div className='sixteen wide centered column'><img src='images/loading.gif' /></div>}
                    </div>}
                        </div>
              
       
        </div>
        );
    }
}

export default GroupsView;


/*  
                    {groups[i].unlisted == 'false' && <td data-label="Privacy">Public</td>}
                    {groups[i].unlisted == 'true' && <td data-label="Privacy"><span style={{'color': 'red'}}>Unlisted</span></td>}
                    */