import React from 'react';
import { getCurrentUser, deleteUser, setUser } from '../userInfo';
import ImageUpload from './uploaders/ImageUpload';

class AccountEditor extends React.Component {

    //      <input type="submit" value="Submit" / >
    constructor(props) {
        super(props)
        this.state = { confirmDelete: false, onFinishEdit: props.onFinishEdit, name: '', weight: '', email: '', eth: '' }
    }


    componentDidMount = () => {
        let user = getCurrentUser()
        this.setState({ name: user.displayName, weight: user.weight, email: user.email, eth: user.ethAddress })
    }

    handleNameChange = (event) => {
        this.setState({ name: event.target.value });
    }

    handleWeightChange = (event) => {
        this.setState({ weight: event.target.value });
    }

    handleEmailChange = (event) => {
        this.setState({ email: event.target.value });
    }

    handleEthChange = (event) => {
        this.setState({ eth: event.target.value });
    }




    confirmDeleteAccount = () => {
        alert("Deleting an account is IRREVERSABLE. Deleting your account will also cause all your techniques to be deleted as well as any dojangs you are the owner of.")
        this.setState({ confirmDelete: true })
    }

    deleteAccount = () => {
        deleteUser();
        this.setState({ confirmDelete: false })
        //TODO: log out user. 
    }



    handleSubmit = async (event) => {

        var self = this;
        event.preventDefault();
        let currentUser = getCurrentUser();

        //check if each property is different than current before setting. 
        if (currentUser.displayName !== self.state.name) { await setUser('displayName', self.state.name); }
        if (currentUser.email !== self.state.email) { await setUser('email', self.state.email); }
        if (currentUser.weight !== self.state.weight) { await setUser('weight', self.state.weight); }
        if (currentUser.ethAddress !== self.state.eth) { await setUser('eth', self.state.eth); }

        this.state.onFinishEdit();

    }

    render() {

        return (

            <div className="ui inverted segment">
                <div className="ui two column very relaxed grid">
                    <div className=" eight wide column">
                        <div className="ui labeled fluid input"><div className="ui label">Display Name: </div><input type="text" value={this.state.name} onChange={this.handleNameChange} /></div>
                        <div className='ui divider' />
                        <div className="ui labeled fluid input"><div className="ui label">Weight: </div><input type="text" value={this.state.weight} onChange={this.handleWeightChange} /></div>
                        <div className='ui divider' />
                        <div className="ui labeled fluid input"><div className="ui label">Email: </div><input type="text" value={this.state.email} onChange={this.handleEmailChange} /></div>
                        <div className='ui divider' />
                        <div className="ui labeled fluid input"><div className="ui label"><i className="ethereum icon" />Eth Address: </div><input type="text" value={this.state.email} onChange={this.handleEmailChange} /></div>
                        <div className='ui divider' />
                        <button className="ui inverted red button" onClick={this.handleSubmit}>Cancel</button>
                        <button className="ui inverted green button" onClick={this.handleSubmit}>Submit</button>
                    </div>
                    <div className="eight wide column">


                        <button className="ui inverted green button">Change Subscription Plan</button>
                        <div className='ui divider' />
                        <ImageUpload imageType={'user'} />
                        <div className='ui divider' />

                        {!this.state.confirmDelete && <button className="ui inverted yellow button" onClick={this.confirmDeleteAccount}>Delete Account</button>}
                        {this.state.confirmDelete && <button className="ui inverted red button" onClick={this.deleteAccount}>I'm Sure - Really Delete Account</button>}
                        <div className='ui divider' />
                        <p> Providing your Ethereum address is optional, but it will allow you to receive bonus Taekwondo Access Credit (TAC) from the <a href="https://www.tkd-coop.com" >Taekwondo Coop. </a> </p>
                    </div>
                </div>
                <div className="ui vertical divider">
                    OR
                    </div>
            </div>




        );
    }


}
export default AccountEditor;