
import React from "react";
import { useAuth0 } from "../react-auth0-wrapper";

// NEW - import the Link component
//import { Link } from "react-router-dom";

const NavBar = () => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    return (
        <div>
            {!isAuthenticated && (
                <button className ='ui inverted blue button'
                    onClick={() =>
                        loginWithRedirect({})
                    }
                >
                    Login
        </button>
            )}

            {isAuthenticated && <button className ='ui inverted red button' onClick={() => logout()}>Logout</button>}
        </div>
    );
};

export default NavBar;