import React from 'react'
import Files from 'react-files'
import { uploadTechniqueFromFile } from '../../data.js';


class FileUpload extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            fileNum: 0,
            setUploadStatus: props.setUploadStatus,
            techType: props.techType
        }
    }

    onFilesChange = (files) => {
  
        var read = new FileReader();

        read.readAsBinaryString(files[this.state.fileNum]);
        
        read.onloadend = function () {
         //   console.log(read.result);
            let technique = JSON.parse(read.result);
            uploadTechniqueFromFile(technique);
         //   uploadBVH();
      
        }
        this.setState({ fileNum: this.state.fileNum + 1 });
        this.state.setUploadStatus(3);
    }

    onFilesError = (error, file) => {
        console.log('error code ' + error.code + ': ' + error.message)
    }

    render() {
        return (
            <div className="files">
                <Files
                    className='files-dropzone'
                    onChange={this.onFilesChange}
                    onError={this.onFilesError}
                    accepts={['application/json']}
                    multiple
                    maxFiles={10}
                    maxFileSize={3000000}
                    minFileSize={0}
                    clickable
                >
                    <button className="ui inverted blue button">Step 2 - Upload New Technique (JSON)</button>
        </Files>
            </div>
        )
    }

}

export default FileUpload;