import axios from 'axios'
import { getCurrentUser } from './userInfo';
import { getUserDojang } from './dojangInfo';
import { getToken } from './token';
import { functionURL } from './config/default';
const { BlobServiceClient } = require("@azure/storage-blob"); // Change to "@azure/storage-blob" in your package
  

//Local cache files
let userImage = '';
let dojangImage = ''

let compVideo = '';
let video = ''

const baseURL = "https://newteststorageaccount123.blob.core.windows.net/";


async function uploadImage(newImage, type) {

    let SAS = await getContainerSAS('images');
 
    const blobServiceClient = new BlobServiceClient(baseURL + '?' + SAS);
  
    // Create a blob
    let blobName = '';
    if (type === 'user') {
        let currentUser = await getCurrentUser();
        blobName = currentUser.userId
        console.log(blobName);
        userImage = newImage;
       
    }

    if (type === 'dojang') {
        let dojang = await getUserDojang();
        blobName = dojang.id;
        dojangImage = newImage;
    }

    const containerName = 'images'
    const containerClient = await blobServiceClient.getContainerClient(containerName);
    try {
        const blobClient = containerClient.getBlobClient(blobName);
        const blockBlobClient = blobClient.getBlockBlobClient();
        const uploadBlobResponse = await blockBlobClient.upload(newImage, newImage.length);
        console.log(`Upload block blob ${blobName} successfully`, uploadBlobResponse.requestId);
    }
    catch (error) {
        console.log('error updating picture', error)
    }

}


//Function to get the shared access signiture to write to a container. 
async function getContainerSAS(containerType) {
    let token = getToken();
    let info = await axios.get(functionURL + `?container=${containerType}&type=getSAS&blobName&permissions=w`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }}, {});
    info = JSON.parse(info.request.response);
    return info.token

}


//Functions to get Shared Access Sigs to read files. 

async function getImageSAS(image) {
    let token = getToken();
    let info = await axios.get(functionURL + `?&type=getImageSAS&blobName=${image}`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }}, {});
    info = JSON.parse(info.request.response);
    return info.token

}

async function getTechSAS(technique) {
    let token = getToken();
   
    try {
        let info = await axios.get(functionURL + `?container=techdata&type=getSAS&blobName=${technique}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }, {});
        info = JSON.parse(info.request.response);
        return info.token
    }
    catch (error) {
        console.log(error)
        return 403
    }

}

async function getVideoSAS(video) {
    let token = getToken();
    let info = await axios.get(functionURL + `?container=videos&type=getSAS&blobName=${video}`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }}, {});
    info = JSON.parse(info.request.response);
    return info.token

}


async function getImage(type) {
    var xhr = new XMLHttpRequest();
    //first check if an image is already loaded. If not, go to blob storage. 
    axios.defaults.headers.common['Authorization'] = {}
    if (type === 'user') {

        if (userImage !== '') {
                return userImage;
        }
        else {
            let currentUser = await getCurrentUser();
            if (currentUser.userId == 0) {
                return "images/default.jpg"
            }
            let SAS = await getImageSAS(currentUser.userId);
            
            const url = baseURL + "images/" + currentUser.userId + '?' + SAS;
            xhr.open('GET', url, true);
            xhr.send();
            xhr.addEventListener("readystatechange", processRequest, false);
            try {
                let blob = axios.create({
                    baseURL: url
                });
                let response = await blob();
                userImage = response.data;
                return response.data;
            }
            catch (error) {
               return "images/default.jpg"
            }
        }
    }

    if (type === 'dojang') {
        if (dojangImage !== '') { return dojangImage; }
        else {
            let dojang = await getUserDojang();
            let SAS = await getImageSAS(dojang.id);
            const url = baseURL + "images/" + dojang.id + '?' + SAS;
            xhr.open('GET', url, true);
            xhr.send();
            xhr.addEventListener("readystatechange", processRequest, false);
            try {
                let blob = axios.create({
                    baseURL: url
                });
                let response = await blob();
                dojangImage = response.data;
                return response.data;
            }
            catch (error) {
                return "images/dojangs/dojang.jpg"
            }
        }
    }


}

async function writeTechniqueToBlob(technique) {
    
    const containerName = `techdata`;
    let SAS = await getContainerSAS(containerName);

    const blobServiceClient = new BlobServiceClient(baseURL + '?' + SAS);

    const containerClient = blobServiceClient.getContainerClient(containerName);

    // Create a blob
    const blobName = technique.id;
    technique = JSON.stringify(technique);
   
    console.log(blobName);
    try {
        const blobClient = containerClient.getBlobClient(blobName);
        const blockBlobClient = blobClient.getBlockBlobClient();
        const uploadBlobResponse = await blockBlobClient.upload(technique, technique.length);
       // console.log(`Upload block blob ${blobName} successfully`, uploadBlobResponse.requestId);
    }
    catch (error) {
        console.log('error updating technique', error)
    }


}

async function writeVideoToBlob(id, data) {
   
    const containerName = `videos`;
    let SAS = await getContainerSAS(containerName);

    const blobServiceClient = new BlobServiceClient(baseURL + '?' + SAS);

    const containerClient = blobServiceClient.getContainerClient(containerName);


    // Create a blob
    const blobName = id;
   
   
    try {
        const blobClient = containerClient.getBlobClient(blobName);
        const blockBlobClient = blobClient.getBlockBlobClient();
        const uploadBlobResponse = await blockBlobClient.upload(data, data.length);
        console.log(`Upload block blob ${blobName} successfully`, uploadBlobResponse.requestId);
    }
    catch (error) {
        console.log('error updating technique', error)
    }


}

///////////////////////////////////////////////////////////////DELETION FUNCTIONS //////////////////////////////////////////////////////////////////////////

//If the user isn't authorized to delete a certain resource, they shouldn't receive a deletion SAS


async function getDeleteSAS(containerName, blobName) {
    let token = getToken();
    let info = await axios.get(functionURL + `?container=${containerName}&type=getSAS&blobName=${blobName}&permissions=d`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }}, {});
    info = JSON.parse(info.request.response);
    //console.log("delete SAS", info.token)
    return info.token;

}



/////////// Delete any of the 4 file types from the blob ///////////////
async function deleteFromBlob(containerName, blobName) {

    let SAS = await getDeleteSAS(containerName, blobName);

    const blobServiceClient = new BlobServiceClient(baseURL + '?' + SAS);

    const containerClient = blobServiceClient.getContainerClient(containerName);
    try {
        const blobClient = containerClient.getBlobClient(blobName);
        const blockBlobClient = blobClient.getBlockBlobClient();
        const deleteBlobResponse = await blockBlobClient.delete();
        //console.log(deleteBlobResponse);
    }
    catch (error) {
        console.log('error deleting technique', error)
    }


}

function getCompVideo() {
    return compVideo
}


function getVideo() {
    return video;
}

function setVideo(newVideo) {
    video = newVideo;
}

async function loadVideo(type, id) {
 //   let technique = await getCompTechnique();
  
    var xhr = new XMLHttpRequest();
    let SAS = await getVideoSAS(id)
    const url = baseURL + "videos/" + id + '?' + SAS;
    axios.defaults.headers.common['Authorization'] = {}

    for (let i = 0; i < 20; i++) {
        try {
            xhr.open('GET', url, true);
            xhr.send();
            let blob = axios.create({
                baseURL: url
            });
            let response = await blob();
            if (xhr.readyState === 4) {
                if (type === 'comp') {
                    compVideo = xhr.response;
                }
                else {
                    video = xhr.response
                }
            }
        }
        catch {
            console.log('Failed ', i, 'times retrying')
        }
    }

}

/*
async function loadVideo(id) {
  
    let SAS = await getVideoSAS(id)
    const url = baseURL + "videos/" + technique.id + '?' + SAS;
    axios.defaults.headers.common['Authorization'] = {}

    console.log(type, url)
    xhr.open('GET', url, true);
    console.log(xhr.readyState, type)
    for (let i = 0; i < 20; i++) {
        try {
            xhr.open('GET', url, true);
            xhr.send();
            //xhr.addEventListener("readystatechange", processRequest, false);
           // console.log('url', url)
           // console.log(axios.defaults.headers.common)
            let blob = axios.create({
                baseURL: url
            });
           // console.log(blob.defaults.headers.common)
            let response = await blob();
            if (xhr.readyState === 4) {
               // console.log(type, xhr.response)
                return xhr.response;
            }
        }
        catch {
            console.log('Failed ', i , 'times retrying')
        }
    }
   
 
}

*/
function processRequest(e) {
  //  if (xhr.readyState === 4) {
       // console.log(xhr)
       // console.log(xhr.response)
 //   }
}


async function getTechniqueFromBlob(techniqueId) {
    var xhr = new XMLHttpRequest();
    let SAS = await getTechSAS(techniqueId);
    if (SAS === 403) {
        return 403
    }
   
    axios.defaults.headers.common['Authorization'] = {}

    const url = baseURL + "techdata/" + techniqueId + '?' + SAS;
    xhr.open('GET', url, true);
    xhr.send();
    xhr.addEventListener("readystatechange", processRequest, false);
    let blob = axios.create({
        baseURL: url
    });

    let response = await blob();
    return response.data;  

}

/*
function getBlobConnection() {
    return {baseURL, SAS}
}
*/



export {writeTechniqueToBlob, getTechniqueFromBlob , writeVideoToBlob, getVideo, getCompVideo, loadVideo, setVideo, getImage, uploadImage, deleteFromBlob}


  
     
     
     
     
     
     
     
