import React from 'react';
import HeaderSection from '../HeaderSection';
import { getTechniquesForUser, getTechniqueFromGraphDb} from '../../data';
import LoadButton from '../buttons/LoadButton';
import CompareButton from '../buttons/CompareButton';
import PrivacySelector from '../PrivacySelector';
import FavoriteButton from '../buttons/FavoriteButton';
import { loadFavoritesForUser } from '../../favoriteInfo';
import AdminButton from '../buttons/AdminButton';
import { getHRTechType } from '../../techTypes';



class MyTechTable extends React.Component {


  constructor(props) {
        super(props)
      this.state = {setView: props.setView, updateTechnique: props.updateTechnique, updateComparisonTechnique: props.updateComparisonTechnique, techTable: [], currentId: props.currentId, noTech: false} 

      
    }

    componentDidMount = async () => { 
       await this.updateTechniqueTable()
       loadFavoritesForUser()
    }

    async updateTechniqueTable() {
        let techTable = [];
        let currentTech = {};
        let userTechniques = await getTechniquesForUser(false);
        if (userTechniques._items.length === 0) {
            this.setState({ noTech: true })
        }
        else {
            let humanDate = '';
            let humanType = '';
            for (var i = 0; i < userTechniques._items.length; i++) {
                try {
                    currentTech = await getTechniqueFromGraphDb(userTechniques._items[i].inV);
                    humanDate = this.getHumanReadableDate(currentTech.timeStamp)
                    humanType = getHRTechType(currentTech.techType);
                    techTable.push(
                        <tr key={i}>
                            <td data-label="Type">{humanType}</td>
                            <td data-label="Date">{humanDate}</td>
                            <td data-label="Notes">{currentTech.notes}</td>
                            <td data-label="Score">{currentTech.calculatedScore}</td>
                            <td data-label="Privacy"><PrivacySelector techniqueId={currentTech.id} privacy={currentTech.privacy} /></td>
                            <td data-label="Action"> <AdminButton id={userTechniques._items[i].inV} setView={this.state.setView} refresh={this.state.updateTechnique} /> <LoadButton key={this.state.currentId} currentId={this.state.currentId} techniqueId={userTechniques._items[i].inV} refresh={this.state.updateTechnique} /> <CompareButton techniqueId={userTechniques._items[i].inV} update={this.state.updateComparisonTechnique} /> <FavoriteButton id={userTechniques._items[i].inV} /></td>

                        </tr>
                    );
                }
                catch {
                    console.log('could not get tech')
                }

            }
            this.setState({ techTable: techTable })
        }
    }


    getHumanReadableDate(time) {
        time = parseInt(time, 10);
        let timestamp = new Date(time *1000);
        var date = timestamp.getFullYear() + '-' + (timestamp.getMonth() + 1) + '-' + timestamp.getDate();
        time = timestamp.getHours() + ":" + timestamp.getMinutes() + ":" + timestamp.getSeconds();
        return (date);
    }


    render() {
       console.log(this.state.currentId)
        return (
            <div className="ui grid">
                <HeaderSection color={'rgba(64, 160, 255, 1)'} title='My Techniques' />
                    <table className="ui blue striped unstackable table">
                        <thead>
                            <tr>
                                <th scope="col" >Type</th>
                                <th scope="col" >Date</th>
                                <th scope="col" >Notes</th>
                                <th scope="col" >Score</th>
                                <th scope="col" >Privacy</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.techTable.length !== 0 && this.state.techTable}
                        </tbody>
                    </table>
                {this.state.techTable.length === 0 && this.state.noTech === false && <div className='sixteen wide centered column'> <img src='images/loading.gif' /></div>}
                {this.state.noTech === true && <div className='sixteen wide column'>
                    <div className='ui raised segment'>
                        <p>You don't yet have any techniques. Record and upload one, ask someone else to transfer, or analyze public techniques.</p>
                    </div>
                </div>}
        </div>
        );
    }
}

export default MyTechTable;


