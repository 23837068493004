import React from 'react';
import HeaderSection from '../HeaderSection';
import {getTechnique,  isUserOwner} from '../../data';
import TechniqueAdder from '../TechniqueAdder';
import TechniqueOptions from '../TechniqueOptions';
import { getCompTechnique, unloadTechnique } from '../../compData';
import FavoriteButton from '../buttons/FavoriteButton';
import { loadFavoritesForUser } from '../../favoriteInfo';
import AdminButton from '../buttons/AdminButton';
import { getHRTechType } from '../../techTypes';
import { getCurrentUser } from '../../userInfo';
import MyTechTable from '../tables/MyTechTable';
import PublicTechTable from '../tables/PublicTechTable';
import TechniqueUploader from '../uploaders/TechniqueUploader';




class TechniquesView extends React.Component {


  constructor(props) {
        super(props)
      this.state = { toggle: true, uploadStatus: 0, newTech: false, view:'publicTechniques', compLoaded: false , adminView: false, owner: false,  canUpload: false, setModule: props.setModule} 

      // view = 'admin', 'newTech', 'publicTechniques' , 'myTechniques'
      //upload status 0 = cannot upload, 1 = upload JSON, 2 = upload video. 3 = upload to database.
      
    }

    componentDidMount = async () => {
        //Don't let non - logged in users upload. 
        let currentUser = getCurrentUser();
      //  console.log(currentUser.userId)
        if (currentUser.userId != 0) {
            this.setState({ canUpload: true, view: 'myTechniques' })
        }
       
        this.updateTechnique()
        this.updateComparisonTechnique()
        this.updatePublicTechnique()
        loadFavoritesForUser()
      
    }

    updateTechnique = () => {
        var self = this;
        let technique = getTechnique();
        let owner = isUserOwner();
        let timeStamp = this.getHumanReadableDate(technique.timeStamp);
        let humanType = getHRTechType(technique.techniqueType);
        self.setState({ currentType: humanType, currentTimeStamp: timeStamp, currentScore: technique.calculatedScore, notes: technique.notes, currentId: technique.id , owner});
       
    }

    updatePublicTechnique = () => {
        var self = this;
        let technique = getTechnique();
        let timeStamp = this.getHumanReadableDate(technique.timeStamp);
        let humanType = getHRTechType(technique.techniqueType);
        self.setState({ currentType: humanType, currentTimeStamp: timeStamp, currentScore: technique.calculatedScore, currentId: technique.id });
       // self.updatePublicTechniqueTable();

    }

    updateComparisonTechnique = async () => {
        var self = this;
        let technique = await getCompTechnique();
        if (technique.id !== 0) {
            let timeStamp = this.getHumanReadableDate(technique.timeStamp);
            let humanType = getHRTechType(technique.techniqueType);
            self.setState({ compType: humanType, compTimeStamp: timeStamp, compNotes: technique.notes, compScore: technique.calculatedScore, compId: technique.id, compLoaded: true });
        }
      

    }

   
    getHumanReadableDate(time) {
        time = parseInt(time, 10);
        let timestamp = new Date(time *1000);
        var date = timestamp.getFullYear() + '-' + (timestamp.getMonth() + 1) + '-' + timestamp.getDate();
        time = timestamp.getHours() + ":" + timestamp.getMinutes() + ":" + timestamp.getSeconds();
        return (date);
    }


    

    unload = async () => {
        let self = this;
        await unloadTechnique();
        self.setState({ compType: 'None', compTimeStamp: '', compScore: '', compId: '' });
    }



    setView = (view) => {
        this.setState({view})
    }


    render() {
       console.log(this.state.currentId)

        return (

   
            <div className="ui grid">
                <div className='sixteen wide centered column'>
                
                     <div className="ui raised inverted link segment">
                        <p>This module gives you access to your techqniues as well as public techniques, while the groups module enables loading techniques from your groups.  </p>
                        {this.state.canUpload && <p>If you have recorded a technique with our utilities and the Azure Kinect, <a href='/#!' onClick={() => this.setState({ 'view': 'upload' })}> upload it. </a>
                            Advanced users can also <a href='#!' onClick={() => this.setView('newTech')}> define custom technique types </a> </p>}
                  
                        <p style={{'color': 'white'}}>Chooose a technique to load and optionally choose another one to compare, and then click the analyze button to view details on the analyze page.</p>
                        <table className="ui very basic inverted table">
                            <tbody>
                                <tr>
                                    <td>Load Main Technique<i className="ui green cloud download icon" /></td>
                                    <td>Load for Comparison<i className='ui purple chart line icon' /></td>
                                    <td>Toggle Favorite Status <i className="red heart icon" /></td>
                                    <td>Load Admin Panel <i className="edit outline icon" /></td>
                                </tr>
                            </tbody>
                        </table>
                        
                        {this.state.view === 'myTechniques' && <span style={{ 'paddingBottom': 100 }}> <button className='ui inverted tiny basic green button' onClick={() => this.setView('publicTechniques')}>View Public Techniques </button> </span>}
                        {this.state.view === 'publicTechniques' && <span style={{ 'paddingBottom': 100 }}> <button className='ui inverted tiny basic blue button' onClick={() => this.setView('myTechniques')}>View My Techniques </button> </span>}        
                         

                       

                    </div>
                     
             
                {this.state.view === 'upload' && <TechniqueUploader setView={this.setView} updateTechnique={this.updateTechnique}/>}
                    {this.state.view == 'admin' && <TechniqueOptions setView={this.setView} update={this.updateTechnique} updateTable={this.updateTechniqueTable} />}
                </div>
                <div className = 'sixteen wide column' >
                    {this.state.view !== 'admin' && this.state.view !== 'newTech' && <div className="ui grid">  <HeaderSection title="Currently Loaded Technique" color='rgba(231, 76, 50, 1)' />
                    <table className="ui blue striped unstackable table">
                        <thead>
                            <tr>
                                <th scope="col" >Type</th>
                                <th scope="col" >Date</th>
                                <th scope="col" >Notes</th>
                                <th scope="col" >Score</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key='currently loaded'>
                                <td data-label="Type">{this.state.currentType}</td>
                                <td data-label="Date">{this.state.currentTimeStamp}</td>
                                <td data-label="Notes">{this.state.notes}</td>
                                <td data-label="Score">{this.state.currentScore}</td>
                                    <td data-label="Action"><button className='ui fliud button' onClick={() => this.state.setModule('Dashboard')}>Analyze</button></td>

                            </tr>

                            {this.state.compLoaded && <tr key='comparison'>
                                <td data-label="Type">{this.state.compType}</td>
                                <td data-label="Date">{this.state.compTimeStamp}</td>
                                <td data-label="Notes">{this.state.compNotes}</td>
                                <td data-label="Score">{this.state.compScore}</td>
                                <td data-label="Action"><button className='ui inverted purple button' onClick={this.unload}>Unload Comparison</button> </td>

                            </tr>}
                        </tbody>
                    </table>
                    </div>}
                    {(this.state.view === 'myTechniques' || this.state.view == 'publicTechniques') && <div> <div className='ui divider' /> <div className='ui divider' /> </div> }
                  
                    {this.state.view === 'myTechniques' && <MyTechTable currentId={this.state.currentId} updateTechnique={this.updateTechnique} updateComparisonTechnique={this.updateComparisonTechnique} loadAdminWindow={this.loadAdminWindow} setView={this.setView}/>}

                    {this.state.view === 'publicTechniques' && <PublicTechTable updateTechnique={this.updateTechnique} updateComparisonTechnique={this.updateComparisonTechnique} loadAdminWindow={this.loadAdminWindow} setView={this.setView} />}
              
                   
                    {this.state.view === 'newTech' && <div> newtech  <TechniqueAdder setView={this.setView} /> </div>}
                    </div>
                </div>
              

        );
    }
}

export default TechniquesView;


//{this.state.owner && <AdminButton id={this.state.currentId} setView={this.setView} />} <FavoriteButton id={this.state.currentId} />