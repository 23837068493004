import React from 'react';
import { Chart } from '@bit/primefaces.primereact.chart';
import { getTechnique } from '../../data.js';
import { getCompTechnique } from '../../compData.js';

class ExtensionChart extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            data: [], options: [], view: 'leftFoot', charts: [], counter: 0, comparison: props.comparison, fixedHeight: true
        }
    }

    componentDidMount() {

        var self = this;
        let data = {
            labels: [],
            datasets: [
                {
                    label: 'First Dataset',
                    data: [],
                    fill: true,
                    backgroundColor: [
                        'rgba(231, 76, 50, 0.2)'
                    ],
                    borderColor: [
                        'rgba(231, 76, 50, 1)'
                    ],
                    borderWidth: 1
                },
            ]
        };
        let options = {
            title: {

            },

        };

        let technique = getTechnique();
        let compTechnique = getCompTechnique();
        //Initialize with right foot if as a default. 
        if (this.state.counter === 0) { this.updateGraph('rightKneeExt', technique.rightKneeExt, compTechnique.rightKneeExt); }

        self.setState({ data, options });
    }

    handleFixedHeightChange = async (event) => {
        event.persist()
        let options = {};
        if (this.state.fixedHeight) {
            options = { title: {} }
            this.setState({ options, fixedHeight: false });
        }
        else {
            options = {
                title: {},
                scales: {
                    yAxes: [{
                        ticks: {
                            min: 0,
                            max: 2000
                        }
                    }]
                }
            };
            this.setState({ options, fixedHeight: true });
        }

        this.updateGraph(this.state.view, this.state.positions, this.state.compPositions);
    }

    async updateGraph(view, positions, compPositions) {

        var self = this;

        let data2 = {
            labels: [],
            datasets: [
                {
                    label: 'First Dataset',
                    data: [],
                    fill: true,
                    backgroundColor: ['rgba(75, 192, 192, 0.2)'],
                    borderColor: ['rgba(75, 192, 192, 1)'],
                    borderWidth: 1
                },
                {
                    label: 'Comparison Dataset',
                    data: [],
                    fill: true,
                    backgroundColor: ['rgba(128, 128, 128, 0.2)'],
                    borderColor: ['rgba(128, 128, 128, 1)'],
                    borderWidth: 1
                },
            ]
        };
        let labels = [];
        let heightData = [];
        let compHeightData = [];
        for (var k = 0; k < compPositions.length - 1; k++) {
            compHeightData[k] = compPositions[k]
        }

        for (var j = 0; j < positions.length - 1; j++) {
            labels[j] = j;
            heightData[j] = positions[j]
        }


        data2.labels = labels;
        data2.datasets[0].data = heightData; //positions
        data2.datasets[0].label = view;

        data2.datasets[1].data = compHeightData; //positions
        data2.datasets[1].label = 'Comparison';

        let charts = self.state.charts;
        let i = self.state.counter + 1;
        if (charts.length !== 0) { charts.pop(); }
        charts.push(<div style={{ width: 375, height: 375 }}> <Chart type='line' key={i} data={data2} options={this.state.options} height={350} width={350} /> </div>);
        self.setState({ charts: charts, counter: i, view: view, positions: positions, compPositions: compPositions });
    }




    handleChange = async (event) => {
        event.persist()
        let compTech = await getCompTechnique();
        let technique = getTechnique();

        switch (event.target.value) {
            case 'leftKneeExt': this.updateGraph('leftKneeExt', technique.leftKneeExt, compTech.leftKneeExt); break;
            case 'rightKneeExt': this.updateGraph('rightKneeExt', technique.rightKneeExt, compTech.rightKneeExt); break;
            case 'leftElbowExt': this.updateGraph('leftElbowExt', technique.leftElbowExt, compTech.leftElbowExt); break;
            case 'rightElbowExt': this.updateGraph('rightElbowExt', technique.rightElbowExt, compTech.rightElbowExt); break;
            default: this.updateGraph('leftKneeExt', technique.leftKneeExt, compTech.leftKneeExt); break;
        }
    }

    render() {
        return (
            <div className='ui grid'>
                <div className='eight wide centered column'>
                    <h3 style={{ color: 'rgba(75, 192, 192, 1)' }}>Extension [deg]</h3>
                </div>
                <div className='row'>
                    <div className='eight wide column'>
                    </div>
                    <div className='five wide column'>
                        <select style={{ cursor: 'pointer' }} onChange={this.handleChange} >
                            <option key={1} value={'leftKneeExt'}> Left Knee </option>
                            <option key={2} value={'rightKneeExt'}> Right Knee </option>
                            <option key={3} value={'leftElbowExt'}> Left Elbow </option>
                            <option key={4} value={'rightElbowExt'}> Right Elbow </option>
                        </select>
                    </div>
                </div>

                {this.state.charts[0]}

            </div>


        );
    }

}



export default ExtensionChart;

