import React from 'react';
import { addDojang, getDojangById, changeDojang } from '../dojangInfo';
import { getCurrentUser } from '../userInfo';
import ImageUpload from './uploaders/ImageUpload';



class DojangAdder extends React.Component {


    //      <input type="submit" value="Submit" / >
    constructor(props) {
        super(props)
        this.state = {name:'', location: '', website: '', description: '', search: '', status: 'none'}
    }


    handleNameChange = (event) => {
        this.setState({ name: event.target.value });
    }

    handleLocationChange = (event) => {
        this.setState({ location: event.target.value });
    }

    handleDescriptionChange = (event) => {
        this.setState({ description: event.target.value });
    }

    handleWebsiteChange = (event) => {
        this.setState({ website: event.target.value });
    }

    handleSearchChange = (event) => {
        this.setState({ search: event.target.value });
    }


    handleSearchSubmit = async (event) => {
        event.preventDefault();
        let dojang = await getDojangById(this.state.search);
        console.log(dojang)
        changeDojang(dojang.id);
        this.setState({dojang:dojang, status: 'changed'})        
    }

    handleSubmit = async (event) => {


        event.preventDefault();
        let currentUser = await getCurrentUser();
        let dojang = {
            name: this.state.name,
            website: this.state.website,
            description: this.state.description,
            location: this.state.location,
            owner: currentUser.userId
        }
        addDojang(dojang);
        this.setState({status: 'added'})

    }

    render() {
        return (<div>
            {this.state.status === 'added'  && <div className="ui positive message">
                < i class="close icon" onClick={() => this.props.closeEditDojang()}></i>
            <div class="header">
                Your dojang has been added! Please refresh to see changes. 
                 </div>
            </div >}
            {this.state.status === 'changed' && <div className="ui positive message">
                < i className="close icon" onClick={() => this.props.closeEditDojang()}></i>
                <div className="header">
                    Your dojang has been changed! Please refresh to see changes. 
                 </div>
            </div >}
            {this.state.status === 'none' && <div className="ui relaxed stackable grid">

                <div className="ui inverted segment">
                    <div className="ui two column very relaxed grid">
                        <div className="column">

                            <form onSubmit={this.handleSearchSubmit}>
                                <p>Enter the ID code of an existing dojang to add it to your profile.</p>
                                <div className="ui input"> <input type="text" value={this.state.search} onChange={this.handleSearchChange} placeholder="Search..." /></div>
                                <div className='ui divider' />
                                <button className="ui inverted green button" type="submit">Submit</button>
                                <div className='ui divider' />
                                <button className='ui inverted red button' onClick={() => this.props.closeEditDojang()}> Cancel Dojang Change</button>
                            </form>

                        </div>
                        <div className="column">
                            <form onSubmit={this.handleSubmit}>
                                <p>Add a new school to My-AI-Coach. You will be the owner of the record. </p>
                                <div className="ui labeled fluid input"><div className="ui label">School Name: </div><input type="text" value={this.state.name} onChange={this.handleNameChange} /></div>
                                <div className='ui divider' />
                                <div className="ui labeled fluid input"><div className="ui label">Location: </div><input type="text" placeholder="City, Country" value={this.state.location} onChange={this.handleLocationChange} /></div>
                                <div className='ui divider' />
                                <div className="ui labeled fluid input"><div className="ui label">Description: </div><input type="text" value={this.state.description} onChange={this.handleDescriptionChange} /></div>
                                <div className='ui divider' />
                                <div className="ui labeled fluid input"><div className="ui label">Website: http://</div><input type="text" placeholder="mysite.com" value={this.state.website} onChange={this.handleWebsiteChange} /></div>
                                <div className='ui divider' />
                                <ImageUpload imageType={'dojang'} />
                                <div className='ui divider' />
                                <button className="ui inverted green button" type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                    <div className="ui vertical divider">
                        OR
  </div>
                </div>
            </div>
            }
            </div>
        );
    }


}
export default DojangAdder;
