import axios from 'axios'
import { getCurrentUser } from './userInfo';
import { getToken } from './token';
import { functionURL } from './config/default';


async function countDojangs() {
    let token = getToken();
    let dojangNumbers = await axios.get(functionURL + `?type=getDojangNumbers`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }}, {});
    dojangNumbers = JSON.parse(dojangNumbers.request.response);
    console.log('Total Number of Dojangs: ', dojangNumbers._items[0]);
    return dojangNumbers._items[0] + 1;
}

async function addDojang(dojang) {
    let token = getToken();
    let newId = await countDojangs();
    newId = 'd' + newId;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.post(functionURL, {
     }, {
        params: {
            type: 'addDojang',
            name: dojang.name,
            id: newId,
            website: dojang.website,
            description: dojang.description,
            location: dojang.location,
            logo: 'none',
            userId: dojang.owner
        }
})
    .then(function (response) {
        console.log(response);
    })
    .catch(function (error) {
        console.log(error);
    });
}



async function getUserDojang() {
    //returns 0 if the user isn't currently associated with a dojang
    //otherwise returns information about the user's dojang.  
    let user = getCurrentUser();
    let token = getToken();
    try {
        let info = await axios.get(functionURL + `?id=${user.userId}&type=getDojangForStudent`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }, {});
        info = JSON.parse(info.request.response);
        let memberNumbers = info.memberNumbers;
        let techniqueNumbers = info.techniqueNumbers;
        info = info.results;
        let dojang = currentDojang;

        if (info._items.length === 0) {
            console.log("User doesn't currently have a dojang");
            dojang.id = 0;
            return dojang;
        }
        else {

            dojang.name = info._items[0].properties.name[0].value;
            dojang.id = info._items[0].id;
            dojang.website = info._items[0].properties.website[0].value;
            dojang.description = info._items[0].properties.description[0].value;
            dojang.location = info._items[0].properties.location[0].value;
            dojang.memberNumbers = memberNumbers._items[0]
            dojang.techniqueNumbers = techniqueNumbers._items[0];
            console.log(dojang)
            return dojang;
        }
    }
    catch {
        let dojang = currentDojang;
        dojang.id = 0;
        return dojang;
    }
}

async function getDojangById(id) {
  
    let token = getToken();
    
    let info = await axios.get(functionURL + `?id=${id}&type=getDojang`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }}, {});
    info = JSON.parse(info.request.response);
    let dojang = currentDojang;

    if (info._items.length === 0) {
        alert("This Dojang Id does not exist. Please try another.")
        dojang.id = 0;
        return dojang;
    }
    else {

        dojang.name = info._items[0].properties.name[0].value;
        dojang.id = info._items[0].id;
        dojang.website = info._items[0].properties.website[0].value;
        dojang.description = info._items[0].properties.description[0].value;
        dojang.location = info._items[0].properties.location[0].value;
        return dojang;
      
    }
}

async function changeDojang(newId) {
    let token = getToken();
    //TODO::: handle case of when owner is trying to change. 
    let currentUser = await getCurrentUser();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.post(functionURL, {
      }, {
        params: {
            type: 'changeUserDojang',
            dojangId: newId,
            userId: currentUser.userId
        }
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
}


let currentDojang = {
    name: 'none', 
    id: 'none',
    website: 'none',
    description: '',
    memberNumbers: 'NA',
    location: '',
	logo: 'none' ,
}

export {addDojang, getUserDojang, getDojangById, changeDojang}