import React from 'react';
import PositionChart from '../charts/PositionChart';
import SpeedChart from '../charts/SpeedChart';
import AccChart from '../charts/AccChart';
import ExtensionChart from '../charts/ExtensionChart';
import RotationChart from '../charts/RotationChart';
import InfoCard from '../InfoCard';
import InfoCardKinect from '../InfoCardKinect';
import SpiderChart from  '../charts/SpiderChart';
import { getVideo, getCompVideo } from '../../azureBlob';
import TechniqueSuggestions from '../TechniqueSuggestions';
import {  getTechnique, getTechniqueFromDb } from '../../data';
import { getCompTechnique } from '../../compData';
import ThreeScene from '../ThreeScene';
import ManualScorer from '../ManualScorer';
import NavBar from '../NavBar';
import { getDirectTechnique, setDirectTechnique } from '../../userInfo';

class DashboardView extends React.Component {

    //    <VideoPlayer {...videoJsOptions} />
  constructor(props) {
        super(props)
        this.state = {comparisonLoaded: false, speedDirection: false, fixedHeight: true, unauthorized: false, copied: false,  b64Video: ''}
    }

    componentDidMount = async (props) => {
        let self = this;
        let comparisonLoaded;
        //Check the case where they entered a technique ID directly. 
        let loadGraphs = false;
      
        if (self.props.id) {
            console.log(self.props)
            //Need to make sure this is only called once, by setting variable in external file. 
            if (!getDirectTechnique()) {
                setDirectTechnique(true);
                let didSucceed = await getTechniqueFromDb(self.props.id)
                if (didSucceed === 403) {
                    this.setState({ unauthorized: true })

                } else {
                    loadGraphs = true;
                }


            }
        }
        else {
            loadGraphs = true;
        }
       
        this.getVideofromDb();

        let technique = await getTechnique();
        await this.setState({ technique })
        let compTechnique = await getCompTechnique();
     
        if (compTechnique.id == 0) {
            comparisonLoaded = false;
        }
        else {
        
            comparisonLoaded = true;
            this.getCompVideofromDb()
        }
        this.setState({compTechnique, comparisonLoaded,loadGraphs,  loaded: true, comparisonFocus: false });
        
    }

    getVideofromDb = async () => {
        let technique = await getTechnique();
        let b64Video = '/data/video/N5.mp4'
        if (technique.id !== 'c2-1563330158') {
           b64Video = getVideo();
        }
        this.setState({b64Video: b64Video})
    }

    getCompVideofromDb = async () => {
        let b64Video =  getCompVideo();
        this.setState({ b64CompVideo: b64Video })

    }

    handleComparisonChange = (event) => {
        if (this.state.comparison) { this.setState({ comparison: false }); }
        else { this.setState({ comparison: true }); }
        
    }

    handleSpeedDirectionChange = (event) => {
        if (this.state.speedDirection) { this.setState({ speedDirection: false }); }
        else { this.setState({ speedDirection: true }); }
    }


    handleFocusChange = async (event) => {
        let result = event.target.value;
      
        if (result === 'comp') {
            await this.setState({comparisonFocus: true})
        }
        if (result === 'main') {
            await this.setState({comparisonFocus: false })
        }

        console.log(this.state)
    }

    copyId = () => {
        navigator.clipboard.writeText('https://www.my-ai-coach.com/t-' + this.state.technique.id) 
        this.setState({copied: true})

    }

    render() {

        if (this.state.unauthorized == true) {
            return (<div className='ui raised segment'>
                <p> You tried to access a private technique. Please go to the <a href='https://www.my-ai.coach.com'>homepage </a> or log in.</p>
                <NavBar />

            </div>)
        }
        else {

            return (

                <div className="ui stackable grid container">

                    <table className="ui basic table" >
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td valign="top">
                                    {this.state.comparisonLoaded &&
                                        <select style={{ cursor: 'pointer' }} onChange={this.handleFocusChange}>
                                            <option value={'main'}>Show Main</option>
                                            <option value={'comp'}>Show Comparison</option>
                                        </select>}
                                    <span style={{ 'paddingLeft': 10, cursor: 'pointer' }}>
                                        {this.state.copied === false && <button onClick={this.copyId}>Copy Share Link</button>}
                                        {this.state.copied === true && <span style={{ 'color': 'white' }}>Link copied!</span>}
                                    </span>
                                {this.state.comparisonFocus === true && <InfoCard isComparison={true} />}
                                {this.state.comparisonFocus === false && <InfoCard isComparison={false} />}
                            </td>
                                <td valign="top">
                                    <InfoCardKinect />
                                    {this.state.b64Video === '' && <img src='images/loading.gif' />}
                                    
                                {this.state.comparisonFocus === false && <video src={this.state.b64Video} controls width={350} />}
                                        {this.state.comparisonFocus === true && <video src={this.state.b64CompVideo} controls width={350} />}
                          
                                <div>
                                </div>
                                <div>
                              
                                 
                                    </div>
                            </td>
                                <td valign="top">   <SpiderChart />  </td>
                            </tr>
                            <tr>
                                <td style={{ "text-align":"center"}}> {this.state.loadGraphs && <PositionChart />}</td>
                                <td> {this.state.loadGraphs && < SpeedChart />} </td>
                                <td> {this.state.loadGraphs && < AccChart /> }</td>
                            </tr>
                            <tr>
                                <td>{this.state.loadGraphs && <ExtensionChart />}</td>
                                <td>{this.state.loadGraphs && <RotationChart />}</td>
                                <td>{this.state.loadGraphs && <ThreeScene />}</td>
                            </tr>
                        </tbody>
                    </table>
                    <TechniqueSuggestions />
                </div>
            );
        }
    }
}

export default DashboardView;
