
import axios from 'axios'
import { getTechniqueFromBlob, loadVideo } from './azureBlob.js';
import { getToken } from './token.js';
import { functionURL } from './config/default.js';

//main data structure for an individual technique. This will be stored on the Azure Cosmos Gremlin db as a node.

let nullCompTechnique = {
    //raw point arrays for each joint - note, not sure which joints will actually be exposed in API
    leftFootPos: [],
    rightFootPos: [],
    leftAnklePos: [],
    rightAnklePos: [],
    leftKneePos: [],
    rightKneePos: [],
    leftHipPos: [],
    rightHipPos: [],
    leftShoulderPos: [],
    rightShoulderPos: [],
    leftElbowPos: [],
    rightElbowPos: [],
    leftWristPos: [],
    rightWristPos: [],
    pelvisPos:[],
    headPos: [],

    leftAnkleSpeed: [],
    rightAnkleSpeed: [],
    leftFootSpeed: [],
    rightFootSpeed: [],
    leftKneeSpeed: [],
    rightKneeSpeed: [],
    leftHipSpeed: [],
    rightHipSpeed: [],
    leftShoulderSpeed: [],
    rightShoulderSpeed: [],
    leftElbowSpeed: [],
    rightElbowSpeed: [],
    leftWristSpeed: [],
    rightWristSpeed: [],
    pelvisSpeed: [],
    headSpeed: [],

    leftFootAcc: [],
    rightFootAcc: [],
    letfAnkleAcc: [],
    rightAnkleAcc: [],
    leftKneeAcc: [],
    rightKneeAcc: [],
    leftHipAcc: [],
    rightHipAcc: [],
    leftShoulderAcc: [],
    rightShoulderAcc: [],
    leftElbowAcc: [],
    rightElbowAcc: [],
    leftWristAcc: [],
    rightWristAcc: [],
    pelvisAcc: [],
    headAcc: [],

    leftKneeExt: [],
    rightKneeExt: [],

    leftElbowExt: [],
    rightElbowExt: [],

    timeStamps: [],
    frameTimes: [],

    //basic info about the technique
    timeStamp: 0,
    id: 0,
    techniqueType: 'Default',
    notes: 'none',
    ownerId: 0,
    manualScore: 0,
    calculatedScore: 0,
    

    //length of the technique in frames
    length: 45,

    //arrays of userId and groupIds of who has permission to view the technique. Some flag here will be set for publics. 
    privacy: '0 - Private',
    //privacy of 0 means private
    //privacy of 1 means public unlisted
    //privacy of 2 means public leaderboard



    //I think it would be cool for people to see like how many other people loaded or favorited their techniques. 
    loads: 0,
    favorites: 0,

    //It might be useful to store some highlights of the technique here, so users don't have to recalculate everytime they open
    //However, this is also getting pretty big, so I think just one highlight may be good enough to help them find it in a table. 
    maxSpeed: 0,
    maxFootHeight: 0,
    calculatedFoot: "None",

}


let compTechnique = nullCompTechnique;

function getCompTechnique() {
    return compTechnique;
}


//Unload the comparison technique. 
function unloadTechnique() {
     compTechnique = nullCompTechnique;
}

export function isCompTechnique(id) {
    return (id === compTechnique.id)
}

async function getCompTechniqueFromGraphDb(techniqueId) {
    let tech = {};
     //Loads the details of a technique.
    let token = getToken();
    let info = await axios.get(functionURL + `?id=${techniqueId}&type=getRecord`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }}, {});
    info = JSON.parse(info.request.response);
    console.log(techniqueId, compTechnique)
    tech.techType = info._items[0].properties.techType[0].value;
    tech.manualScore = info._items[0].properties.manualScore[0].value;
    tech.calculatedScore = info._items[0].properties.calculatedScore[0].value;
    tech.timeStamp = info._items[0].properties.timeStamp[0].value;
    tech.privacy = info._items[0].properties.privacy[0].value;
    tech.notes = info._items[0].properties.notes[0].value;
    tech.id = techniqueId;
   
    return tech;
}


async function loadCompTechnique(techniqueId) {

    //first get from blob storage
    compTechnique = await getTechniqueFromBlob(techniqueId)
    //then update with graph db
    let gDbTech = await getCompTechniqueFromGraphDb(techniqueId);
    compTechnique.techType = gDbTech.techType;
    compTechnique.manualScore = gDbTech.manualScore;
    compTechnique.calculatedScore = gDbTech.calculatedScore;
    compTechnique.timeStamp = gDbTech.timeStamp;
    compTechnique.privacy = gDbTech.privacy;
    compTechnique.notes = gDbTech.notes;
    console.log('Comparison technique loaded', compTechnique)

    //Finally, load the video in local memory
    await loadVideo('comp', techniqueId)
}



export { getCompTechnique,  loadCompTechnique, unloadTechnique};