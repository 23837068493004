import React from 'react';


class MinMaxer extends React.Component {


    constructor(props) {
        super(props)
        this.state = {submit: props.submit, button: true}
    }


    handleFootSpeedChange = (event) => {
        if (this.state.footSpeed) { this.setState({ footSpeed: false }); }
        else { this.setState({ footSpeed: true }); }
    }

    handleFootHeightChange = (event) => {
        if (this.state.footHeight) { this.setState({ footHeight: false }); }
        else { this.setState({ footHeight: true }); }
    }

    handleFootSpeedChange = (event) => {
        if (this.state.footSpeed) { this.setState({ footSpeed: false }); }
        else { this.setState({ footSpeed: true }); }
    }

    handleHandSpeedChange = (event) => {
        if (this.state.handSpeed) { this.setState({ handSpeed: false }); }
        else { this.setState({ handSpeed: true }); }
    }
   
    handleHandHeightChange = (event) => {
        if (this.state.handheight) { this.setState({ handheight: false }); }
        else { this.setState({ handheight: true }); }
    }

    handleKneeExtensionAngleChange = (event) => {
        if (this.state.kneeExtensionAngle) { this.setState({ kneeExtensionAngle: false }); }
        else { this.setState({ kneeExtensionAngle: true }); }
    }

    handleKneeExtensionSpeedChange = (event) => {
        if (this.state.kneeExtensionSpeed) { this.setState({ kneeExtensionSpeed: false }); }
        else { this.setState({ kneeExtensionSpeed: true }); }
    }

    handleelbowExtensionAngleChange = (event) => {
        if (this.state.elbowExtensionAngle) { this.setState({ elbowExtensionAngle: false }); }
        else { this.setState({ elbowExtensionAngle: true }); }
    }

    handleelbowExtensionSpeedChange = (event) => {
        if (this.state.elbowExtensionSpeed) { this.setState({ elbowExtensionSpeed: false }); }
        else { this.setState({ elbowExtensionSpeed: true }); }
    }

    handleShoulderRotationSpeedChange = (event) => {
        if (this.state.shoulderRotationSpeed) { this.setState({ shoulderRotationSpeed: false }); }
        else { this.setState({ shoulderRotationSpeed: true }); }
    }

    handleHipRotationSpeedChange = (event) => {
        if (this.state.HipRotationSpeed) { this.setState({ HipRotationSpeed: false }); }
        else { this.setState({ HipRotationSpeed: true }); }
    }


    handleMaxSubmit = (event) => {
        event.preventDefault();
        let max = { footHeight: this.state.footHeight, footSpeed: this.state.footSpeed, handSpeed: this.state.handSpeed, handHeight: this.state.handHeight, kneeExtensionAngle: this.state.kneeExtensionAngle, kneeExtensionSpeed: this.state.kneeExtensionSpeed, elbowExtensionAngle: this.state.elbowExtensionAngle, elbowExtensionSpeed: this.state.elbowExtensionSpeed, shoulderRotationSpeed: this.state.shoulderRotationSpeed, hipRotationSpeed: this.state.hipRotationSpeed }
        this.setState({ button: false });
        this.state.submit(max);
    

    }

    handleMinSubmit = (event) => {
        event.preventDefault();
        let min = { footHeight: this.state.footHeight, footSpeed: this.state.footSpeed, handSpeed: this.state.handSpeed, handHeight: this.state.handHeight, kneeExtensionAngle: this.state.kneeExtensionAngle, kneeExtensionSpeed: this.state.kneeExtensionSpeed, elbowExtensionAngle: this.state.elbowExtensionAngle, elbowExtensionSpeed: this.state.elbowExtensionSpeed, shoulderRotationSpeed: this.state.shoulderRotationSpeed, hipRotationSpeed: this.state.hipRotationSpeed }
        this.setState({ button: false });
        this.state.submit(min);
       
    }

    render(props) {

        return (
          
            <div className = 'sixteen wide column'>
                <div className="ui segment">
                  
                     
                    <form onSubmit={this.handleSubmit}>
                        {this.props.max && <h3> Maximize Values</h3>}
                        {this.props.min && <h3> Minimize Values</h3>}

                        {this.props.max && <p><b>Check the boxes that it is important to maximize. </b> Athletes will see their how their techniques compare to average values.</p>}
                        {this.props.min && <p><b>Check the boxes that it is important to minimize. </b></p>}
                        <table className="ui unstackable table">
                            <thead>
                                </thead>
                            <tbody>
                                <tr>
                                    <td> <span> Foot Speed: </span><input type="checkbox" value={this.state.footSpeed} onChange={this.handleFootSpeedChange} /></td>
                                    <td> <span> Foot Height: </span><input type="checkbox" value={this.state.footHeight} onChange={this.handleFootHeightChange} /></td>
                                    <td>  <span> Hand Speed: </span><input type="checkbox" value={this.state.handSpeed} onChange={this.handleHandSpeedChange} /></td>
                                    <td>  <span>  Hand Height </span> <input type="checkbox" value={this.state.handHeight} onChange={this.handleHandHeightChange} /></td>
                                    <td>  <span>  Knee Extension Angle (180) </span><input type="checkbox" value={this.state.kneeExtensionAngle} onChange={this.handleKneeExtensionAngleChange} /></td>
                                </tr>
                                <tr>
                                    <td> <span> Knee Extension Speed: </span><input type="checkbox" value={this.state.kneeExtensionSpeed} onChange={this.handleKneeExtensionSpeedChange} /> </td>
                                    <td> <span> Elbow Extension Angle (180): </span><input type="checkbox" value={this.state.elbowExtensionAngle} onChange={this.handleElbowExtensionAngleChange} /></td>
                                    <td>  <span> Elbow Extension Speed: </span><input type="checkbox" value={this.state.elbowExtensionSpeed} onChange={this.handleElbowExtensionSpeedChange} /> </td>
                                    <td> <span> Shoulder Rotation Speed: </span><input type="checkbox" value={this.state.shoulderRotationSpeed} onChange={this.handleShoulderRotationSpeedChange} /> </td>
                                    <td> <span> Hip Rotation Speed: </span><input type="checkbox" value={this.state.hipRotationSpeed} onChange={this.handleHipRotationSpeedChange} /> </td>
                                </tr>
                            </tbody>
                        </table>

                        {(this.props.max && this.state.button) && <button className="ui inverted green button" type="submit" onClick={this.handleMaxSubmit}>Maximize 'em!</button>}
                        {(this.props.min && this.state.button) && <button className="ui inverted purple button" type="submit" onClick={this.handleMinSubmit}>Minimize 'em!</button>}
                            </form>
                        </div>
                
 </div>
                
        );
    }


}
export default MinMaxer;
