

import React from 'react';
import { loadCompTechnique } from '../../compData';

class CompareButton extends React.Component {

    constructor(props) {
        super(props)
        this.state = { techniqueId: props.techniqueId, status: 1, update: props.update }
    }

    componentDidMount() {
      
    }

    handleClick = async (e) => {
        e.persist()
        this.setState({status: 2})
        await loadCompTechnique(this.state.techniqueId)
        e.preventDefault();
        this.state.update();
        this.setState({ status: 1 })
    }

    //check if technique is currently loaded and gray it out. 
    render() {
        return (
            <>
         
                {this.state.status === 1 && <i className="ui big purple chart line icon" style={{ cursor: 'pointer' }} onClick={this.handleClick} />}
                {this.state.status === 2 && <span>Loading...</span>}
         
            </>

        );
    }

}

export default CompareButton;