import React from 'react';
import './App.css';
import HomeView from './components/views/HomeView';
import DashboardView from './components/views/DashboardView';
import TechniquesView from './components/views/TechniquesView';
import GroupsView from './components/views/GroupsView';
import AccountView from './components/views/AccountView';
import LeaderboardView from './components/views/LeaderboardView';
import LoginFunction from './components/LoginFunction';
import Profile from './components/Profile.js';
import { getDirectTechnique } from './userInfo';



class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {currentView: 'Home', mobile: false}
    }


    componentDidMount = async (props) =>  {
        let self = this;
        if (window.innerWidth < 750) {
            this.setState({mobile: true})
        }
        console.log('resolution', window.innerHeight, window.innerWidth)
        if (self.props.location.pathname.startsWith('/t-') && !getDirectTechnique()) {
            console.log(self.props, getDirectTechnique)
           await this.setState({ currentView: 'Dashboard', id: self.props.location.pathname.slice(3) })
        }
       
    }

    setModule = (view) => {
        this.setState({currentView: view})
    }
    
 
    render() {
       
        return (
            <div>
     

            <div className="ui container">
                <LoginFunction />
                <Profile />
                <div className="App">
            
                        {!this.state.mobile && <div className="ui six item stackable menu">
                            <a className="item" href="#" onClick={() => this.setState({ currentView: 'Home' })}> Home</a>
                            <a className="item" href="#" onClick={() => this.setState({ currentView: 'Techniques' })}>Techniques</a>
                            <a className="item" href="#" onClick={() => this.setState({ currentView: 'Dashboard' })}>Analyze</a>
                          
                            <a className="item" href="#" onClick={() => this.setState({ currentView: 'Groups' })}>Groups</a>
                            <a className="item" href="#" onClick={() => this.setState({ currentView: 'Account' })}>Account</a>
                            <a className="item" href="#" onClick={() => this.setState({ currentView: 'Leaderboard' })}>Leaderboard</a>


                        </div>}
                        {this.state.mobile && <div className='ui grid'>
                            <div className='sixteen wide column'>
<div class="ui compact left floated inverted menu">
                            <div class="ui simple dropdown item">
                                &#9776;
                                <div class="menu">
                                            <a className="item" href="#" onClick={() => this.setState({ currentView: 'Home' })}> Home</a>
                                            <a className="item" href="#" onClick={() => this.setState({ currentView: 'Techniques' })}>Techniques</a>
                                    <a className="item" href="#" onClick={() => this.setState({ currentView: 'Dashboard' })}>Analyze</a>
                                    <a className="item" href="#" onClick={() => this.setState({ currentView: 'Groups' })}>Groups</a>
                                    <a className="item" href="#" onClick={() => this.setState({ currentView: 'Account' })}>Account</a>
                                    <a className="item" href="#" onClick={() => this.setState({ currentView: 'Leaderboard' })}>Leaderboard</a>
                                </div>
                            </div>
                                </div>
                            </div>
                            </div>
                        }

                    <div className="ui divider"></div>
                  
                   
                    {this.state.currentView === 'Home' && <HomeView />}
                    {this.state.currentView === 'Dashboard' && <DashboardView id={this.state.id} />}
                        {this.state.currentView === 'Techniques' && <TechniquesView setModule={this.setModule}/>}
                        {this.state.currentView === 'Groups' && <GroupsView setModule={this.setModule}/>}
                    {this.state.currentView === 'Account' && <AccountView />}
                    {this.state.currentView === 'Leaderboard' && <LeaderboardView />}

                    </div>
             
               </div>
    </div>

        );
    }

}
/*
<BrowserRouter>
    <Switch>
        <Route path='/groups' component={GroupsView} />
        <Route path='/tech' exact={false} render={(props) => (<DashboardView {...props} />)} />
        <Route path='/account' render={(props) => (<AccountView {...props} />)} />
    </Switch>

</BrowserRouter>
*/


export default App;
