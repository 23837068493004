import { defaultTechnique, functionURL } from './config/default.js';
import axios from 'axios'
import { fillSpeedArray, fillAccArray, fillAngArray, fillRotArray , fillExtSpeedArray} from './ma-motion';
import { max, min, abs } from 'mathjs';
import { getCurrentUser } from './userInfo.js';
import { getTechniqueFromBlob, deleteFromBlob, loadCompVideo, loadVideo } from './azureBlob.js';
import { getToken } from './token.js';


//raw information about a point in space. 
function point(x, y, z) {
    this.x = x;
    this.y = y;
    this.z = z;


};

//main data structure for an individual technique. This will be stored on the Azure Cosmos Gremlin db as a node.

let technique = {
    //raw point arrays for each joint - note, not sure which joints will actually be exposed in API
    leftFootPos: [],
    rightFootPos: [],
    leftAnklePos: [],
    rightAnklePos: [],
    leftKneePos: [],
    rightKneePos: [],
    leftHipPos: [],
    rightHipPos: [],
    leftClaviclePos: [],
    rightClaviclePos: [],
    leftShoulderPos: [],
    rightShoulderPos: [],
    leftElbowPos: [],
    rightElbowPos: [],
    leftWristPos: [],
    rightWristPos: [],
    pelvisPos: [],
    headPos: [],
    spineChestPos: [],

    leftAnkleSpeed: [],
    rightAnkleSpeed: [],
    leftFootSpeed: [],
    rightFootSpeed: [],
    leftKneeSpeed: [],
    rightKneeSpeed: [],
    leftHipSpeed: [],
    rightHipSpeed: [],
    leftShoulderSpeed: [],
    rightShoulderSpeed: [],
    leftElbowSpeed: [],
    rightElbowSpeed: [],
    leftWristSpeed: [],
    rightWristSpeed: [],
    pelvisSpeed: [],
    headSpeed: [],

    leftFootAcc: [],
    rightFootAcc: [],
    leftAnkleAcc: [],
    rightAnkleAcc: [],
    leftKneeAcc: [],
    rightKneeAcc: [],
    leftHipAcc: [],
    rightHipAcc: [],
    leftShoulderAcc: [],
    rightShoulderAcc: [],
    leftElbowAcc: [],
    rightElbowAcc: [],
    leftWristAcc: [],
    rightWristAcc: [],
    pelvisAcc: [],
    headAcc: [],

    leftKneeExt: [],
    rightKneeExt: [],

    leftElbowExt: [],
    rightElbowExt: [],

    hipRot: [],
    shoulderRot: [],

    timeStamps: [],
    frameTimes: [],

    //basic info about the technique
    timeStamp: 0,
    id: 0,
    techniqueType: 'c2',
    notes: 'none',
    ownerId: 0,
    manualScore: 0,
    calculatedScore: 0,
    //length of the technique in frames
    length: 120,

    //arrays of userId and groupIds of who has permission to view the technique. 
    privacy: '0 - Private',
    //privacy of 0 means private
    //privacy of 1 means public unlisted
    //privacy of 2 means public leaderboard
    //I think it would be cool for people to see like how many other people loaded or favorited their techniques. 
    loads: 0,
    favorites: 0,

    //It might be useful to store some highlights of the technique here, so users don't have to recalculate everytime they open
    //However, this is also getting pretty big, so I think just one highlight may be good enough to help them find it in a table. 
    maxSpeed: 0,
    maxFootHeight: 0,
    calculatedFoot: "None",

}

/*function setTechniquePrivacy(privacyLevel) {
    technique.privacy = privacyLevel;
}
*/

function uploadTechniqueFromFile(newTechnique) {
    let defaultTechnique = newTechnique;
    technique.timeStamp = defaultTechnique.timeStamp;
    technique.notes = defaultTechnique.notes;
    let currentUser = getCurrentUser();
    technique.ownerId = currentUser.userId;
    technique.timeStamps = defaultTechnique.allTimestamps;
 
    //export technique length from converter and use in for loop here. 
    for (var i = 0; i <= 125; i += 1) {
        
        technique.leftFootPos[i] = new point(defaultTechnique.leftFoot_x[i], defaultTechnique.leftFoot_y[i], defaultTechnique.leftFoot_z[i]);
        technique.rightFootPos[i] = new point(defaultTechnique.rightFoot_x[i], defaultTechnique.rightFoot_y[i], defaultTechnique.rightFoot_z[i]);

        technique.leftAnklePos[i] = new point(defaultTechnique.leftAnkle_x[i], defaultTechnique.leftAnkle_y[i], defaultTechnique.leftAnkle_z[i]);
        technique.rightAnklePos[i] = new point(defaultTechnique.rightAnkle_x[i], defaultTechnique.rightAnkle_y[i], defaultTechnique.rightAnkle_z[i]);

        technique.leftKneePos[i] = new point(defaultTechnique.leftKnee_x[i], defaultTechnique.leftKnee_y[i], defaultTechnique.leftKnee_z[i]);
        technique.rightKneePos[i] = new point(defaultTechnique.rightKnee_x[i], defaultTechnique.rightKnee_y[i], defaultTechnique.rightKnee_z[i]);

        technique.pelvisPos[i] = new point(defaultTechnique.pelvis_x[i], defaultTechnique.pelvis_y[i], defaultTechnique.pelvis_z[i]);

        technique.leftWristPos[i] = new point(defaultTechnique.leftWrist_x[i], defaultTechnique.leftWrist_y[i], defaultTechnique.leftWrist_z[i]);
        technique.rightWristPos[i] = new point(defaultTechnique.rightWrist_x[i], defaultTechnique.rightWrist_y[i], defaultTechnique.rightWrist_z[i]);

        technique.leftElbowPos[i] = new point(defaultTechnique.leftElbow_x[i], defaultTechnique.leftElbow_y[i], defaultTechnique.leftElbow_z[i]);
        technique.rightElbowPos[i] = new point(defaultTechnique.rightElbow_x[i], defaultTechnique.rightElbow_y[i], defaultTechnique.rightElbow_z[i]);

        technique.leftHipPos[i] = new point(defaultTechnique.leftHip_x[i], defaultTechnique.leftHip_y[i], defaultTechnique.leftHip_z[i]);
        technique.rightHipPos[i] = new point(defaultTechnique.rightHip_x[i], defaultTechnique.rightHip_y[i], defaultTechnique.rightHip_z[i]);

        technique.leftShoulderPos[i] = new point(defaultTechnique.leftShoulder_x[i], defaultTechnique.leftShoulder_y[i], defaultTechnique.leftShoulder_z[i]);
        technique.rightShoulderPos[i] = new point(defaultTechnique.rightShoulder_x[i], defaultTechnique.rightShoulder_y[i], defaultTechnique.rightShoulder_z[i]);

        technique.spineChestPos[i] = new point(defaultTechnique.spineChest_x[i], defaultTechnique.spineChest_y[i], defaultTechnique.spineChest_z[i]);


        technique.headPos[i] = new point(defaultTechnique.spineChest_x[i], defaultTechnique.spineChest_y[i] - 250, defaultTechnique.spineChest_z[i]);

        technique.leftClaviclePos[i] = new point(defaultTechnique.leftClavicle_x[i], defaultTechnique.leftClavicle_y[i], defaultTechnique.leftClavicle_z[i]);
        technique.rightClaviclePos[i] = new point(defaultTechnique.rightClavicle_x[i], defaultTechnique.rightClavicle_y[i], defaultTechnique.rightClavicle_z[i]);
      
    }

    technique.leftFootQ = defaultTechnique.leftFoot_Q;
    technique.rightFootQ = defaultTechnique.rightFoot_Q;
    technique.leftAnkleQ = defaultTechnique.leftAnkle_Q;
    technique.rightAnkleQ = defaultTechnique.rightAnkle_Q;
    technique.leftKneeQ = defaultTechnique.leftKnee_Q;
    technique.rightKneeQ = defaultTechnique.rightKnee_Q;
    technique.leftHipQ = defaultTechnique.leftHip_Q;
    technique.rightHipQ = defaultTechnique.rightHip_Q;
    technique.leftShoulderQ = defaultTechnique.leftShoulder_Q;
    technique.rightShoulderQ = defaultTechnique.rightShoulder_Q;
    technique.leftClavicleQ = defaultTechnique.leftClavicle_Q;
    technique.rightClavicleQ = defaultTechnique.rightClavicle_Q;
    technique.leftWristQ = defaultTechnique.leftWrist_Q;
    technique.rightWristQ = defaultTechnique.rightWrist_Q;

    technique.spineChestQ = defaultTechnique.spineChest_Q;

    technique.leftElbowQ = defaultTechnique.leftElbow_Q;
    technique.rightElbowQ = defaultTechnique.rightElbow_Q;

    technique.pelvisQ = defaultTechnique.pelvis_Q;
    technique.headQ = defaultTechnique.head_Q;

    //console.log("technique", technique)

    //Apply various processes on JSON and export. 
    filterJSON();
    normalizeJSON();
    calcSpeedAngAcc();
    calcMinMax();

   // console.log(technique);
}

export function loadDefault() {


    technique.techniqueType = defaultTechnique.techniqueType;
    technique.timeStamp = defaultTechnique.timeStamp;
    technique.notes = defaultTechnique.notes;
    technique.ownerId = defaultTechnique.ownerId;
    technique.timeStamps = defaultTechnique.allTimestamps;
    technique.id = technique.techniqueType + '-' + technique.timeStamp;
    console.log('loading default technique ------------------------', technique.id)
    //  getFrameTimes(technique);

    //export technique length from converter and use in for loop here. 
    for (var i = 0; i <= 125; i += 1) {

        technique.leftFootPos[i] = new point(defaultTechnique.leftFoot_x[i], defaultTechnique.leftFoot_y[i], defaultTechnique.leftFoot_z[i]);
        technique.rightFootPos[i] = new point(defaultTechnique.rightFoot_x[i], defaultTechnique.rightFoot_y[i], defaultTechnique.rightFoot_z[i]);

        technique.leftAnklePos[i] = new point(defaultTechnique.leftAnkle_x[i], defaultTechnique.leftAnkle_y[i], defaultTechnique.leftAnkle_z[i]);
        technique.rightAnklePos[i] = new point(defaultTechnique.rightAnkle_x[i], defaultTechnique.rightAnkle_y[i], defaultTechnique.rightAnkle_z[i]);

        technique.leftKneePos[i] = new point(defaultTechnique.leftKnee_x[i], defaultTechnique.leftKnee_y[i], defaultTechnique.leftKnee_z[i]);
        technique.rightKneePos[i] = new point(defaultTechnique.rightKnee_x[i], defaultTechnique.rightKnee_y[i], defaultTechnique.rightKnee_z[i]);

        technique.pelvisPos[i] = new point(defaultTechnique.pelvis_x[i], defaultTechnique.pelvis_y[i], defaultTechnique.pelvis_z[i]);

        technique.leftWristPos[i] = new point(defaultTechnique.leftWrist_x[i], defaultTechnique.leftWrist_y[i], defaultTechnique.leftWrist_z[i]);
        technique.rightWristPos[i] = new point(defaultTechnique.rightWrist_x[i], defaultTechnique.rightWrist_y[i], defaultTechnique.rightWrist_z[i]);

        technique.leftElbowPos[i] = new point(defaultTechnique.leftElbow_x[i], defaultTechnique.leftElbow_y[i], defaultTechnique.leftElbow_z[i]);
        technique.rightElbowPos[i] = new point(defaultTechnique.rightElbow_x[i], defaultTechnique.rightElbow_y[i], defaultTechnique.rightElbow_z[i]);

        technique.leftHipPos[i] = new point(defaultTechnique.leftHip_x[i], defaultTechnique.leftHip_y[i], defaultTechnique.leftHip_z[i]);
        technique.rightHipPos[i] = new point(defaultTechnique.rightHip_x[i], defaultTechnique.rightHip_y[i], defaultTechnique.rightHip_z[i]);

        technique.leftShoulderPos[i] = new point(defaultTechnique.leftShoulder_x[i], defaultTechnique.leftShoulder_y[i], defaultTechnique.leftShoulder_z[i]);
        technique.rightShoulderPos[i] = new point(defaultTechnique.rightShoulder_x[i], defaultTechnique.rightShoulder_y[i], defaultTechnique.rightShoulder_z[i]);

        technique.spineChestPos[i] = new point(defaultTechnique.spineChest_x[i], defaultTechnique.spineChest_y[i], defaultTechnique.spineChest_z[i]);
        technique.leftClaviclePos[i] = new point(defaultTechnique.leftClavicle_x[i], defaultTechnique.leftClavicle_y[i], defaultTechnique.leftClavicle_z[i]);
        technique.rightClaviclePos[i] = new point(defaultTechnique.rightClavicle_x[i], defaultTechnique.rightClavicle_y[i], defaultTechnique.rightClavicle_z[i]);
        //TODO: get real head, not this hack for joint number issue. 
        technique.headPos[i] = new point(defaultTechnique.head_x[i], defaultTechnique.head_y[i], defaultTechnique.head_z[i]);

    }

    //Apply various processes on JSON and export. 
    filterJSON();
    normalizeJSON();
    calcSpeedAngAcc();
    calcMinMax();

    //  exports.loadedTech = technique;
}

function filterJSON() {
    console.log("filtering started")
    filterArray(technique.leftFootPos);
    filterArray(technique.rightFootPos);

    filterArray(technique.leftAnklePos);
    filterArray(technique.rightAnklePos);

    filterArray(technique.leftKneePos);
    filterArray(technique.rightKneePos);

    filterArray(technique.pelvisPos);

    filterArray(technique.leftWristPos);
    filterArray(technique.rightWristPos);

    filterArray(technique.leftElbowPos);
    filterArray(technique.rightElbowPos);

    filterArray(technique.leftHipPos);
    filterArray(technique.rightHipPos);

    filterArray(technique.leftClaviclePos);
    filterArray(technique.rightClaviclePos);

    filterArray(technique.spineChestPos);

    filterArray(technique.leftShoulderPos);
    filterArray(technique.rightShoulderPos);

    filterArray(technique.headPos);
}

function calcMinMax() {

    //Simple guess of which (one) foot kicked by seeing which is higher.

    let maxLeftHeight = 0;
    let maxRightHeight = 0;
    let minlength = 0;
    if (technique.leftFootPos.length < technique.rightFootPos.length) {
        minlength = technique.leftFootPos.length;
    }
    else { minlength = technique.rightFootPos.length; }

    for (var i = 0; i <= minlength - 1; i += 1) {
        if (technique.leftFootPos[i].y > maxLeftHeight) { maxLeftHeight = technique.leftFootPos[i].y; }
        if (technique.rightFootPos[i].y > maxRightHeight) { maxRightHeight = technique.rightFootPos[i].y; }
    }
    if (maxLeftHeight > maxRightHeight) {
        technique.calculatedFoot = "Left";
        technique.maxFootHeight = maxLeftHeight;
    }

    else {
        technique.calculatedFoot = "Right";
        technique.maxFootHeight = maxRightHeight;
    }

    if (technique.leftWristPos.length < technique.rightWristPos.length) {
        minlength = technique.leftWristPos.length;
    }
    else { minlength = technique.rightWristPos.length; }

    for (i = 0; i <= minlength - 1; i += 1) {
        if (technique.leftWristPos[i].y > maxLeftHeight) { maxLeftHeight = technique.leftWristPos[i].y; }
        if (technique.rightWristPos[i].y > maxRightHeight) { maxRightHeight = technique.rightWristPos[i].y; }
    }
    if (maxLeftHeight > maxRightHeight) {
        technique.calculatedWrist = "Left";
        technique.maxHandHeight = maxLeftHeight;
    }

    else {
        technique.calculatedWrist = "Right";
        technique.maxHandHeight = maxRightHeight;
    }


    technique.maxFootSpeed = max(...technique.leftFootSpeed, ...technique.rightFootSpeed);
    technique.maxHandSpeed = max(...technique.leftWristSpeed, ...technique.rightWristSpeed);
    technique.maxKneeExtAngle = max(...technique.leftKneeExt, ...technique.rightKneeExt);
    technique.maxKneeExtSpeed = max(...technique.leftKneeExtSpeed, ...technique.rightKneeExtSpeed);
    technique.maxElbowExtAngle = max(...technique.leftElbowExt, ...technique.rightElbowExt);
    technique.maxElbowExtSpeed = max(...technique.leftElbowExtSpeed, ...technique.rightElbowExtSpeed);
   // technique.maxShoulderRotSpeed = max(...technique.shoulderRot);
    technique.maxHipRotSpeed = max(...technique.hipRot);
}

function normalizeJSON() {

    //normalize technique by setting 0,0,0 point
    //O point is the average height of left and right feet, and x and z of pelvis joint. 
    let zeroPoint = new point(technique.pelvisPos[0].x, (technique.leftFootPos[0].y + technique.rightFootPos[0].y) / 2, technique.pelvisPos[0].z);
   
    normalizePos(technique.leftFootPos, zeroPoint);
    normalizePos(technique.rightFootPos, zeroPoint);
    normalizePos(technique.leftAnklePos, zeroPoint);
    normalizePos(technique.rightAnklePos, zeroPoint);
    normalizePos(technique.leftKneePos, zeroPoint);
    normalizePos(technique.rightKneePos, zeroPoint);
    normalizePos(technique.leftHipPos, zeroPoint);
    normalizePos(technique.rightHipPos, zeroPoint);
    normalizePos(technique.leftElbowPos, zeroPoint);
    normalizePos(technique.rightElbowPos, zeroPoint);
    normalizePos(technique.leftClaviclePos, zeroPoint);
    normalizePos(technique.rightClaviclePos, zeroPoint);
    normalizePos(technique.leftShoulderPos, zeroPoint);
    normalizePos(technique.rightShoulderPos, zeroPoint);
    normalizePos(technique.leftWristPos, zeroPoint);
    normalizePos(technique.rightWristPos, zeroPoint);
    normalizePos(technique.pelvisPos, zeroPoint);
    normalizePos(technique.spineChestPos, zeroPoint);
    normalizePos(technique.headPos, zeroPoint);
}

function calcSpeedAngAcc() {

    technique.leftFootSpeed = calcSpeedArray(technique.leftFootPos);
    technique.leftFootAcc = calcAccArray(technique.leftFootSpeed);

    technique.rightFootSpeed = calcSpeedArray(technique.rightFootPos);
    technique.rightFootAcc = calcAccArray(technique.rightFootSpeed);

    technique.leftAnkleSpeed = calcSpeedArray(technique.leftAnklePos);
    technique.leftAnkleAcc = calcAccArray(technique.leftAnkleSpeed);

    technique.rightAnkleSpeed = calcSpeedArray(technique.rightAnklePos);
    technique.rightAnkleAcc = calcAccArray(technique.rightAnkleSpeed);

    technique.leftKneeSpeed = calcSpeedArray(technique.leftKneePos);
    technique.leftKneeAcc = calcAccArray(technique.leftKneeSpeed);

    technique.rightKneeSpeed = calcSpeedArray(technique.rightKneePos);
    technique.rightKneeAcc = calcAccArray(technique.rightKneeSpeed);

    technique.pelvisSpeed = calcSpeedArray(technique.pelvisPos);
    technique.pelvisAcc = calcAccArray(technique.pelvisSpeed);

    technique.leftWristSpeed = calcSpeedArray(technique.leftWristPos);
    technique.leftWristAcc = calcAccArray(technique.leftWristSpeed);

    technique.rightWristSpeed = calcSpeedArray(technique.rightWristPos);
    technique.rightWristAcc = calcAccArray(technique.rightWristSpeed);

    technique.leftHipSpeed = calcSpeedArray(technique.leftHipPos);
    technique.leftHipAcc = calcAccArray(technique.leftHipSpeed);

    technique.rightHipSpeed = calcSpeedArray(technique.rightHipPos);
    technique.rightHipAcc = calcAccArray(technique.rightHipSpeed);


    technique.leftShoulderSpeed = calcSpeedArray(technique.leftShoulderPos);
    technique.leftShoulderAcc = calcAccArray(technique.leftShoulderSpeed);

    technique.rightShoulderSpeed = calcSpeedArray(technique.rightShoulderPos);
    technique.rightShoulderAcc = calcAccArray(technique.rightShoulderSpeed);


    technique.leftElbowSpeed = calcSpeedArray(technique.leftElbowPos);
    technique.leftElbowAcc = calcAccArray(technique.leftElbowSpeed);

    technique.rightElbowSpeed = calcSpeedArray(technique.rightElbowPos);
    technique.rightElbowAcc = calcAccArray(technique.rightElbowSpeed);

    technique.headSpeed = calcSpeedArray(technique.headPos);
    technique.headAcc = calcAccArray(technique.headSpeed);

    //Knee and Elbow exension and extension speeds

    technique.leftKneeExt = calcAngArray(technique.leftHipPos, technique.leftKneePos, technique.leftFootPos);
    technique.rightKneeExt = calcAngArray(technique.rightHipPos, technique.rightKneePos, technique.rightFootPos);

    technique.leftKneeExtSpeed = fillExtSpeedArray(technique.leftKneeExt);
    technique.rightKneeExtSpeed = fillExtSpeedArray(technique.rightKneeExt);

    technique.leftElbowExt = calcAngArray(technique.leftShoulderPos, technique.leftElbowPos, technique.leftWristPos);
    technique.rightElbowExt = calcAngArray(technique.rightShoulderPos, technique.rightElbowPos, technique.rightWristPos);

    technique.leftElbowExtSpeed = fillExtSpeedArray(technique.leftElbowExt);
    technique.rightElbowExtSpeed = fillExtSpeedArray(technique.rightElbowExt);

    //Calculate rotation speeds. 

    technique.hipRot = fillRotArray(technique.leftHipPos, technique.pelvisPos);
    //technique.shoulderRot = fillRotArray(technique.leftShoulderPos, technique.rightShoulderPos);



    //Dev - see if value ranges make sense. 
    /*
    reportValueRanges(technique.leftFootPos, "Left Foot");
    reportValueRanges(technique.rightFootPos, "Right Foot");
    reportValueRanges(technique.leftAnklePos, "Left Ankle");
    reportValueRanges(technique.rightAnklePos, "Right Ankle");
    reportValueRanges(technique.leftKneePos, "Left Knee");
    reportValueRanges(technique.rightKneePos, "Right Knee");
    reportValueRanges(technique.leftHipPos, "Left Hip");
    reportValueRanges(technique.rightHipPos, "Right Hip");
    reportValueRanges(technique.leftShoulderPos, "Right Shoulder");
    reportValueRanges(technique.rightShoulderPos, "Right Shoulder");
    reportValueRanges(technique.leftElbowPos, "Left Elbow");
    reportValueRanges(technique.rightElbowPos, "Right Elbow");
    reportValueRanges(technique.leftWristPos, "Left Wrist");
    reportValueRanges(technique.rightWristPos, "Right Wrist");
    reportValueRanges(technique.headPos, "Head");

    /*
    console.log("Left Foot Max Speed = " + max(...technique.leftFootSpeed) + "Max Acceleration: " + max(...technique.leftFootAcc));
    console.log("Right Foot Max Speed = " + max(...technique.rightFootSpeed) + "Max Acceleration: " + max(...technique.rightFootAcc));

    console.log("Left Ankle Max Speed = " + max(...technique.leftAnkleSpeed) + "Max Acceleration: " + max(...technique.leftAnkleAcc));
    console.log("Right Ankle Max Speed = " + max(...technique.rightAnkleSpeed) + "Max Acceleration: " + max(...technique.rightAnkleAcc));

    console.log("Left Knee Max Speed = " + max(...technique.leftKneeSpeed) + "Max Acceleration: " + max(...technique.leftKneeAcc));
    console.log("Right Knee Max Speed = " + max(...technique.rightKneeSpeed) + "Max Acceleration: " + max(...technique.rightKneeAcc));

    console.log("Left Hip Max Speed = " + max(...technique.leftHipSpeed) + "Max Acceleration: " + max(...technique.leftHipAcc));
    console.log("Right Hip Max Speed = " + max(...technique.rightHipSpeed) + "Max Acceleration: " + max(...technique.rightHipAcc));

    console.log("Left Shoulder Max Speed = " + max(...technique.leftShoulderSpeed) + "Max Acceleration: " + max(...technique.leftShoulderAcc));
    console.log("Right Shoulder Max Speed = " + max(...technique.rightShoulderSpeed) + "Max Acceleration: " + max(...technique.rightShoulderAcc));

    console.log("Left Elbow Max Speed = " + max(...technique.leftElbowSpeed) + "Max Acceleration: " + max(...technique.leftElbowAcc));
    console.log("Right Elbow Max Speed = " + max(...technique.rightElbowSpeed) + "Max Acceleration: " + max(...technique.rightElbowAcc));

    console.log("Left Wrist Max Speed = " + max(...technique.leftWristSpeed) + "Max Acceleration: " + max(...technique.leftWristAcc));
    console.log("Right Wrist Max Speed = " + max(...technique.rightWristSpeed) + "Max Acceleration: " + max(...technique.rightWristAcc));


    console.log("Pelvis Max Speed = " + max(...technique.pelvisSpeed) + "Max Acceleration: " + max(...technique.pelvisAcc));
    console.log("Head Max Speed = " + max(...technique.headSpeed) + "Max Acceleration: " + max(...technique.headAcc));
    */
   

}


/*
function getFrameTimes(technique) {
    for (var j = 0; j < technique.timeStamps.length - 1; j++) {
        technique.frameTimes[j] = technique.timeStamps[j + 1] - technique.timeStamps[j];
    }

}
*/

function normalizePos(arr, zeroPoint) {

    for (var j = 0; j < arr.length - 1; j++) {

        arr[j].x = arr[j].x - zeroPoint.x;
        arr[j].y = -1 * (arr[j].y - zeroPoint.y);
        arr[j].z = arr[j].z - zeroPoint.z;
    }
    return arr;

}

function filterArray(arr) {
    //Minimum and maximum values of legitimate techniques
    //Filter out error values in the position arrays due to uninitialized memory or values too high or low.
    const minValue = 10;
    const maxValue = 7000;
    for (var j = 0; j < arr.length - 1; j++) {
        //  console.log("j= ", j, "arr[j].x = ", arr[j].x);
        if ((abs(arr[j].x) < minValue) || (abs(arr[j].x) > maxValue)) {
            //console.log("spliced out", arr[j]);
            arr.splice(j, 1);
            j--;
        }
    }
    return arr;
}

function calcSpeedArray(arr) {

    return fillSpeedArray(arr);

}

function calcAccArray(arr) {

    return fillAccArray(arr);

}

function calcAngArray(arr1, arr2, arr3) {

    return fillAngArray(arr1, arr2, arr3);

}

/*Function that reports ranges and values. Right now used for debugging/testing. 
function reportValueRanges(arr, name) {
    var height = [];
    let i;
    for (i = 0; i < arr.length - 1; i++) {
        height[i] = arr[i].y;
    }
    console.log(`${name} Max Height = ` + max(...height) + " Min Height = " + min(...height));

}

//Report the max speed or acceleration of any joint
function reportMax(arr, name) {
    let i;
    var height = [];
    for (i = 0; i < arr.length - 1; i++) {
        height[i] = arr[i].y;
    }
    console.log(`${name}  = ` + max(...height) + " Min Height = " + min(...height));

}


*/

//exports.loadJSON = loadJSON;
loadDefault();

function getTechnique() {
    return technique;
}

export function isTechnique(id) {
    return (id === technique.id)
}

function isUserOwner() {
    //returns true if the currently logged in user is the owner of the currently loaded technique. 
    let currentUser = getCurrentUser();
    //console.log('technique user and current user Id', technique.ownerId, currentUser.userId);
    if (technique.ownerId === currentUser.userId) { return true; }
    return false;
}

async function getTechniquesForGroup(groupId, type) {
    //Finds all the current user's techniques as an array of technique ids. 
    let token = getToken();
    let info = await axios.get(functionURL + `?&userId=${type}&groupId=${groupId}&type=getTechniquesForGroup`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }, {});
    info = JSON.parse(info.request.response);
    console.log('info:', info);

    return info;
}


async function getTechniqueNumbersForUser() {
    //Finds all the current user's techniques as an array of technique ids. 
    let token = getToken();
    let currentUser = getCurrentUser();
    
    let info = await axios.get(functionURL + `?userId=${currentUser.userId}&type=getTechniqueNumbers`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }, {});
    info = JSON.parse(info.request.response);
    info = info._items[0]

    return info;
}




async function getTechniquesForUser(isPublic) {
    //Finds all the current user's techniques as an array of technique ids. 
    let token = getToken();
    let currentUser = getCurrentUser();
    let userId = currentUser.userId
    if (isPublic === true) { userId = 'public'; } //get all public techniques
    
    let info = await axios.get(functionURL + `?userId=${userId}&type=getAllTechniques`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }, {});
    info = JSON.parse(info.request.response);


    return info;
}


async function getTechniqueFromGraphDb(techniqueId) {
    let tech = {};
    //Loads the details of a technique.
    let token = getToken();
  

    let info = await axios.get(functionURL + `?id=${techniqueId}&type=getRecord`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }}, {});
    info = JSON.parse(info.request.response);

    tech.techType = info._items[0].properties.techType[0].value;
    tech.manualScore = info._items[0].properties.manualScore[0].value;
    tech.calculatedScore = tech.manualScore
    //tech.calculatedScore = info._items[0].properties.calculatedScore[0].value;
    tech.timeStamp = info._items[0].properties.timeStamp[0].value;
    tech.privacy = info._items[0].properties.privacy[0].value;
    tech.notes = info._items[0].properties.notes[0].value;
    tech.id = techniqueId;
    
    return tech;
}


async function changeNotes(notes) {
    technique.notes = notes
    console.log(notes)
    let token = getToken();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.post(functionURL + `?type=changeProperty&id=${technique.id}&key=notes&newValue=${notes}`, {}, {})
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        }); 


}

async function addTechniqueToDb() {
    //first make sure it's not already there
 
   
    let token = getToken();
  
    let info = await axios.get(functionURL + `?id=${technique.id}&type=getRecord`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }, {});
  

    if (technique.notes == '') {
        technique.notes = 'none'
    }
    let currentUser = getCurrentUser();

    if (info.request.response != '') {
        console.log("this technique is already in the db!")
        return;
    }

    technique.privacy = '1-Public-Unlisted'
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    let response = await axios.post(functionURL, {
        }, {
            params: {
                type: 'addTechnique',
                techniqueId: technique.id,
                techType: technique.techniqueType,
                manualScore: technique.manualScore,
                calculatedScore: technique.calculatedScore,
                timeStamp: technique.timeStamp,
                videoURL: technique.videoURL,
                userId: currentUser.userId,
                privacy: technique.privacy,
                notes: technique.notes
            }
        })

        

    //After the technique has been added, then adjust the average parameters of the technique.
    //If the technique isn't a general motion type. 
    if (technique.techniqueType !== 'c1') {
        await adjustTechniqueAverages('add', technique.techniqueType);
    }

console.log(response)
return response;
  
}
 

async function addTechniqueOwner() {
    let currentUser = getCurrentUser();
    // As a backup, add the owner
    let token = getToken();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.post(functionURL, {
    }, {
        params: {
            type: 'addTechniqueOwner',
            techniqueId: technique.id,
            userId: currentUser.userId,

        }
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });

}


async function adjustTechniqueAverages(operation, customId) {
    let token = getToken();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.post(functionURL, {
    
    }, {
        params: {
            type: 'addCustomTechniqueExample',
            customId: customId,
            score: technique.calculatedScore,
            maxFootSpeed: technique.maxFootSpeed,
            maxFootHeight: technique.maxFootHeight,
            maxHandSpeed : technique.maxHandSpeed,
            maxHandHeight : technique.maxHandHeight,
            maxKneeExtAngle : technique.maxKneeExtAngle,
            maxKneeExtSpeed : technique.maxKneeExtSpeed,
            maxElbowExtAngle : technique.maxElbowExtAngle,
            maxElbowExtSpeed : technique.maxElbowExtSpeed, 
            maxShoulderRotSpeed : technique.maxShoulderRotSpeed,
            maxHipRotSpeed: technique.maxHipRotSpeed,
            operation: operation
        }
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });

}


//local function to change techType before upload

function changeLocalTechType(newType) {
    technique.techniqueType = newType;
    technique.id = newType + '-' + technique.timeStamp;
    console.log('Id after upload', technique.id);
}


//Function to change a technique from one type to another. 
async function changeTechType(newType) {
    await adjustTechniqueAverages('subtract', technique.techniqueType);
    technique.techniqueType = newType;
    await adjustTechniqueAverages('add', technique.techniqueType);

}

async function getTechniqueFromDb(techniqueId) {
   
    //first get from blob storage
    let success = await getTechniqueFromBlob(techniqueId)
    if (success == 403) {
        alert('403')
        return 403
    }
    
    await loadVideo('main', techniqueId)
    //then update with graph db
    let gDbTech = await getTechniqueFromGraphDb(techniqueId);
  
    technique = success;
    technique.id = gDbTech.id;
    technique.techType = gDbTech.techType;
    technique.manualScore = gDbTech.manualScore;
    technique.calculatedScore = gDbTech.calculatedScore;
    technique.timeStamp = gDbTech.timeStamp;
    technique.privacy = gDbTech.privacy;
    technique.notes = gDbTech.notes;
  
    
   // console.log('technique from data.js', technique)
    return technique
}

async function changePrivacy(techniqueId, newPrivacy) {
    let token = getToken();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.post(functionURL, {

    }, {
        params: {
            type: 'changeProperty',
            id: techniqueId,
            key: 'privacy',
            newValue: newPrivacy,
        }
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
}



async function changeManualScore(techniqueId, newScore) {
    let token = getToken();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.post(functionURL, {
    }, {
        params: {
            type: 'changeProperty',
            id: techniqueId,
            key: 'manualScore',
            newValue: newScore,
        }
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
    /*
    await adjustTechniqueAverages('subtract', technique.techniqueType);
    technique.manualScore= newScore;
    await adjustTechniqueAverages('add', technique.techniqueType);
    */
}


async function changeOwner(techniqueId, newOwner) {
    let token = getToken();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.post(functionURL, {
    }, {
        params: {
            type: 'changeTechniqueOwner',
            techniqueId: techniqueId,
            newOwner: newOwner,
        }
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
    /*
    await adjustTechniqueAverages('subtract', technique.techniqueType);
    technique.manualScore= newScore;
    await adjustTechniqueAverages('add', technique.techniqueType);
    */
}






async function deleteTechnique(techniqueId) {

    // delete from blob storage
    await deleteFromBlob('videos', techniqueId);
    await deleteFromBlob('techdata', techniqueId);
    
    let token = getToken();
    // delete with graph db
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    await axios.post(functionURL, {
     
    }, {
        params: {
            type: 'deleteId',
            deleteId: techniqueId,
        }
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
 
   

}

export {point,  getTechnique, uploadTechniqueFromFile, addTechniqueToDb, getTechniquesForUser, addTechniqueOwner, getTechniqueFromGraphDb, getTechniqueFromDb, changePrivacy, isUserOwner, getTechniquesForGroup, changeTechType, changeManualScore, deleteTechnique, changeLocalTechType, changeNotes, getTechniqueNumbersForUser };
