import React from 'react';
import HeaderSection from '../HeaderSection';
import { getTechniquesForUser } from '../../data';
import LoadButton from '../buttons/LoadButton';
import CompareButton from '../buttons/CompareButton';


import FavoriteButton from '../buttons/FavoriteButton';
import { getHRTechType } from '../../techTypes';



class PublicTechTable extends React.Component {


  constructor(props) {
      super(props)
      this.state = {setView: props.setView, updateComparisonTechnique: props.updateComparisonTechnique, updateTechnique: props.updateTechnique, publicTechTable: [] } 

      
    }

    componentDidMount = async () => {
 
        await this.updatePublicTechniqueTable()
      
    }
  

    async updatePublicTechniqueTable() {
        let techTable = [];
        let userTechniques = await getTechniquesForUser(true);
        console.log('public user techniques', userTechniques)
        let humanType = '';
        let humanDate = '';
        for (var i = 0; i < userTechniques._items.length; i++) {
            humanType = getHRTechType(userTechniques._items[i].properties.techType[0].value);
            humanDate = this.getHumanReadableDate(userTechniques._items[i].properties.timeStamp[0].value)
                techTable.push(
                    <tr key={i}>
                        <td data-label="Type">{humanType}</td>
                        <td data-label="Date">{humanDate}</td>
                        <td data-label="Notes">{userTechniques._items[i].properties.notes[0].value}</td>
                        <td data-label="Score">{userTechniques._items[i].properties.calculatedScore[0].value}</td>
                        <td data-label="loads">{userTechniques._items[i].properties.loads[0].value}</td>
                        <td data-label="Action"> <LoadButton techniqueId={userTechniques._items[i].id} refresh={this.state.updateTechnique} /> <CompareButton techniqueId={userTechniques._items[i].id} update={this.state.updateComparisonTechnique} /> <FavoriteButton id={userTechniques._items[i].id} /></td>

                    </tr>
                );
           
        }
        this.setState({ publicTechTable: techTable })

    }


    getHumanReadableDate(time) {
        time = parseInt(time, 10);
        let timestamp = new Date(time *1000);
        var date = timestamp.getFullYear() + '-' + (timestamp.getMonth() + 1) + '-' + timestamp.getDate();
        time = timestamp.getHours() + ":" + timestamp.getMinutes() + ":" + timestamp.getSeconds();
        return (date);
    }

    render() {
       
        return (
            <div className="ui grid">
                <HeaderSection color='green'  title='Public Techniques' />
                    <table className="ui green striped unstackable table">
                        <thead>
                            <tr>
                                <th scope="col" >Type</th>
                                <th scope="col" >Date</th>
                                <th scope="col" >Notes</th>
                                <th scope="col" >Score</th>
                                <th scope="col" >Loads</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.publicTechTable.length !== 0 && this.state.publicTechTable}
                        </tbody>
                </table>
                {this.state.publicTechTable.length == 0 && <div className='sixteen wide centered column'><img src='images/loading.gif' /></div>}
        </div>
        );
    }
}

export default PublicTechTable;


