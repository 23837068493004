import React from 'react';
import AccountInfoCard from '../AccountInfoCard';
import LoginCard from '../LoginCard';
import DojangInfoCard from '../DojangInfoCard';
import Profile from '../Profile';
import DojangAdder from '../DojangAdder';
import { getUserDojang } from '../../dojangInfo.js';
import AccountEditor from '../AccountEditor';
import { getCurrentUser } from '../../userInfo';



class AccountView extends React.Component {


    constructor(props) {
        super(props)
        let dojang = {id: 0, website: '', description: 'Add your school info', memberNumbers: 0, location: 'earth', techniqueNumbers: 0};

        this.state = {dojang: dojang, editUser: false, userName: 'My Account', loaded: false}
    }

    async componentDidMount() {
         //function to get whether a user is currently associated with a dojang.
        //Set info as state to pass down to other components. 
        let editDojang = false;
        let dojang = await getUserDojang();
        let currentUser = await getCurrentUser();
        let mustLogin = false;
        if (currentUser.userId == 0) {
            mustLogin = true;
        }
        if (dojang.id === 0) { editDojang = true;} 
        this.setState({ currentUser: currentUser, dojang: dojang , userName: currentUser.displayName, editDojang, loaded: true, mustLogin}); 
        
    }

    editUser = () => {
        this.setState({editUser: true});
    }


   closeEditUser = () => {
        this.setState({ editUser: false});
    }

    closeEditDojang = () => {
        this.setState({ editDojang: false });
    }

    onFinishEdit = () => {
        let currentUser = getCurrentUser();
        this.setState({ editUser: false, currentUser: currentUser });
    }

    changeDojang = () => {
        let dojang = this.state.dojang;
    
        this.setState({ dojang: dojang, editDojang: true });

    }

    render() {

      
        return (
            <div className='ui stackable grid'>
                <div className = 'fourteen wide centered column'>
                {(!this.state.mustLogin && this.state.editUser) && <AccountEditor onFinishEdit={this.onFinishEdit} closeEditUser={this.closeEditUser} />}
                {(!this.state.mustLogin && this.state.editDojang)&& <div> <div className='ui divider' /><DojangAdder closeEditDojang={this.closeEditDojang} /> </div> }
                </div>

                <div className='six wide column'>

                    <LoginCard />
                </div>

                <div className='five wide column'>
                    {!this.state.mustLogin && <AccountInfoCard editUser={this.editUser} currentUser={this.state.currentUser} />}
                </div>

                <div className='five wide column'>
                    {(!this.state.mustLogin && this.state.loaded) && <DojangInfoCard dojang={this.state.dojang} changeDojang={this.changeDojang} />}
                </div>
                <div className='five wide column'>
                        <Profile />
                </div>
                </div>

        );



    }


}
export default AccountView;



