
import axios from 'axios'
import { getCurrentUser } from './userInfo.js';
import { getToken } from './token.js';
import { functionURL } from './config/default.js';


async function addCustomTechniqueToDb(customTechnique) {

    let token = getToken();

    //first see how many custom techniques there are
    let info = await axios.get(functionURL + `?type=getCustomTechniqueNumbers`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }}, {});
    info = JSON.parse(info.request.response);

    let numbers = info._items[0] + 1;
    let customId = 'c' + numbers;

    let currentUser = getCurrentUser();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.post(functionURL, {

    }, {
        params: {
            type: 'addCustomTechnique',
            customId: customId,
            name: customTechnique.name,
            number: 0,
            max: customTechnique.max,
            min: customTechnique.min,
            attributes: JSON.stringify(customTechnique.attributes),
            creator: currentUser.userId
        }
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });

    return customId
}

//Function to get all types of custom defined techniques in the db. 
async function getTechniqueTypes() {
    let token = getToken();
    let info = await axios.get(functionURL + `?type=getTechniqueTypes`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }}, {});
    console.log(info)
    info = JSON.parse(info.request.response);

    return info._items;
    
}

 



//async function deleteTechniqueFromDb(techniqueId) {

    //first delete from blob storage
    //  technique = await deleteTechniqueFromBlob(techniqueId)

    //then delete with graph db

    //finally, load the default technique. 

    //  console.log('technique from data.js', technique)
//}

export { addCustomTechniqueToDb, getTechniqueTypes};