import axios from 'axios'
import { getToken } from './token';
import { functionURL } from './config/default';


// Flag to know if they directly called a technique
let directTechnique = false;

export function getDirectTechnique() {
    return directTechnique;
}

export function setDirectTechnique(value) {
    directTechnique = value;
}



function setUser(field, value) {
    let token = getToken();
    console.log(field, value)
    if (field === 'displayName') {
        currentUser.displayName = value;

    }
    if (field === 'email') { currentUser.email = value; }
    if (field === 'weight') { currentUser.weight = value; }
    if (field === 'ethAddress') { currentUser.ethAddress = value; }
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.post(functionURL, {
      }, {
        params: {
            type: 'changeProperty',
            id: currentUser.userId,
            key: field,
            newValue: value
        }
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
    console.log('User=', currentUser);

}

function deleteUser() {
    //deletes the currently logged in user
    let token = getToken();
    //TODO: Also delete all their techniques
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.post(functionURL, {
      }, {
        params: {
            type: 'deleteId',
            deleteId: currentUser.userId,
        }
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });


}


let currentUser = {
    displayName: 'none', //if none display email
   
    email: 'none',
    //userId is the unique id from oath
    userId: 0,
    //for comparisons on leaderboard, like fastest heavyweight
    weight: 0,
    subscription: 'free',
    ethAddress: 'none', //for integration with coop. 
    overallScore: 0
    //groups the user belongs to are edges with property belongsTo

    //techniques the user owns are edges with property owns

    //dojang is an edge with property studentOf
}

function getCurrentUser() {
	//returns info about user currently logged in. 
    //console.log('current user is:', currentUser)
    return currentUser;
}

async function getAllUsers() {
    let token = getToken();
    let allUserInfo = await axios.get(functionURL + `?type=getAllUsers` , {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }, {});
    console.log(allUserInfo)
    allUserInfo = JSON.parse(allUserInfo.request.response);
    allUserInfo = allUserInfo._items;

    let userArray = [];
    let user = {}
    for (let i = 0; i < allUserInfo.length; i++) {
        user = {}
        user.name = allUserInfo[i].properties.displayName[0].value;
        user.overallScore = parseInt(allUserInfo[i].properties.overallScore[0].value, 10);
        user.weight = parseInt(allUserInfo[i].properties.weight[0].value, 10);
        userArray.push(user);
    }
 
    return userArray;

}

async function updateUser(user) {

    //Function called on login from Oath. 
    //If the user already exists in the database, then populate fields in the currentUser object. 
    //If not, add the user to the database with default currentUser parameters. 

    let token = getToken();
 
    let doesUserExist = await axios.get(functionURL + `?id=${user.sub}&type=doesUserExist`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }, {});
    doesUserExist = JSON.parse(doesUserExist.request.response);
  
    if (doesUserExist === '' || doesUserExist._items.length === 0) { //the user does not exist
        console.log("New user, adding to db")
        addUserToDb(user);
    }
    else {
        let userInfo = doesUserExist;

        currentUser.displayName = userInfo._items[0].properties.displayName[0].value;
        currentUser.email = userInfo._items[0].properties.email[0].value
        currentUser.weight = userInfo._items[0].properties.weight[0].value;
        currentUser.subscription = userInfo._items[0].properties.subscription[0].value;
        currentUser.overallScore = userInfo._items[0].properties.overallScore[0].value;
        currentUser.ethAddress = userInfo._items[0].properties.ethAddress[0].value;
        currentUser.userId = user.sub
    }
    
}

async function getNameForId(id) {
    let token = getToken();
   
    let name = await axios.get(functionURL + `?id=${id}&type=getNameForId`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }, {});
  
    if (name.request.response !== '') {
        name = JSON.parse(name.request.response);
        if (!name._items[0]) {
            return 'None'
        }
    
        return name._items[0].properties.displayName[0].value
    }
    return 'No Name Retrieved'
}

//Change the owner of a technique
async function switchOwner(newOwnerEmail, techniqueId) {
    //First, find the user Id that corresponds to the email. 
    let token = getToken();
    let email = await axios.get(functionURL + `?email=${newOwnerEmail}&type=getIdForEmail`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }, {});

    email = JSON.parse(email.request.response);
    if (!email._items[0]) {
        return 404
    }
    let newUserId = email._items[0].id

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    let result = await axios.post(functionURL, {
    }, {
        params: {
            type: 'changeTechniqueOwner',
            techniqueId: techniqueId,
            newOwner: newUserId,
      
        }
    })
    return result.status
}


function addUserToDb(user) {

    let token = getToken();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    axios.post(functionURL, {
        headers: {
            'Authorization': 'Bearer ' + token
        }}, {
        params: {
            type: 'addUser',
            displayName: user.nickname,
            email: user.email,
            userId: user.sub,
            weight: currentUser.weight,
            subscription: currentUser.subscription,
            overallScore: currentUser.overallScore,
            ethAddress: currentUser.ethAddress
        }
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
        
}


export { updateUser, getCurrentUser, getAllUsers, deleteUser, setUser, switchOwner, getNameForId }