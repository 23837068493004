
const math = require("mathjs")

//Pretty sure our analysis will stay here, but not hardcoding in case of future better sensors.
const framesPerSecond = 30;

//raw information about a point in space. 
function point(x, y, z) {
    this.x = x;
    this.y = y;
    this.z = z;
}


let positionArray = [];

//Finds the angle between vectors AB and BC in 3 dimensional space. 
function getAngle(pointA, pointB, pointC) {

    let ab = [pointB.x - pointA.x, pointB.y - pointA.y, pointB.z - pointA.z];
    let bc = [pointC.x - pointB.x, pointC.y - pointB.y, pointC.z - pointB.z];  

    let abVec = math.sqrt(ab[0] * ab[0] + ab[1] * ab[1] + ab[2] * ab[2]);
    let bcVec = math.sqrt(bc[0] * bc[0] + bc[1] * bc[1] + bc[2] * bc[2]);

    let abNorm = [ab[0]/ abVec, ab[1] / abVec, ab[2] / abVec];
    let bcNorm = [bc[0]/ bcVec, bc[1] / bcVec, bc[2] / bcVec];
   

    let  res = abNorm[0] * bcNorm[0] + abNorm[1] * bcNorm[1] + abNorm[2] * bcNorm[2];

    return math.acos(res) * 180.0 / 3.141592653589793;
            }




function fillDummyPosition() {
    //fill  with dummy info. 
  
    let positionArray = [];
    for (var i = 0; i <= 30; i += 1) {
        if (i < 10) {
            positionArray[i] = new point(1 + i , 1 + 2*i , 1 + 3*i );
        }
        if ((i >= 10)  && (i < 20)) {
            positionArray[i] = new point(1 + i , 1 + 2*i , 1 - 3*i );
        }
        if ((i >= 20) && (i < 30)) {
            positionArray[i] = new point(1 + i , 1 - 2*i , 1 + 3*i );
        }
    }
    return positionArray;
    }


positionArray = fillDummyPosition();
let speedArray = fillSpeedArray(positionArray);
let accArray = fillAccArray(speedArray);




function fill3DSpeedArray(positionArray) {
    let i;

    let speedArray = [];

    for (i = 0; i < positionArray.length - 3; i++) {

        //speedArray[i] = (distancePerFrame(positionArray[i], positionArray[i])* framesPerSecond).toPrecision(2);
        speedArray[i] = distancePerFrame(positionArray[i+1], positionArray[i]) * framesPerSecond;
        speedArray[i] = parseFloat(speedArray[i]);
    }

    //smooth it out
    for (let j = 1; j < speedArray.length - 2; j++) {

        speedArray[j] = math.mean(speedArray[j - 1], speedArray[j], speedArray[j + 1]).toPrecision(2);
    }


    return speedArray;



}



//returns an array where speedArray[i] is the instantaneous speed of the given joint at frame i. 
//args: positions = array of points with 3d positions of a joint. 
//Speed here is just vertical (y Axis speed)
function fillSpeedArray(positionArray) {
    let i;
   
    let speedArray = [];

    for (i = 0; i < positionArray.length - 3; i++) {
      
      //speedArray[i] = (distancePerFrame(positionArray[i], positionArray[i])* framesPerSecond).toPrecision(2);
        speedArray[i] = (positionArray[i+1].y - positionArray[i].y) * framesPerSecond;
        speedArray[i] = parseFloat(speedArray[i]);
    }
  
    for (let j = 1; j < speedArray.length -2; j++) {
  
        speedArray[j] =  math.mean(speedArray[j - 1], speedArray[j], speedArray[j + 1]).toPrecision(2);
    }
  
 
    return speedArray;
} 

function fillExtSpeedArray(positionArray) {
    let i;

    let speedArray = [];

    for (i = 0; i < positionArray.length - 3; i++) {

        //speedArray[i] = (distancePerFrame(positionArray[i], positionArray[i])* framesPerSecond).toPrecision(2);
        speedArray[i] = (positionArray[i + 1] - positionArray[i]) * framesPerSecond;
        speedArray[i] = parseFloat(speedArray[i]);
    }

    for (let j = 1; j < speedArray.length - 2; j++) {

        speedArray[j] = math.mean(speedArray[j - 1], speedArray[j], speedArray[j + 1]).toPrecision(2);
    }


    return speedArray;
} 


//returns an array where accArray[i] is the instantaneous acceleration of the given joint at frame i. 
//args: speedArray = array of linear instantaneous speeds of a joint. 
function fillAccArray(speedArray) {
    let i;
    let accArray = [];
 
    //TODO: Change fill speed and array -3 to proper number, by not having any undefineds at end of array
    for (i = 0; i < speedArray.length - 3; i++) {
        accArray[i] = ((speedArray[i] - speedArray[i + 1]) * framesPerSecond).toPrecision(2);
    }
 
    return accArray;
} 

//Returns an array showing the angle (extension of a joint) 
//For instance, to find right elbow extension, enter right shoulder first, right elbow second, right wrist third. 
function fillAngArray(firstJointPos, secondJointPos, thirdJointPos) {
    let i;
    let angArray = [];
  

    //TODO: First, check to make sure no dropped frames affect calculation - for now, just get smallest array
    let smallest = 2;
    if (firstJointPos.length <= secondJointPos.length) {
        smallest = firstJointPos.length;
    } else { smallest = secondJointPos.length; }
    if (thirdJointPos.length < smallest) {
        smallest = thirdJointPos.length;
    } 

    for (i = 0; i < smallest -1; i++) {
        
        angArray[i] = getAngle(firstJointPos[i], secondJointPos[i], thirdJointPos[i]).toPrecision(2);
    }
    return angArray;
} 

//Returns an array showing rotation speed of the hips or shoulders
//For instance, find the angle between the line formed between the left and right hips at time t, and that at time t1, and then divide by delta t
function fillRotArray(leftPosition, rightPosition) {
    let intersect;
    let rotArray = [];
    let angleArray = [];


    //TODO: First, check to make sure no dropped frames affect calculation - for now, just get smallest array
    let smallest = leftPosition.length;
    if (rightPosition.length <= leftPosition.length) {
        smallest = rightPosition.length;
    }
    angleArray[0] = 180
    for (let i = 0; i < smallest - 2; i++) {
        
        //intersect = math.intersect([leftPosition[i].x, leftPosition[i].y, leftPosition[i].z], [rightPosition[i].x, rightPosition[i].y, rightPosition[i].z], [leftPosition[i+1].x, leftPosition[i+1].y, leftPosition[i+1].z], [rightPosition[i+1].x, rightPosition[i+1].y, rightPosition[i+1].z]) 
        angleArray[i+1] = getAngle(leftPosition[i], rightPosition[i], leftPosition[i + 1]).toPrecision(5);
        rotArray[i] = (angleArray[i+1] - angleArray[i]) * framesPerSecond
    }

    return rotArray;
} 



function distancePerFrame(point1, point2) {
    //console.log(point1);
    //console.log(point2);
    return math.distance([point1.x, point1.y, point1.z], [point2.x, point2.y, point2.z]) 
}

export { fillSpeedArray, fillAccArray, fillAngArray , fillRotArray, fillExtSpeedArray, fill3DSpeedArray};

/*module.exports.positionArray = positionArray;
module.exports.speedArray = speedArray;
module.exports.accArray = accArray;
*/

//Functions we need



//Rotational speed, acceleration, etc. I think this one is only useful for certain pairs - is, rotation of the left hip relative to the right, 
//or rotation of the ankle relative to the vertical axis or also the mid hip/shoulder axis I think. 

//We need functions to fill the technique and user objects, but maybe better to wait until we get the API to see how they handle it. 
//Maybe some for loops to fill with some numbers like 1.1,1.2,1.3, etc so we can test our functions and the graphs. 

//We will be displaying the output using www.chartsjs.org so you can see how they need input. 

