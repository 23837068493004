import React from 'react';
import { changeManualScore} from '../data';


//Component that should only show for admins. 

class ManualScorer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {techniqueId: props.techniqueId, score: 0}
    }


    updateScore = (event) => {

        this.setState({ score: event.target.value });
    }

    submit = () => {
        changeManualScore(this.state.techniqueId, this.state.score);
        console.log('New Manual Score submitted for technique', this.state.techniqueId, 'of:', this.state.score)
    }

    render() {
        return (<div>
            <div className="ui labeled input"><div className="ui label">New Score </div> <input type="text"  size='5' value={this.state.score} onChange={this.updateScore} /></div>
            <span style={{ 'paddingLeft': 10 }}> <button  onClick={this.submit}>Submit </button> </span>
            </div>
        );
    }
}

export default ManualScorer










