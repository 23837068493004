import React from 'react';
//import PrivacySelector from './PrivacySelector';
import { getTechnique, addTechniqueOwner} from '../../data';
import VideoUpload from './VideoUpload';
import FileUpload from './FileUpload';
import TechniqueTypeSelector from '../TechniqueTypeSelector';
import { changeLocalTechType, addTechniqueToDb } from '../../data';
import { writeTechniqueToBlob } from '../../azureBlob';



class TechniqueUploader extends React.Component {


    constructor(props) {
        super(props)
        this.state = { uploadStatus: 1, setView: props.setView, updateTechnique: props.updateTechnique }
    }


    upload = async () => {
        console.log('adding tech to database')

        let technique = getTechnique();
        technique.techType = this.state.techType;
        changeLocalTechType(this.state.techType)
        writeTechniqueToBlob(technique);
        await addTechniqueToDb();
        await addTechniqueOwner()
        this.state.updateTechnique();
        this.setState({ uploadStatus: 4 });
    }

    setUploadStatus = (newStatus) => {
        if (newStatus === 4) { this.upload(); }
        this.setState({ uploadStatus: newStatus });
    }

    setUploadType = async (techType) => {
        let self = this;
        await self.setState({ techType, uploadStatus: 2 });
    }

    render() {

        return (<div className='ui sixteen wide column'>
    
                <div className="ui raised inverted segment">

                    <h2 style={{ color: 'rgba(64, 160, 255, 1)' }}> Upload a New Technique </h2>
                  

                {this.state.uploadStatus === 1 && <TechniqueTypeSelector setType={this.setUploadType} />}
                {this.state.uploadStatus === 2 && <FileUpload setUploadStatus={this.setUploadStatus} techType={this.state.techType} />}
                {this.state.uploadStatus === 3 && <VideoUpload setUploadStatus={this.setUploadStatus} />}
                {this.state.uploadStatus === 4 && <p>Upload Successful! Analyze your technique on the dashboard or <span style={{ color: 'rgba(64, 160, 255, 1)' }} onClick={() => this.setState({ uploadStatus: 1 })}> upload more </span> </p>}

                <div className = 'ui divider' />

                        <button className='ui inverted tiny red button' onClick={() => this.state.setView('publicTechniques')}> Cancel </button>
                        </div>
        </div>
        );
    }


}
export default TechniqueUploader;