import React from 'react';
import { Chart } from '@bit/primefaces.primereact.chart';


class ExampleChart extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [], options: [], view: 'leftFoot', charts: [], counter: 0
        }
    }

   
    render() {
        let data = {
            labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            datasets: [
                {
                    label: 'Effecient',
                    data: [0, 1, 2, 2.7, 2.4, 1.8, 1.4, 1.0, 0.5, 0.3, 0.1, 0],
                    fill: true,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)'
                    ],
                    borderWidth: 1
                },
                {
                    label: 'Explosive',
                    data: [0, 5, 7, 5, 1, 0, 0, 0, 0, 0, 0, 0, 0],
                    fill: true,
                    backgroundColor: [
                        'rgba(64, 160, 255, 0.2)'
                    ],
                    borderColor: [
                        'rgba(64, 160, 255, 1)'
                    ],
                    borderWidth: 1
                },
            ]
        };

       
        return (
            <div style={{ width: 275, height: 275 }}><Chart type='line' data={data} height={250} width={250} /></div>
        );  
        
}

}




export default ExampleChart;

